'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceOptionTemplateList
 * @description
 * # diceOptionTemplateList
 * Controller of the diceApp
 */
angular.module('diceApp').component('diceOptionTemplateList', {
  templateUrl: 'es6/templates/option/option.template.list.html',
  controllerAs: 'listController',
  controller: function($scope, $filter, OptionTemplate, Pageable, language) {
    const listController = this;

    listController.$onInit = function() {
      listController.searchString = '';
      listController.pageable = Pageable.of({
        order: 'name'
      });

      listController.search();
    };

    listController.search = () => {
      listController.syncing = true;

      const params = listController.pageable.build({
        language: language.get(),
        text: listController.searchString,
        evaluationType: listController.evaluationType
      });

      OptionTemplate.search(params).$promise.then((result) => {
        listController.filteredOptions = result;
      }).finally(() => {
        listController.syncing = false;
      });
    };
  }
});
