'use strict';

/**
 * Formats an array as string.
 * Usage goes as follows:
 * {{ ['a', 'b'] | join }}
 */
angular.module('diceApp').filter('join', function() {
  return function(values, separator) {
    return _.join(values, separator || ', ');
  };
});
