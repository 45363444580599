'use strict';

angular.module('diceApp')
  .component('organisationModal', {
    templateUrl: 'es6/organisation/organisation.modal.html',
    bindings: {
        organisation: '<',
        instance: '<',
        onSave: '&'
    },
    controllerAs: 'organisationController',
    controller: function(Organisation) {
        const organisationController = this;

        organisationController.cancel = function() {
            organisationController.instance.dismiss();
        };

        organisationController.save = function() {
            const body = angular.copy(organisationController.organisation);

            organisationController.loading = true;
            Organisation.store(body).$promise.then((result) => {
                organisationController.onSave({ result });
                organisationController.cancel();
            }).finally(() => {
                organisationController.loading = false;
            });
        };

    }
  });
