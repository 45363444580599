'use strict';

import XLSX from 'xlsx';

/**
 * @ngdoc service
 * @name diceApp.factory:Download
 * @description
 * The Download service, able to download data as CSV or Excel
 */
angular.module('diceApp')
    .factory('Download', function ($timeout, diceDateFilter, diceDateTimeFilter, numberFilter) {
        function download(strData, strFileName, strMimeType) {
            const blob = new Blob([strData], {
                type: strMimeType || 'application/octet-stream'
            });

            downloadBlob(blob, strFileName);
        }

        function downloadBlob(blob, strFileName) {
            var D = document,
                a = D.createElement('a');

            if (navigator.msSaveBlob) { // IE10
                return navigator.msSaveBlob(blob, strFileName);
            } /* end if (navigator.msSaveBlob) */

            if ('download' in a || (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0)) { //html5 A[download]
                a.href = URL.createObjectURL(blob);
                a.setAttribute('download', strFileName);
                a.innerHTML = 'downloading...';
                D.body.appendChild(a);
                $timeout(() => {
                    a.click();
                    D.body.removeChild(a);
                }, 66);
                return true;
            } /* end if ('download' in a) */

            // do iframe dataURL download (old ch+FF):
            var f = D.createElement('iframe');
            D.body.appendChild(f);
            f.src = URL.createObjectURL(blob);

            $timeout(() => {
                D.body.removeChild(f);
            }, 333);

            return true;
        }

        function downloadCsv(name, rows, columns, unwrap) {
            const content = convertToCSV(rows, columns, unwrap);
            download(content, name, 'text/csv;charset=utf-8');
        }
        
        function convertToCsvValue(value, type) {
            if (angular.isUndefined(value) || value === null) {
                value = '';
            } else if (type === 'DATE') {
                value = diceDateFilter(value);
            } else if (type === 'DATE_TIME') {
                value = diceDateTimeFilter(value);
            } else if (type === 'NUMBER') {
                value = numberFilter(value);
            } else if (type === 'PERCENTAGE') {
                value = numberFilter(value, 2);
            } else {
                value = '' + value;
            }
            return value.replace(/"/g, '\'').replace(/(?:\r\n|\r|\n)/g, '');
        }

        function convertToCSV(rows, columns, unwrap) {
            const separator = '\",\"';
            const newline = '\r\n';
            const dq = '\"';
            const headers = dq + _.map(columns, 'name').join(separator) + dq;
            const body = _.map(rows, (row) => {
                const data = [];
                _.each(columns, function(column) {
                    const value = unwrap(_.get(row, column.code || column.name));
                    data.push(convertToCsvValue(value, column.valueType));
                });
                return dq + _.values(data).join(separator) + dq;
            }).join(newline);
            return headers + newline + body;
        }

        function downloadExcel(name, rows, columns, unwrap) {
            const data = _.map(rows, (row) => {
                const cell = {};
                _(columns).each((column) => {
                    const value = unwrap(_.get(row, column.code || column.name));
                    let columnName = column.name;
                    if (_.has(cell, column.name)) {
                        columnName = `${columnName} (${column.code})`;
                    }
                    cell[columnName] = convertToExcelValue(value, column.valueType);
                });
                return cell;
            });

            const sheet = XLSX.utils.json_to_sheet(data, { cellDates: true });
            const book = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(book, sheet);
            XLSX.writeFile(book, name);
        }

        function convertToExcelValue(value, type) {
            if (value && (type === 'DATE' || type === 'DATE_TIME')) {
                return new Date(value);
            }

            return value;
        }

        return { downloadCsv, downloadExcel };
    });
