'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:ParticipateStates
 * @description
 * # ParticipateStates
 * Configures all states in relation to participation.
 */
angular.module('diceApp').config(function ($stateProvider) {

  const DATA = {
    public: true,
    visible: {
      header: false,
      breadcrumb: false,
      footer: false
    }
  };

  $stateProvider.state('participate', {
    url: '/participate/{evaluationId:[0-9]{1,8}}?session',
    controllerAs: 'participateController',
    controller: function ($stateParams) {
      const participateController = this;
      participateController.evaluationId = $stateParams.evaluationId;
      participateController.session = $stateParams.session;
    },
    template: `
      <dice-evaluation-participate
        evaluation-id="{{ participateController.evaluationId }}"
        session="{{ participateController.session }}">
      </dice-evaluation-participate>
    `,
    data: DATA
  });

  $stateProvider.state('participation-closed', {
    url: '/participate/{id:[0-9]{1,8}}/closed',
    resolve: {
      evaluationId: function ($stateParams) {
        return $stateParams.id;
      },
      type: function(evaluationId, Evaluation) {
        return Evaluation.type({ id: evaluationId }).$promise;
      }
    },
    controllerAs: 'participateController',
    controller: function (evaluationId, type) {
      const participateController = this;
      participateController.evaluationId = evaluationId;
      participateController.type = type;
    },
    template: `
      <dice-participate-message
        breadcrumb-label="Page.ParticipationFinished.Page.Closed.Breadcrumb"
        message-label="Page.ParticipationFinished.Page.Closed.Message"
        evaluation-id="participateController.evaluationId"
        type="participateController.type">
      </dice-participate-message>
    `,
    data: DATA
  });

  $stateProvider.state('participation-error', {
    url: '/participate/{id:[0-9]{1,8}}/error',
    resolve: {
      evaluationId: function ($stateParams) {
        return $stateParams.id;
      },
      type: function(evaluationId, Evaluation) {
        return Evaluation.type({ id: evaluationId }).$promise;
      }
    },
    controllerAs: 'participateController',
    controller: function (evaluationId, type) {
      const participateController = this;
      participateController.evaluationId = evaluationId;
      participateController.type = type;
    },
    template: `
      <dice-participate-message
        breadcrumb-label="Page.ParticipationFinished.Page.Error.Breadcrumb"
        message-label="Page.ParticipationFinished.Page.Error.Message"
        evaluation-id="participateController.evaluationId"
        type="participateController.type">
      </dice-participate-message>
    `,
    data: DATA
  });

  $stateProvider.state('participation-expired', {
    url: '/participate/{id:[0-9]{1,8}}/expired',
    resolve: {
      evaluationId: function ($stateParams) {
        return $stateParams.id;
      },
      type: function(evaluationId, Evaluation) {
        return Evaluation.type({ id: evaluationId }).$promise;
      }
    },
    controllerAs: 'participateController',
    controller: function (evaluationId, type) {
      const participateController = this;
      participateController.evaluationId = evaluationId;
      participateController.type = type;
    },
    template: `
      <dice-participate-message
        breadcrumb-label="Page.ParticipationFinished.Page.Expired.Breadcrumb"
        message-label="Page.ParticipationFinished.Page.Expired.Message"
        evaluation-id="participateController.evaluationId"
        type="participateController.type">
      </dice-participate-message>
    `,
    data: DATA
  });

  $stateProvider.state('participation-finished', {
      url: '/participate/{id:[0-9]{1,8}}/finished?session',
      resolve: {
        evaluationId: function ($stateParams) {
          return $stateParams.id;
        },
        type: function(evaluationId, Evaluation) {
          return Evaluation.type({ id: evaluationId }).$promise;
        },
        session: function ($stateParams) {
          return $stateParams.session;
        }
      },
      controllerAs: 'participateController',
      controller: function (evaluationId, type, session, descriptionFilter) {
        const participateController = this;
        participateController.evaluationId = evaluationId;
        participateController.type = type;
        participateController.session = session;
        participateController.message = descriptionFilter(type.messages);
      },
      template: `
        <dice-participate-message
          breadcrumb-label="Page.ParticipationFinished.Page.Thanks.Breadcrumb"
          message-label="Page.ParticipationFinished.Page.Thanks.Message"
          message="{{ participateController.message }}"
          evaluation-id="participateController.evaluationId"
          session="participateController.session"
          type="participateController.type">
        </dice-participate-message>
      `,
      data: DATA
  });

  $stateProvider.state('participation-unauthorized', {
    url: '/participate/{id:[0-9]{1,8}}/unauthorized',
    resolve: {
      evaluationId: function ($stateParams) {
        return $stateParams.id;
      },
      type: function(evaluationId, Evaluation) {
        return Evaluation.type({ id: evaluationId }).$promise;
      }
    },
    controllerAs: 'participateController',
    controller: function (evaluationId, type) {
      const participateController = this;
      participateController.evaluationId = evaluationId;
      participateController.type = type;
    },
    template: `
      <dice-participate-message
        breadcrumb-label="Page.ParticipationFinished.Page.Unauthorized.Breadcrumb"
        message-label="Page.ParticipationFinished.Page.Unauthorized.Message"
        evaluation-id="participateController.evaluationId"
        type="participateController.type">
      </dice-participate-message>
    `,
    data: DATA
  });

  $stateProvider.state('participation-progress', {
      url: '/participation-progress',
      template: `
        <dice-participation-progress></dice-participation-progress>
      `,
      data: {
        public: false
      }
  });
});
