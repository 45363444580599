'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:latestReport
 */
angular.module('diceApp')
  .component('latestReport', {
    bindings: {
        code: '<'
    },
    templateUrl: 'es6/report/latest.report.html',
    controllerAs: 'latestReportController',
    controller: function(Report, language) {
        const latestReportController = this;

        latestReportController.$onInit = function() {
            loadData();
        };

        function loadData() {
            latestReportController.loading = true;
            Report.latest({
                code: latestReportController.code,
                language: language.get()
            }).$promise.then((report) => {
                latestReportController.report = report;
                latestReportController.empty = _.result(latestReportController.report, 'empty', true);
            }).finally(() => {
                latestReportController.loading = false;
            });
        }

        latestReportController.onLanguage = function() {
            loadData();
        };
    }
});