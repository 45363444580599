'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceMemberTypeList
 * @description
 * diceMemberTypeList Shows the member types.
 */
angular.module('diceApp')
  .component('diceMemberTypeList', {
    bindings: {
      memberTypes: '<'
    },
    bindToController: true,
    templateUrl: 'es6/members/type/member.type.list.html',
    controllerAs: 'listController',
    controller: function ($uibModal, MemberType, EvaluationType, message) {
      const listController = this;

      listController.order = 'sequence';
      listController.reverse = false;

      listController.evaluationTypes = EvaluationType.query({});
      listController.evaluationTypes.$promise.then(function(types) {
        listController.evaluationType = _.find(types, { defaultSelected: true });
        listController.filterData();
      });

      listController.filterData = function() {
        var evaluationTypeId = _.result(listController.evaluationType, 'id');
        listController.filteredTypes = _.filter(listController.memberTypes, (t) => t.evaluationType === evaluationTypeId);
      };

      listController.remove = function (memberType) {
        MemberType.remove(memberType.id).$promise.then(function () {
          listController.memberTypes.splice(listController.memberTypes.indexOf(memberType), 1);
          listController.filterData();
        });
      };

      listController.openSequenceOrder = function () {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;
            modalController.memberTypes = listController.memberTypes;
            modalController.evaluationType = listController.evaluationType;

            modalController.onSave = function (memberTypes) {
              // replace saved member types in list and sort list by sequence
              listController.memberTypes = _(listController.memberTypes).map((mt) => {
                let foundMemberType = _.find(memberTypes, { 'id': mt.id });
                return angular.isDefined(foundMemberType) ? foundMemberType : mt;
              }).orderBy('sequence').value();
              listController.filterData();
              message.addSuccessLabel('Message.Save.Success');
            };

          },
          template: `
            <member-type-sequence-modal
              evaluation-type="modalController.evaluationType"
              member-types="modalController.memberTypes"
              on-save="modalController.onSave"
              modal="modalController.modal">
            </member-type-sequence-modal>`
        });
      };

      listController.create = function () {
        listController.open({
          evaluationType: listController.evaluationType.id
        });
      };

      listController.open = function (memberType) {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;

            if (angular.isUndefined(memberType.id)) {
              modalController.memberType = memberType;
            } else {
              modalController.memberType = MemberType.get({ id: memberType.id });
            }

            modalController.onSave = function () {
              MemberType.query().$promise.then(function(memberTypes) {
                listController.memberTypes = memberTypes;
                listController.filterData();
                message.addSuccessLabel('Message.Save.Success');
              });
            };
          },
          template: `
            <dice-member-type
              member-type="modalController.memberType"
              on-save="modalController.onSave"
              modal="modalController.modal">
            </dice-member-type>`
        });
      };

      listController.openRules = function (memberType, $event) {
        $event.preventDefault();
        $event.stopPropagation();

        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;
            modalController.memberType = memberType;
          },
          template: `
            <access-rule-list-for-member-type-modal
              member-type="modalController.memberType"
              modal="modalController.modal">
            </access-rule-list-for-member-type-modal>
          `
        });
      };
    }
  });
