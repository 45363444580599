'use strict';

angular.module('diceApp').component('condensedReportTemplate', {
  bindings: {
    template: '<'
  },
  templateUrl: 'es6/report/condensed/template/condensed.report.template.html',
  controllerAs: 'condensedReportTemplateController',
  controller: function ($state, CondensedReportTemplate, message) {
    const condensedReportTemplateController = this;

    condensedReportTemplateController.save = function(form) {
      const body = condensedReportTemplateController.template;
      _.forEach(body.memberTypes, (memberType) => {
        memberType.accessType = 'THIS_MEMBER_TYPE';
      });

      return CondensedReportTemplate.store(body).$promise.then((result) => {
        condensedReportTemplateController.markFormAsPristine(form);
        message.addSuccessLabel('Message.Save.Success');

        if (angular.isUndefined(condensedReportTemplateController.template.id)) {
          $state.go('condensed-report-template-edit', { id: result.id }, { reload: false });
        }
      });
    };

    condensedReportTemplateController.remove = function() {
      if (angular.isDefined(condensedReportTemplateController.template.id)) {
        CondensedReportTemplate.delete({
          id: condensedReportTemplateController.template.id
        }).$promise.then(function() {
          message.addSuccessLabel('Message.Delete.Success');
          $state.go('report-template-list');
        });
      }
    };

    /**
     * Sets the form to "pristine" state. Required to hide "You have unsaved changes" message upon saving or setting the available questions.
     * @param form Form to mark as pristine.
     */
    condensedReportTemplateController.markFormAsPristine = function(form) {
      form.$setPristine();
    };
  }
});