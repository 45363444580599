'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:memberEditModal
 * @description Opens a modal to add members.
 */
angular.module('diceApp').component('memberEditModal', {
  templateUrl: 'es6/members/member.edit.modal.html',
  bindings: {
    evaluationId: '@',
    container: '<',
    modal: '<',
    onSave: '<'
  },
  controllerAs: 'memberController',
  controller: function (Person, Member, message, $filter, Pageable) {
    const memberController = this;

    memberController.$onInit = function() {
      memberController.pageableCurrent = Pageable.of({
        order: 'fullName'
      });

      memberController.pageableSearch = Pageable.of({
        order: 'fullName'
      });

      reset();
      buildMembers();
    };

    memberController.close = function() {
      memberController.modal.close();
    };

    memberController.disable = function(member) {
      setDisabled(member, true);
    };

    memberController.enable = function(member) {
      setDisabled(member, false);
    };

    function setDisabled(member, disabled) {
      Member.setDisabled({
        id: member.id
      }, {
        disabled
      }).$promise.then(() => {
        const object = _.find(memberController.container.data, {
          id: member.id
        });

        if (angular.isDefined(object)) {
          object.disabled = disabled;
        }

        onChange(disabled ? 'Disable' : 'Enable');
      });
    }

    memberController.remove = function (member) {
      Member.delete({
        id: member.id
      }).$promise.then(() => {
        _.remove(memberController.container.data, {
          id: member.id
        });

        onChange('Delete');
      });
    };

    function onChange(messageType) {
      setSuggestedAvailable();
      buildMembers();

      memberController.onSave();
      message.addSuccessLabel(`Message.${messageType}.Success`);
    }

    function buildMembers() {
      memberController.members = _.map(memberController.container.data, function (member) {
        const result = angular.copy(member);
        result.fullName = result.fullName || _.result(result, 'person.displayName') || _.result(result, 'person.code');
        return result;
      });
    }

    //
    // Add new member
    //

    memberController.addNew = function() {
      memberController.model = {};
    };

    memberController.addPerson = function(member) {
      memberController.term = member.fullName;
      memberController.model = angular.copy(member);
      memberController.onSearch();
    };

    memberController.onSearch = function() {
      memberController.model.fullName = memberController.term;

      if (memberController.term) {
        const params = memberController.pageableSearch.build({
          text: _.lowerCase(angular.copy(memberController.term)),
          employee: true
        });

        Person.publicSearch(params).$promise.then((suggested) => {
          memberController.suggested = suggested;
          setSuggestedAvailable();
        });
      } else {
        memberController.suggested = [];
      }
    };

    function setSuggestedAvailable() {
      if (angular.isUndefined(memberController.suggested)) {
        return;
      }

      _.each(memberController.suggested.content, (suggested) => {
        suggested.available = !_.some(memberController.container.data, (member) => {
          return suggested.id === _.result(member, 'person.id');
        });
      });
    }

    memberController.selectSuggested = function (person) {
      if (person.available === true) {
        memberController.model.person = person.id;
        delete memberController.model.fullName;
        delete memberController.model.fullName;

        setSuggestedAvailable();
        memberController.save().then(function () {
          memberController.reset();
        });
      }
    };

    memberController.reset = function () {
      reset();
    };

    memberController.save = function () {
      return Member.store(buildBody()).$promise.then((result) => {
        _.remove(memberController.container.data, { id: result.id });
        memberController.container.data.push(result);
        memberController.reset();
        setSuggestedAvailable();
        buildMembers();
        memberController.onSave(result);
        message.addSuccessLabel('Message.Save.Success');
      });

      function buildBody() {
        let body = angular.copy(memberController.model);
        body.type = memberController.container.type.id;
        body.evaluation = memberController.evaluationId;
        return body;
      }
    };

    function reset() {
      memberController.term = '';
      delete memberController.model;
    }
  }
});
