'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:LabelStates
 * @description
 * # LabelStates
 * Configures all states for labels.
 */
  angular.module('diceApp').config(function($stateProvider) {
    $stateProvider.state('label-list', {
      url: '/labels',
      data: {
        root: true
      },
      resolve: {
        labels: function (Label) {
          return Label.query().$promise;
        }
      },
      controllerAs: 'labelListController',
      controller: function (labels) {
        const labelListController = this;
        const groupedLabels = _.groupBy(labels, function (result) {
          result.original = angular.copy(result);
          return result.key;
        });

        labelListController.labels = [];
        _.each(groupedLabels, function (label) {
          labelListController.labels.push(_.sortBy(label, 'language').reverse());
        });
      },
      template:
        `<dice-label
            labels="labelListController.labels">
         </dice-label>`
    });
  });
