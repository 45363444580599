'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:LoginController
 * @description
 * # LoginController
 * Login controller of the diceApp
 */
angular.module('diceApp').component('diceLogin', {
  bindings: {
    redirect: '<?',
    successUrl: '<?'
  },
  templateUrl: 'es6/security/login.html',
  controllerAs: 'loginController',
  controller: function(authentication, AuthenticateToken, message, $q, $state, $stateParams, $timeout, $window) {
  const loginController = this;

  loginController.$onInit = function() {
    angular.element(document).bind('keydown', onKeyDown);

    setSuccessUrl();

    if (loginController.redirect === false) {
      loadConfig();
    } else {
      loadData();
    }
  };

  loginController.$onDestroy = function() {
    angular.element(document).unbind('keydown', onKeyDown);
  };

  function setSuccessUrl() {
    if (loginController.successUrl) {
      sessionStorage.successUrl = getUrl(loginController.successUrl);
    }
  }

  function getUrl(location) {
    if (_.startsWith(location, '/#/') || _.startsWith(location, '/api/')) {
      return location;
    } else if (_.startsWith(location, '/')) {
      return '/#' + location;
    } else {
      return '/#/dashboard';
    }
  }

  function loadData() {
    loginController.loading = true;
    authentication.loadCurrent().then((result) => {
      goToSuccess(result);
    }, () => {
      loginController.loading = true;
      getConfig().then((config) => {
        const defaultLoginUrl = config.defaultLoginUrl;
        if (_.isEmpty(defaultLoginUrl)) {
          // not logged in and no redirect, show login form
          return setLoginButtons(config);
        } else if (_.startsWith(defaultLoginUrl, '/#/token')) {
          // not logged in, redirect to our authentication service
          return AuthenticateToken.authenticate(config);
        } else {
          // not logged in, redirect to external login page
          $timeout(() => goToLogin(defaultLoginUrl), 1000);
        }
      }).finally(() => {
        loginController.loading = false;
      });
    });
  }

  function getConfig() {
    return authentication.getConfig().then((config) => {
      _.forEach(config.registrations, (registration) => {
        const { id, label } = registration;
        registration.name = label || _.capitalize(id).replace('-', ' ').replace('_', ' ');
        registration.type = getType(id);
      });
      return config;
    });
  }

  function getType(id) {
    let type = '';
    if (id.indexOf('google') !== -1) {
      type = 'google';
    } else if (id === 'surfconext') {
      type = 'surfconext';
    } else if (id.startsWith('te-') !== -1) {
      type = 'timeedit';
    }
    return type;
  }

  function setLoginButtons(config) {
    loginController.registrations = _.sortBy(config.registrations, 'name');
  }

  function loadConfig() {
    loginController.loading = true;
    getConfig().then((config) => {
      setLoginButtons(config);
    }).finally(() => {
      loginController.loading = false;
    });
  }

  function goToLogin(loginUrl) {
    loginController.loading = false;
    $window.location.href = loginUrl;
  }

  loginController.login = function(username, password) {
    authentication.login(username, password).then(goToSuccess, () => {
      message.addError('Invalid username/password.');
    });
  };

  function goToSuccess(result) {
    if (result.authenticated === true) {
      $window.location.href = getUrl(sessionStorage.successUrl);
      delete sessionStorage.successUrl;
    }
  }

  function onKeyDown(e) {
    // When pressing escape (27)
    if (e.keyCode === 27) {
      loadConfig();
    }
  }
 }
});
