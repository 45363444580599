'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:evaluationParticipantList
 * @description
 * evaluationParticipantList Shows the evaluations in list.
 */
angular.module('diceApp')
  .component('manageParticipantsModal', {
    bindings: {
      participants: '<',
      evaluationId: '@',
      onSave: '&',
      modal: '<'
    },
    templateUrl: 'es6/participate/manage.participants.modal.html',
    controllerAs: 'manageParticipantsController',
    controller: function (Participant, $scope) {
      const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const manageParticipantsController = this;

      manageParticipantsController.$onInit = function () {
        manageParticipantsController.incomplete = false;
      };

      manageParticipantsController.save = function () {
        const form = {
          participants: _.filter(manageParticipantsController.participants, isValid)
        };

        Participant.batchUpdate({
          evaluationId: manageParticipantsController.evaluationId
        }, form).$promise.then((participants) => {
          manageParticipantsController.onSave({ participants });
          manageParticipantsController.modal.close();
        });
      };

      $scope.$watch('manageParticipantsController.participants', function () {
        manageParticipantsController.incomplete = _.some(manageParticipantsController.participants, (participant) => isNotEmpty(participant) && !isValid(participant));
      }, true);

      function isNotEmpty(participant) {
        return participant.fullName || participant.email;
      }

      function isValid(participant) {
        if (!participant.fullName) {
          return false;
        }

        return !participant.email || EMAIL_PATTERN.test(participant.email);
      }

      manageParticipantsController.close = function () {
        manageParticipantsController.modal.close();
      };
    }
  });
