'use strict';

angular.module('diceApp')
  .component('relationModal', {
    templateUrl: 'es6/relations/relation.modal.html',
    bindings: {
        evaluationType: '<',
        relation: '<',
        instance: '<',
        onSave: '&'
    },
    controllerAs: 'relationController',
    controller: function(Relation, Person, MemberType) {
        const relationController = this;

        relationController.$onInit = function() {
            relationController.loading = true;
            relationController.persons = [];

            MemberType.query({
                evaluationType: relationController.evaluationType
            }).$promise.then((memberTypes) => {
                relationController.memberTypes = memberTypes;
            }).finally(() => {
                relationController.loading = false;
            });
        };

        relationController.getPersons = function() {
            const text = relationController.term;
            if (text) {
                Person.search({
                    text: _.lowerCase(text),
                    page: 0,
                    size: 10
                }).$promise.then((persons) => {
                    relationController.persons = persons.content;
                });
            } else {
                relationController.persons = [];
            }
        };

        relationController.onPerson = function(person) {
            relationController.relation.person = person;
        };

        relationController.cancel = function() {
            relationController.instance.dismiss();
        };

        relationController.save = function() {
            const body = angular.copy(relationController.relation);
            body.person = body.person.id;

            relationController.loading = true;
            Relation.store(body).$promise.then((result) => {
                relationController.onSave({ result });
                relationController.cancel();
            }).finally(() => {
                relationController.loading = false;
            });
        };

    }
  });
