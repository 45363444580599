'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:dicePinButton
 * @description
 * dicePinButton Adds the evaluation to pins.
 */
angular
  .module('diceApp')
  .component('dicePinButton', {
    bindings: {
      evaluation: '<'
    },
    templateUrl: 'es6/pinning/pin.button.html',
    controllerAs: 'pinButtonController',
    controller: function (pinnedRegistry, $timeout) {
      const pinButtonController = this;

      pinButtonController.$onInit = function () {
        pinnedRegistry.addOnClearCallback(setIsPinned);
        setIsPinned();
      };

      pinButtonController.addOrRemove = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $timeout(pinnedRegistry.add(pinButtonController.evaluation), 0);

        if (pinButtonController.isPinned) {
          pinnedRegistry.remove(pinButtonController.evaluation);
        } else {
          pinnedRegistry.add(pinButtonController.evaluation);
        }

        setIsPinned();
      };

      function setIsPinned() {
        const pins = pinnedRegistry.load();
        const found = _.find(pins, {
          id: pinButtonController.evaluation.id
        });

        pinButtonController.isPinned = angular.isDefined(found);
      }
    }
  });
