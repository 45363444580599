'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:EventLogController
 * @description
 * EventLogController Shows the available HTTP logs.
 */
angular.module('diceApp')
  .component('diceHttpLog', {
    templateUrl: 'es6/base/audit/http.log.html',
    controllerAs: 'eventController',
    controller: function (HttpLog, $uibModal) {
      const eventController = this;

      eventController.$onInit = function() {
        eventController.logs = HttpLog.get({
          page: 1,
          size: 100,
          sort: 'created,DESC'
        });
      };

      eventController.open = function(log) {
        $uibModal.open({
          template: `
            <dice-http-log-modal
              log="modalController.log" 
              instance="modalController.instance">
            </dice-http-log-modal>
          `,
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.log = log;
            modalController.instance = $uibModalInstance;
          }
        });
      };
    }
  }
);
