'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceEvaluationCreate
 */
angular.module('diceApp')
  .component('diceEvaluationCreate', {
    bindings: {
      types: '<',
      templates: '<'
    },
    templateUrl: 'es6/evaluations/create/evaluation.create.html',
    controllerAs: 'evaluationCreateController',
    controller: function ($state, message, Evaluation, EvaluationType, evaluationCopier) {
      const evaluationCreateController = this;

      evaluationCreateController.$onInit = function () {
        evaluationCreateController.model = {
          tags: []
        };

        evaluationCreateController.mode = 'blank';
      };

      evaluationCreateController.onTypeChange = function () {
        delete evaluationCreateController.model.evaluation;
        delete evaluationCreateController.model.template;
        evaluationCreateController.templatesOfType = _.filter(evaluationCreateController.templates, (template) => template.type.id === evaluationCreateController.model.type);
        EvaluationType.get({ id: evaluationCreateController.model.type }).$promise.then((type) => {
          evaluationCreateController.type = type;
          if (type.requiresTemplate) {
            evaluationCreateController.mode = 'template';
          }
        });
      };

      evaluationCreateController.new = function () {
        const body = angular.copy(evaluationCreateController.model);
        delete body.template;
        delete body.evaluation;
        create(body);
      };

      evaluationCreateController.byTemplate = function () {
        const body = angular.copy(evaluationCreateController.model);
        delete body.evaluation;
        create(body);
      };

      evaluationCreateController.byEvaluation = function () {
        const body = angular.copy(evaluationCreateController.model);
        delete body.template;
        body.evaluation = evaluationCreateController.evaluation;
        create(body);
      };

      evaluationCreateController.setJSON = function (data) {
        const evaluation = fromJson(data);
        evaluationCreateController.evaluation = evaluationCopier.copy(evaluation);
      };

      function fromJson(data) {
        let json = angular.fromJson(data);
        if (_.isArray(json) && json.length > 0) {
          json = json[0];
        }

        _.forEach(json.blocks, (block) => {
          delete block.template;
          _.forEach(block.questions, (question) => {
            delete question.template;
          });
        });

        return json;
      }

      function create(body) {
        Evaluation.save(body).$promise.then(function (result) {
          message.addSuccessLabel('Message.Save.Success');
          $state.go('evaluation.general', { id: result.id });
        });
      }
    }
  });
