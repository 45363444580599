'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceExpired
 * @description
 * # diceExpired
 * Shown when session is expired.
 */
angular.module('diceApp').component('diceExpired', {
  templateUrl: 'es6/security/expired.html',
  controllerAs: 'expiredController',
  controller: function(authentication) {
    const expiredController = this;
    
    expiredController.logout = function() {
      authentication.logout();
    };
  }
});
