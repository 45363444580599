'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:Tag
 * @description
 * The Tag service.
 */
angular.module('diceApp')
  .factory('Tag', function(DiceResource) {
    return DiceResource('/available-tags');
  });
