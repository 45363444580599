'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceComposedReport
 */
angular.module('diceApp')
  .component('condensedReport', {
    bindings: {
      year: '<?',
      evaluationType: '<?',
      selectedQuestionTemplateIds: '<?',
      templateId: '<?'
    },
    templateUrl: 'es6/report/condensed/condensed.report.html',
    controllerAs: 'reportController',
    controller: function (Report, language, Export, $window) {
      const reportController = this;

      reportController.$onInit = function () {
        reportController.loadReport();
      };

      reportController.loadReport = function () {
        reportController.loading = true;

        Report.condensed({ templateId: reportController.templateId, language: language.get() }).$promise
          .then(function (report) {
            reportController.report = report;
          })
          .finally(function () {
            reportController.loading = false;
          });
      };

      reportController.getMaxOptionValue = function(question) {
        return _.maxBy(question.options, 'value').value;
      };

      reportController.exportAsPdf = function() {
        Export.generatePdf($window.location.href, language.get());
      };
    }
  });
