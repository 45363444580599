'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:languageSelect
 * @description
 * The languageSelect directive.
 */
angular.module('diceApp').component('languageSelect', {
    bindings: {
      onChange: '&?'
    },
    templateUrl: 'es6/i18n/language.select.html',
    controllerAs: 'languageSelectController',
    controller: function(language) {
      const languageSelectController = this;
      languageSelectController.language = language;

      languageSelectController.changeLanguage = function(langKey) {
        language.set(langKey.toUpperCase());
        if (angular.isDefined(languageSelectController.onChange)) {
          languageSelectController.onChange();
        }
      };
    }
  });
