'use strict';

angular.module('diceApp').component('exportModal', {
  templateUrl: 'es6/export/export.modal.html',
  bindings: {
    size: '<',
    strategies: '<',
    modal: '<'
  },
  controllerAs: 'exportModalController',
  controller: function(Enums, enumFilter, translateFilter, Export, language, EvaluationType, ReportTemplate) {
    const exportModalController = this;
    const customReportType = 'CUSTOM';

    exportModalController.$onInit = function() {
      Enums.query({
        type: 'report-type'
      }).$promise.then((reportTypes) => {
        const standardReportTypes = _(reportTypes)
          .map((reportType, $index) => {
            return {
              value: reportType,
              ordinal: $index,
              name: enumFilter(reportType)
            };
          })
          .orderBy('name')
          .value();

        const customReportTypeItem = [{
          value: customReportType,
          ordinal: standardReportTypes.length,
          name: translateFilter('Export.ReportType.Custom')
        }];

        exportModalController.reportTypes = standardReportTypes.concat(customReportTypeItem);
      });
    };

    exportModalController.export = function(strategy) {
      if (!strategy.usesReportTemplate) {
        Export.exportMultiple(_.map(strategy.evaluations, 'id'), strategy.name, language.get(), exportModalController.compress).then(() => {
          exportModalController.modal.close();
        });
        return;
      }

      // If report template is needed, start process to let user pick report template.
      // If not all evaluations are of the same type, the user must pick an evaluation type.
      exportModalController.evaluationTypeRequired = _.uniqBy(strategy.evaluations, 'type').length !== 1;

      // Only fetch evaluation types if we need to pick one.
      if (exportModalController.evaluationTypeRequired && angular.isUndefined(exportModalController.evaluationTypes)) {
        retrieveEvaluationTypes(strategy.evaluations);
      }

      // If all evaluations are of the same type, auto-fetch report templates.
      if (!exportModalController.evaluationTypeRequired) {
        const firstEvaluationType = _.uniqBy(strategy.evaluations, 'type')[0].type;
        exportModalController.evaluationType = _.isNumber(firstEvaluationType) ? firstEvaluationType : firstEvaluationType.id;
        retrieveReportTemplates(exportModalController.evaluationType);
      }

      exportModalController.reportTemplateRequired = true;
      exportModalController.strategy = strategy;
    };

    exportModalController.onEvaluationType = function(evaluationTypeId) {
      exportModalController.evaluationType = evaluationTypeId;
      retrieveReportTemplates(exportModalController.evaluationType);
    };

    exportModalController.onReportTemplate = function(reportTemplateId) {
      exportModalController.reportTemplate = reportTemplateId;
    };

    exportModalController.onExportWithReport = function(strategy) {
      // If using a custom template, only export evaluations with the selected evaluation type.
      const filtered = exportModalController.reportType === customReportType ? _(strategy.evaluations)
        .filter((evaluation) => _.get(evaluation.type, 'id', evaluation.type)  === exportModalController.evaluationType)
        .map('id')
        .value()
      : _.map(strategy.evaluations, 'id');

      const reportType = exportModalController.reportType === customReportType ? null : exportModalController.reportType;
      const reportTemplate = exportModalController.reportType === customReportType ? exportModalController.reportTemplate : null;
      Export.exportMultiple(filtered, strategy.name, language.get(), exportModalController.compress, reportType, reportTemplate).then(() => {
        exportModalController.modal.close();
      });
    };

    function retrieveEvaluationTypes(evaluations) {
      EvaluationType.query().$promise.then((evaluationTypes) => {
        // Only show evaluation types that are in the list of filtered evaluations.
        exportModalController.evaluationTypes = _.filter(evaluationTypes, (evaluationType) => _.some(evaluations, (evaluation) => _.get(evaluation, 'type.id', _.get(evaluation, 'type')) === evaluationType.id));
      });
    }

    function retrieveReportTemplates(evaluationType) {
      ReportTemplate.query({ evaluationType, active: true }).$promise.then((reportTemplates) => {
        exportModalController.reportTemplates = _.sortBy(reportTemplates, ['sequence', 'id']);
      });
    }
  }
});
