'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceStatusTransitionList
 * @description
 * # diceStatusTransitionList
 * Controller of the diceApp
 */
angular.module('diceApp')
  .component('diceStatusTransitionList', {
    bindings: {
      transitions: '<'
    },
    templateUrl: 'es6/status/transition/status.transition.list.html',
    controllerAs: 'listController',
    controller: function (EvaluationType, StatusTransition, $uibModal, message) {
      const listController = this;

      listController.order = 'label';
      listController.reverse = false;

      listController.evaluationTypes = EvaluationType.query({});
      listController.evaluationTypes.$promise.then(function (types) {
        listController.evaluationType = _.find(types, { defaultSelected: true });
        listController.filterData();
      });

      listController.filterData = function () {
        let evaluationTypeId = _.result(listController.evaluationType, 'id');
        listController.filteredTransitions = _.filter(listController.transitions, (t) => t.evaluationType === evaluationTypeId);
      };

      listController.create = function () {
        listController.open({
          evaluationType: listController.evaluationType.id
        });
      };

      listController.open = function (statusTransition) {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;

            if (angular.isUndefined(statusTransition.id)) {
              modalController.statusTransition = statusTransition;
            } else {
              modalController.statusTransition = StatusTransition.get({ id: statusTransition.id });
            }

            modalController.onSave = function () {
              StatusTransition.query().$promise.then((statusTransitions) => {
                listController.transitions = statusTransitions;
                listController.filterData();
                message.addSuccessLabel('Message.Save.Success');
              });
            };
          },
          template: `
            <status-transition-modal
              status-transition="modalController.statusTransition"
              on-save="modalController.onSave"
              modal="modalController.modal">
           </status-transition-modal>
          `
        });
      };

      listController.remove = function (statusTransition) {
        StatusTransition.remove(statusTransition.id).$promise.then(function () {
          message.addSuccessLabel('Message.Delete.Success');
          _.remove(listController.filteredTransitions, (t) => t.id === statusTransition.id);
        });
      };

    }
  });
