'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:pinnedRegistry
 * @description
 * pinnedRegistry Registry for pinned evaluations.
 */
angular.module('diceApp')
  .factory('pinnedRegistry', function (SessionStorage, $filter, $timeout) {
    const key = 'pinned';
    const evaluations = init();
    const _onClearCallbacks = [];
    const _onChangeCallbacks = [];
    let popOverIsOpen = false;

    function init() {
      const json = SessionStorage.get(key) || '[]';
      return angular.fromJson(json);
    }

    function save() {
      SessionStorage.set(key, angular.toJson(evaluations));
      _onChangeCallbacks.forEach((callback) => {
        callback(evaluations);
      });
    }

    function load() {
      return evaluations;
    }

    function togglePinnedState(state) {
      popOverIsOpen = state ? state : !popOverIsOpen;
    }

    function isOpen() {
      return popOverIsOpen;
    }

    function add(evaluation) {
      remove(evaluation); // Remove any duplicates
      
      evaluations.push({
        id: evaluation.id,
        code: evaluation.code,
        name: evaluation.name || $filter('description')(evaluation.names)
      });

      // Open the pinned items popover if not opened.
      $timeout(() => {
        togglePinnedState(false);
      }, 0);
      $timeout(() => {
        togglePinnedState(true);
      }, 1);

      save();
    }

    function remove(evaluation) {
      _.remove(evaluations, { id: evaluation.id });
      save();
    }

    function addOnClearCallback(callback) {
      function deregister() {
        const index = _onClearCallbacks.indexOf(callback);
        _onClearCallbacks.splice(index, 1);
      }
      _onClearCallbacks.push(callback);
      return deregister;
    }

    function clear() {
      evaluations.length = 0;
      save();
      angular.forEach(_onClearCallbacks, function (callback) {
        callback();
      });
    }

    function addOnChangeCallback(callbackFn) {
      _onChangeCallbacks.push(callbackFn);
    }

    return { load, clear, add, remove, addOnClearCallback, togglePinnedState, isOpen, addOnChangeCallback };
  });
