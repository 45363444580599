'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:News
 * @description
 * # News
 * News resource of the diceApp
 */
angular.module('diceApp')
	.factory('News', function(DiceResource) {
		return DiceResource('/news', {
			today: {
				url: '/news/today',
				method: 'GET',
				isArray: true
			}
		});
	});
