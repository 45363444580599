'use strict';

/**
 * @ngdoc component
 * @name diceApp.controller:EvaluationTagSelection
 * @description
 * EvaluationTagSelection allows evaluation tag selection.
 */
angular.module('diceApp')
  .component('diceEvaluationTagSelection', {
    bindings: {
      classes: '<?'
    },
    require: {
      ngModel: 'ngModel'
    },
    templateUrl: 'es6/evaluations/evaluation.tag.selection.html',
    controllerAs: 'tagController',
    controller: function (Evaluation, $scope) {
      const tagController = this;

      tagController.tags = [];
      tagController.selected = {};

      tagController.$onInit = function () {
        Evaluation.tags().$promise.then((tags) => {
          _.forEach(tags, (tag) => {
            tag.options = _.sortBy(tag.options, 'value');
          });

          tagController.tags = tags;
        });

        $scope.$watch('tagController.ngModel.$modelValue', setValues);
      };

      function setValues() {
        const value = tagController.ngModel.$modelValue;
        if (value) {
          const parts = value.split('|');
          parts.forEach(function(part) {
            const type = part.split('=');
            tagController.selected[type[0]] = part;
          });
        } else {
          tagController.selected = {};
        }
      }

      tagController.doChange = function() {
        var value = '';
        tagController.tags.forEach(function(tag) {
          var tmp = tagController.selected[tag.type];
          if (tmp) {
            if (value.length > 0) {
              value += '|';
            }
            value += tmp;
          }
        });

        tagController.ngModel.$setViewValue(value);
      };
    }
  });
