'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:notificationTemplateModal
 * @description Shows a modal of notification template details.
 */
angular.module('diceApp')
  .component('notificationTemplateModal', {
    bindings: {
      notificationTemplate: '<',
      onSave: '<',
      modal: '<'
    },
    bindToController: true,
    templateUrl: 'es6/notifications/template/notifications.template.modal.html',
    controllerAs: 'templateController',
    controller: function(NotificationTemplate, message) {
      const templateController = this;

      templateController.close = function () {
        templateController.modal.close();
      };

      templateController.save = function () {
        NotificationTemplate.store(templateController.notificationTemplate).$promise.then(function (result) {
          message.addSuccessLabel('Message.Save.Success');
          templateController.onSave(result);
          templateController.close();
        });
      };

    }
  });
