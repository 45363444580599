'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Query
 * @description
 * # Query
 * Query resource of the diceApp
 */
angular.module('diceApp')
	.factory('Query', function($q, DiceResource, Promises, language, Download) {
		const resource = DiceResource('/query', {
            'values': {
                url: '/query',
                method: 'POST',
                isArray: true
            }
        });

        function isEmpty(row, column) {
            const data = row.data[column.code];
            return angular.isUndefined(data);
        }

        function setValues(rows, column) {
            const entityType = rows[0].entity.type;
            const entityIds = _(rows).map((row) => {
                row.data[column.code] = 'loading';
                return row.entity.id;
            }).uniq().value();

            return resource.values({
                entityType: entityType,
                entityIds: entityIds,
                headerType: column.type,
                headerCode: column.code,
                language: language.get()
            }).$promise.then((extensions) => {
                _.each(extensions, (extension) => {
                    _(rows).filter({
                        entity: extension.entity
                    }).each((row) => {
                        delete row.data[column.code];

                        // Enhance the existing row with our new decorations
                        row.data = _.extend(row.data, extension.data);
                    });
                });
            });
        }

        function ensure(page, columns, wait) {
            const requests = _(columns).filter({
                visible: true,
                provided: false
            }).map((column) => {
                return ensureColumn(page.content, column);
            }).value();

            if (wait === true) {
                return $q.all(requests).then(() => {
                    return page;
                });
            } else {
                return $q.resolve(page);
            }
        }

        function ensureColumn(rows, column) {
            if (_.isEmpty(rows) || column.provided !== false) {
                return $q.resolve();
            } else if (column.promise) {
                return column.promise;
            }
        
            const requests = _(rows).filter((row) => isEmpty(row, column)).chunk(100).map((chunk) => {
                return () => setValues(chunk, column);
            }).value();
        
            column.loading = true;
            const promise = column.promise = Promises.chain(requests).$promise.finally(() => {
                delete column.promise;
                column.loading = false;
            });
        
            return promise;
        }

        function unwrap(value) {
            if (_.isObject(value)) {
                if (angular.isDefined(value.value)) {
                    return value.value;
                } else if (angular.isDefined(value.visible)) {
                    return value.visible;
                }
            }

            return value;
        }

        return {
            ensure,

            csv: function(name, rows, columns) {
                name = name + '.csv' || 'download.csv';
                Download.downloadCsv(name, rows, columns, unwrap);
            },

            excel: function(name, rows, columns) {
                name = name + '.xlsx' || 'download.xlsx';
                Download.downloadExcel(name, rows, columns, unwrap);
            }            
        };
	});