'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:ReportStates
 * @description
 * # ReportStates
 * Configures all states in relation to report.
 */
angular.module('diceApp').config(function ($stateProvider) {

  $stateProvider.state('report', {
    url: '/evaluations/{id:[0-9]{1,8}}/report?type&template&questions&filters&language',
    controllerAs: 'reportStateController',
    controller: function ($stateParams, language) {
      const reportStateController = this;
      reportStateController.evaluations = [$stateParams.id];
      reportStateController.type = $stateParams.type;
      reportStateController.template = $stateParams.template;
      reportStateController.questions = $stateParams.questions;
      reportStateController.filters = angular.fromJson($stateParams.filters);
      setLanguage($stateParams, language);
    },
    template: `
      <dice-composed-report
        evaluations="reportStateController.evaluations"
        type="reportStateController.type"
        template="reportStateController.template"
        questions="reportStateController.questions"
        filters="reportStateController.filters">
      </dice-composed-report>
    `,
    data: {
      root: true,
      public: true,
      visible: {
        body: 'blank',
        header: false,
        breadcrumb: false,
        footer: false
      }
    },
    params: {
      questions: {
        value: [],
        array: true
      },
      filters: {
        value: '{}'
      }
    }
  });

  // This state should be removed in later versions, kept for backwards compatibility.
  $stateProvider.state('public-report', {
    url: '/evaluations/{id:[0-9]{1,8}}/report/public?questions&filters&language',
    controllerAs: 'reportStateController',
    controller: function ($stateParams, language) {
      const reportStateController = this;
      reportStateController.evaluations = [$stateParams.id];
      reportStateController.type = $stateParams.type;
      reportStateController.questions = $stateParams.questions;
      reportStateController.filters = angular.fromJson($stateParams.filters);
      setLanguage($stateParams, language);
    },
    template: `
      <dice-composed-report
        evaluations="reportStateController.evaluations"
        type="reportStateController.type"
        questions="reportStateController.questions"
        filters="reportStateController.filters">
      </dice-composed-report>
    `,
    data: {
      root: true,
      public: true,
      visible: {
        body: 'blank',
        header: false,
        breadcrumb: false,
        footer: false
      }
    },
    params: {
      questions: {
        value: [],
        array: true
      },
      filters: {
        value: '{}'
      }
    }
  });

  $stateProvider.state('latest-report', {
    url: '/latest?code&language',
    controllerAs: 'latestReportController',
    controller: function ($stateParams, language) {
      const latestReportController = this;
      latestReportController.code = $stateParams.code;
      setLanguage($stateParams, language);
    },
    template: `
      <latest-report code="latestReportController.code"></latest-report>
    `,
    data: {
      root: true,
      public: true,
      visible: {
        body: 'blank',
        header: false,
        breadcrumb: false,
        footer: false
      }
    },
    params: {
      code: {
        value: ''
      }
    }
  });

  $stateProvider.state('composed-report', {
    url: '/report?evaluations&layout&template&questions&filters&language',
    controllerAs: 'reportStateController',
    controller: function ($stateParams, language) {
      const reportStateController = this;
      reportStateController.evaluations = $stateParams.evaluations.split(',').map(Number);
      reportStateController.template = $stateParams.template;
      reportStateController.layout = $stateParams.layout;
      reportStateController.questions = $stateParams.questions;
      reportStateController.filters = angular.fromJson($stateParams.filters);
      setLanguage($stateParams, language);
    },
    template: `
      <dice-composed-report
        evaluations="reportStateController.evaluations"
        template="reportStateController.template"
        layout="reportStateController.layout"
        questions="reportStateController.questions"
        filters="reportStateController.filters">
      </dice-composed-report>
    `,
    data: {
      root: true,
      public: true,
      visible: {
        body: 'blank',
        header: false,
        breadcrumb: false,
        footer: false
      }
    },
    params: {
      questions: {
        value: [],
        array: true
      },
      filters: {
        value: '{}'
      }
    }
  });

  $stateProvider.state('condensed-report', {
    url: '/condensedReport?year&evaluationType&selectedQuestionTemplateIds&template&language',
    controllerAs: 'reportStateController',
    controller: function ($stateParams, language) {
      const reportStateController = this;
      reportStateController.year = $stateParams.year;
      reportStateController.evaluationType = $stateParams.evaluationType;
      reportStateController.selectedQuestionTemplateIds = $stateParams.selectedQuestionTemplateIds;
      reportStateController.template = $stateParams.template;
      setLanguage($stateParams, language);
    },
    template: `
      <condensed-report
        year="reportStateController.year"
        template-id="reportStateController.template"
        evaluation-type="reportStateController.evaluationType"
        selected-question-template-ids="reportStateController.selectedQuestionTemplateIds">
      </condensed-report>
    `,
    data: {
      root: true,
      visible: {
        body: 'blank',
        header: false,
        breadcrumb: false,
        footer: false
      }
    }
  });

  function setLanguage($stateParams, language) {
    if (!_.isEmpty($stateParams.language)) {
      language.set($stateParams.language.toUpperCase());
    }
  }

});
