'use strict';

/**
 * @ngdoc component
 * @name diceApp.component:diceHtml
 * @description Previews an HTML text.
 */
angular.module('diceApp').component('diceHtml', {
  templateUrl: 'es6/preview/description.html',
  bindings: {
    content: '@'
  },
  controllerAs: 'previewController',
  controller: function($sce, $scope) {
    const ctrl = this;

    $scope.$watch('previewController.content', trustContent);
    trustContent();

    function trustContent() {
      ctrl.trustedContent = $sce.trustAsHtml(ctrl.content || '');
    }
  }
});
