'use strict';

angular.module('diceApp').config(function ($stateProvider) {
  $stateProvider.state('http-log-list', {
    url: '/http-log-list',
    data: {
      root: true
    },
    template: '<dice-http-log></dice-http-log>'
  }).state('request-log-list', {
    url: '/request-logs',
    data: {
      root: true
    },
    template: '<request-log-modal></request-log-modal>'
  }).state('syncronization-log-list', {
    url: '/syncronization-log-list',
    data: {
      root: true
    },
    template: '<dice-syncronization-log></dice-syncronization-log>'
  }).state('mail-log-list', {
    url: '/mail-log-list',
    data: {
      root: true
    },
    template: '<dice-mail-log-list></dice-mail-log-list>'
  }).state('session-list', {
    url: '/session-list',
    data: {
      root: true
    },
    template: '<participant-session-list></participant-session-list>'
  });
});
