'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:StatusLog
 * @description
 * # StatusLog
 * StatusLog resource of the diceApp
 */
angular.module('diceApp')
  .factory('StatusLog', function(DiceResource) {
    return DiceResource('/status-logs');
  });
