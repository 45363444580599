'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceMailLogList
 * @description
 * diceMailLogList Shows the available mail logs.
 */
angular.module('diceApp').component('diceMailLogList', {
    templateUrl: 'es6/notifications/logs/mail.log.list.html',
    controllerAs: 'logsController',
    controller: function (MailLog, Pageable, $scope, $timeout, $uibModal) {
      const logsController = this;

      logsController.$onInit = function() {
        logsController.pageable = Pageable.of({
          order: 'creationDate',
          reverse: true
        });

        logsController.update();
      };

      logsController.open = function(log) {
        $uibModal.open({
          templateUrl: 'es6/notifications/logs/mail.log.modal.html',
          controllerAs: 'logController',
          controller: function($uibModalInstance) {
            const logController = this;
            logController.log = angular.copy(log);
            logController.close = function() {
              $uibModalInstance.dismiss();
            };
          }
        });
      };

      $scope.$watch('logsController.searchString', function (tmpStr, value) {
        if (tmpStr !== value) {
          $timeout(function () {
            if ((tmpStr.length !== 0 && tmpStr === logsController.searchString) || logsController.searchString === '') {
              logsController.update();
            }
          }, 400);
        }
      });

      logsController.update = function () {
        logsController.loading = true;

        const params = logsController.pageable.build({
          text: _.toLower(logsController.searchString),
          success: logsController.success
        });

        MailLog.get(params).$promise.then((logs) => {
          logsController.logs = logs;
        }).finally(() => {
          logsController.loading = false;
        });
      };
    }
  }
);
