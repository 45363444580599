'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:language
 * @description
 * The language service.
 */
angular.module('diceApp').factory('language', function($rootScope, $translate, $cookies, LocalStorage) {

      const LANGUAGE_KEY = 'NG_TRANSLATE_LANG_KEY';

      const instance = {
        supported: []
      };

      instance.get = function() {
        return LocalStorage.get(LANGUAGE_KEY);
      };

      instance.set = function(newLanguage) {
        LocalStorage.set(LANGUAGE_KEY, newLanguage);
        $translate.use(newLanguage);
        $rootScope.$emit('language');
      };

      instance.onChange = function(callback) {
        return $rootScope.$on('language', callback); // eslint-disable-line angular/on-watch
      };

      instance.init = function(languages, defaultLanguage) {
        let current = _([
          $cookies.get('Set-Language'),
          instance.get(),
          defaultLanguage
        ]).filter(isDefined).first();

        instance.set(current);
        instance.supported = languages;

        // Remove cookie once consumed
        $cookies.remove('Set-Language');
      };

      function isDefined(language) {
        return !(language === 'undefined' || _.isUndefined(language));
      }

      return instance;
    });
