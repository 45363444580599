'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:HttpLog
 * @description
 * The HttpLog resource.
 */
angular.module('diceApp')
    .factory('HttpLog', function(DiceResource) {
        return DiceResource('/http-logs');
    });
