'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:dicePersonPopover
 * @description Shows the popover photo of a person
 */
angular.module('diceApp').component('dicePersonPopover', {
  templateUrl: 'es6/members/person.popover.html',
  bindings: {
    person: '<'
  },
  controllerAs: 'personPopoverController',
  controller: function ($window) {
    const personPopoverController = this;

    personPopoverController.sendEmail = function (email, event) {
      event.preventDefault();
      event.stopPropagation();

      const link = 'mailto: ' + email;
      $window.location.href = link;
    };
  }
});
