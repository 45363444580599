'use strict';

/**
 * @ngdoc component
 * @name diceApp.controller:evaluationFeedback
 * @description Shows the evaluation report.
 */
angular.module('diceApp')
  .component('evaluationFeedback', {
    bindings: {
      evaluation: '<'
    },
    templateUrl: 'es6/report/feedback.html',
    controllerAs: 'feedbackController',
    controller: function(Feedback, FeedbackModal, MemberType, message, $q) {
        const feedbackController = this;

        feedbackController.$onInit = function() {
          loadFeedback();
        };

        function loadFeedback() {
            $q.all([
                Feedback.query({
                  evaluationId: feedbackController.evaluation.id
                }).$promise,
                MemberType.query().$promise
            ]).then(([existing, roles]) => {
                const feedbacks = _(roles).sortBy('sequence').filter({
                  evaluationType: feedbackController.evaluation.type.id,
                  feedback: true
                }).map((role) =>
                  buildFeedback(role, existing)
                ).value();

                if (feedbackController.evaluation.type.feedbackToStudentsEnabled) {
                  feedbacks.unshift(buildFeedback(undefined, existing));
                }
                feedbackController.feedbacks = feedbacks;
            });
        }

        function buildFeedback(role, existing) {
            const roleId = _.get(role, 'id');
            const feedback = _.find(existing, (it) => it.role === roleId) || {};
            feedback.role = role;
            if (angular.isUndefined(feedback.message)) {
              feedback.message = '';
            }
            return feedback;
        }

        feedbackController.open = function(feedback) {
          FeedbackModal.open({
            feedback,
            evaluationId: feedbackController.evaluation.id,
            onChange: () => {
              feedbackController.feedbacks = [];
              loadFeedback();
            }
          });
        };
    }
  });
