'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:ReportTemplate
 * @description
 * # ReportTemplate
 * ReportTemplate resource of the diceApp
 */
angular.module('diceApp')
  .factory('ReportTemplate', function (DiceResource) {
    const instance = DiceResource('/report-templates');

    function build() {
      return {
        names: [],
        blockTypes: [],
        memberTypes: [],
        headers: [],
        tags: [],
        questions: [],
        attributes: []
      };
    }

    function copy(original) {
      const clone = angular.copy(original);
      clone.names = _.map(clone.names, function(name) {
        name.value = `${name.value} (2)`;
        return removeId(name);
      });
      clone.blockTypes = _.map(clone.blockTypes, removeId);
      clone.memberTypes = _.map(clone.memberTypes, removeId);
      clone.headers = _.map(clone.headers, function(header) {
        header.labels = _.map(header.labels, removeId);
        return removeId(header);
      });
      clone.tags = _.map(clone.tags, removeId);
      clone.questions = _.map(clone.questions, removeId);
      clone.attributes = _.map(clone.attributes, function(attribute) {
        attribute.tooltips = _.map(attribute.tooltips, removeId);
        return removeId(attribute);
      });

      return _.omit(clone, ['id', 'externalId', 'reportType']);
    }

    function removeId(object) {
      return _.omit(object, 'id');
    }

    return _.extend(instance, { build, copy });
  });
