'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:NotificationTemplate
 * @description
 * # NotificationTemplate
 * NotificationTemplate resource of the diceApp
 */
angular.module('diceApp')
  .factory('NotificationTemplate', function(DiceResource) {
    return DiceResource('/notification-templates', {
      tags: {
        method: 'GET',
        url: '/notification-templates/tags'
      }
    });
  });
