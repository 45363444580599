'use strict';

/**
 * @ngdoc directive
 * @name diceApp.component:charLeft
 * @description Shows howmuch chars are left.
 */
angular.module('diceApp').component('charLeft', {
    templateUrl: 'es6/preview/char.left.html',
    bindings: {
      limit: '@?',
      content: '='
    },
    controllerAs: 'charLeftController',
    controller: function() {
      const charLeftController = this;
      charLeftController.getLength = function() {
        if (angular.isUndefined(charLeftController.content) || charLeftController.content === null) {
          return 0;
        }
        let contentString = ('' + charLeftController.content);
        let newLineCount = (contentString.length === 0 ? 0 : contentString.split('\n').length - 1);
        // new lines are counted as 1 but are actually 2 chars:
        return contentString.length + newLineCount;
      };
    }
  });
