'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Feedback
 * @description
 * # Feedback
 * Feedback resource of the diceApp
 */
angular.module('diceApp')
  .factory('Feedback', function (DiceResource) {
    return DiceResource('/feedbacks/:evaluationId', {
      saveOrUpdate: {
        method: 'POST',
        url: '/feedbacks/:evaluationId'
      }
    });
  });
