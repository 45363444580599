'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:DescriptionInput
 * @description
 * The DescriptionInput directive.
 */
angular.module('diceApp')
  .component('errorFields', {
    bindings: {
      field: '@',
      entity: '@'
    },
    templateUrl: 'es6/base/forms/validation/error.fields.html',
    controllerAs: 'errorFieldsController',
    controller: function (errorFieldsStore) {
      const errorFieldsController = this;

      errorFieldsStore.onError(function (error) {
        const entityField = errorFieldsController.entity + '.' + errorFieldsController.field;
        const fields = _(error.fieldErrors)
          .filter((fieldError) => _.includes([errorFieldsController.field, entityField], fieldError.field))
          .map(function (field) {
            var container = {};
            var result = angular.copy(field);
            _.each(result.arguments, (argument, $index) => container['arg' + $index] = argument);
            result.arguments = container;
            return result;
          }).value();
        errorFieldsController.fields = fields;
      });
    }
  });
