'use strict';

angular.module('diceApp').filter('paged', function(orderByFilter, limitToFilter) {
    return function(content, pageable) {
        if (!_.isArray(content)) {
            return content;
        }

        const currentPage = _.result(pageable, 'currentPage') || 1;
        const pageSize = _.result(pageable, 'pageSize') || 10;
        const startIndex = (currentPage - 1) * pageSize;

        if (!_.isEmpty(pageable.order)) {
            content = orderByFilter(content, pageable.order, pageable.reverse);
        }
        
        return limitToFilter(_.slice(content, startIndex), pageSize);
    };
});
