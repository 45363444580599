'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:dicePeriodList
 * @description Manages a list of periods.
 */
angular.module('diceApp')
  .component('dicePeriodList', {
    bindings: {
      periods: '<'
    },
    templateUrl: 'es6/structure/period.list.html',
    controllerAs: 'periodListController',
    controller: function ($uibModal, Period) {
      const periodListController = this;

      periodListController.remove = function (period) {
        Period.remove(period.id).$promise.then(function () {
          periodListController.periods.splice(periodListController.periods.indexOf(period), 1);
        });
      };

      periodListController.open = function (period) {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;
            modalController.period = angular.copy(period);

            modalController.onSave = function (savedPeriod) {
              const found = _.find(periodListController.periods, { id: savedPeriod.id });
              if (found) {
                _.extend(found, savedPeriod);
              } else {
                periodListController.periods.push(savedPeriod);
              }
            };
          },
          template: `
            <period-modal
              period="modalController.period"
              on-save="modalController.onSave"
              modal="modalController.modal">
            </period-modal>'
           `
        });
      };
    }
  });
