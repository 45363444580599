'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceBenchmark
 * @description
 * diceBenchmark Shows the benchmarks.
 */
angular.module('diceApp')
  .component('diceBenchmark', {
    templateUrl: 'es6/report/benchmark/benchmark.html',
    controllerAs: 'benchmarkController',
    controller: function(Benchmark, QuestionTemplate, EvaluationType, Period, Pageable) {
        const benchmarkController = this;

        benchmarkController.$onInit = function() {
            benchmarkController.pageable = Pageable.of({
                order: 'question.name'
            });

            QuestionTemplate.query().$promise.then((questions) => {
                benchmarkController.questions = questions;
            });
            EvaluationType.query().$promise.then((types) => {
                benchmarkController.types = types;
            });
            Period.query().$promise.then((periods) => {
                benchmarkController.periods = periods;
            });

            loadData();
        };

        benchmarkController.generate = function() {
            benchmarkController.loading = true;
            Benchmark.generate({
                periodId: benchmarkController.periodId
            }).$promise.then(loadData).finally(() => {
                benchmarkController.loading = false;
            });
        };

        benchmarkController.onPeriod = function() {
            benchmarkController.period = _.find(benchmarkController.periods, { 
                id: benchmarkController.periodId
            });

            benchmarkController.search();
        };

        benchmarkController.search = function() {
            loadData();
        };

        function loadData() {
            const params = benchmarkController.pageable.build({
                questionId: benchmarkController.questionId,
                typeId: benchmarkController.typeId,
                periodId: benchmarkController.periodId
            });

            benchmarkController.loading = true;
            Benchmark.get(params).$promise.then((benchmarks) => {
                benchmarkController.benchmarks = benchmarks;
            }).finally(() => {
                benchmarkController.loading = false;
            });
        }
    }
  });
