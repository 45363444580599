'use strict';

import Chart from 'chart.js';

(function() {
  const baseController = Chart.controllers.bar;
  Chart.defaults.histogram = Chart.defaults.bar;

  Chart.controllers.histogram = Chart.controllers.bar.extend({
    draw: function () {
      // draw original bar char
      baseController.prototype.draw.apply(this, arguments);

      // get chart and context
      const chart = this.chart;
      const ctx = chart.chart.ctx;

      if (angular.isDefined(ctx) && chart.config.options.average) {
        const yAxis = chart.scales['y-axis-0'];
        const pixelForValue = getPixelForValueFromX(chart, chart.config.options.average);


        ctx.save();
        drawVerticalLine(ctx, pixelForValue, yAxis.top + 32.5, yAxis.top + 57.5, '#ff0000', 5);
        drawText(ctx, 'avg', 'center', '#ff0000', pixelForValue, yAxis.top + 20);

        if (chart.config.options.standardDeviation && chart.config.options.standardDeviation > 0) {
          const startDeviation = chart.config.options.average - chart.config.options.standardDeviation;
          const endDeviation = chart.config.options.average + chart.config.options.standardDeviation;

          // check if deviation can get drawn, maximum deviation from start and end is 0.5
          const xValues = chart.data.labels;
          if (startDeviation >= _.min(xValues) - 0.5 && endDeviation <= _.max(xValues) + 0.5) {
            const startPixelDeviation = getPixelForValueFromX(chart, startDeviation);
            const endPixelDeviation = getPixelForValueFromX(chart, endDeviation);

            drawVerticalLine(ctx, startPixelDeviation, yAxis.top + 37.5, yAxis.top + 52.5, '#000000', 2);
            drawHorizontalLine(ctx, startPixelDeviation, endPixelDeviation, 45, '#000000', 1);
            drawVerticalLine(ctx, endPixelDeviation, yAxis.top + 37.5, yAxis.top + 52.5, '#000000', 2);
          }
        }

        ctx.restore();
      }
    }
  });


  function drawText(ctx, text, align, color, x, y) {
    ctx.textAlign = align;
    ctx.fillStyle = color;
    ctx.fillText(text, x, y);
  }

  function drawVerticalLine(ctx, x, startY, endY, color, lineWidth) {
    ctx.beginPath();
    ctx.moveTo(x, startY);
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.lineTo(x, endY);
    ctx.stroke();
  }

  function drawHorizontalLine(ctx, startX, endX, y, color, lineWidth) {
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(startX, y);
    ctx.lineTo(endX, y);
    ctx.stroke();
  }

  /**
   * Custom function to get the pixel for value from x axis.
   * Because bar chart labels are shifted to be between grid lines, we need to find the center between two successive points.
   * Scale.getPixelForValue only works with values that exist in scale
   * @param chart
   * @param value
   * @returns {number}
   */
  function getPixelForValueFromX(chart, raw) {
    const axis = chart.scales['x-axis-0'];
    const values = chart.data.labels;

    let value = Math.max(raw, _.min(values));
    value = Math.min(value, _.max(values));

    const index = _.indexOf(values, value);
    if (index >= 0) {
      const pixels = axis.getPixelForValue(value);

      // X position values are the start of the bar, add half the distance
      // to another value for placing in the center
      // var other = values[index + 1] || values[index - 1];
      // if (angular.isDefined(other)) {
      //   const otherPixelValue = axis.getPixelForValue(other);
      //   const totalDistance = Math.abs(pixels - otherPixelValue);
      //   pixels += (totalDistance / 2);
      // }

      return pixels;
    } else if (value !== _.floor(value)) {
      const startPixelValue = getPixelForValueFromX(chart, _.floor(value));
      const endPixelValue = getPixelForValueFromX(chart, _.ceil(value));
      const totalDistance = endPixelValue - startPixelValue;
      const distance = totalDistance * (value % 1);
      return startPixelValue + distance;
    }
  }

})();
