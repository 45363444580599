'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:dailyParticipation
 * @description
 * dailyParticipation Shows the daily participation
 */
angular.module('diceApp').component('dailyParticipation', {
  bindings: {
    success: '<',
    fromDate: '<',
    toDate: '<'
  },
  templateUrl: 'es6/base/audit/daily.participation.html',
  controllerAs: 'dailyParticipationController',
  controller: function (diceDateFilter, ParticipantSession, Pageable, ThemedChart) {
    const dailyParticipationController = this;

    dailyParticipationController.$onInit = function() {
        dailyParticipationController.options = {};
        dailyParticipationController.colors = ThemedChart.getBarColors();

        dailyParticipationController.pageable = Pageable.of({
            order: 'participationDate',
            reverse: true
        });

        loadData();
    };

    dailyParticipationController.$onChanges = function() {
        if (dailyParticipationController.pageable) {
            loadData();
        }
    };

    dailyParticipationController.onPage = function() {
        loadData();
    };

    function loadData() {
        const parameters = dailyParticipationController.pageable.build({
            success: dailyParticipationController.success,
            fromDate: dailyParticipationController.fromDate,
            toDate: dailyParticipationController.toDate
        });

        dailyParticipationController.loading = true;
        ParticipantSession.daily(parameters).$promise.then((page) => {
            dailyParticipationController.page = page;
            dailyParticipationController.chart = formatData(page.content);
        }).finally(() => {
            dailyParticipationController.loading = false;
        });
    }

    function formatData(rows) {
        const mapped = _(rows).map((row) => {
            return {
                label: diceDateFilter(row.participationDate),
                date: row.participationDate,
                value: row.amount
            };
        }).reverse().value();

        return {
            labels: _.map(mapped, 'label'),
            data: [_.map(mapped, 'value')],
            raw: angular.copy(rows)
        };
    }
  }
});
