'use strict';

angular.module('diceApp')
  .directive('checkForUnsavedChanges', function() {
    return {
      restrict: 'A',
      bindToController: {
        checkForUnsavedChanges: '<',
        ngSubmit: '&?'
      },
      controllerAs: 'checkForUnsavedChangesController',
      controller: function($scope, $q, $uibModal) {
        const checkForUnsavedChangesController = this;
        $scope.$on('$stateChangeStart', function(event, toState, toParams) {
          if (toState.ignore === true) {
            toState.ignore = false;
            return;
          }

          const form = checkForUnsavedChangesController.checkForUnsavedChanges;
          if (angular.isDefined(form) && form.$dirty) {
            event.preventDefault();
            $uibModal.open({
              templateUrl: 'es6/base/check-for-unsaved-changes/unsaved.changes.modal.html',
              controllerAs: 'modalController',
              controller: function ($uibModalInstance, $state) {
                const modalController = this;
                modalController.showSaveButton = angular.isDefined(checkForUnsavedChangesController.ngSubmit);

                modalController.save = function () {
                  const promise = checkForUnsavedChangesController.ngSubmit();
                  $q.resolve(promise).then(() => modalController.discard());
                };

                modalController.discard = function () {
                  modalController.dismiss();
                  toState.ignore = true;
                  $state.go(toState, toParams);
                };

                modalController.dismiss = function () {
                  $uibModalInstance.dismiss();
                };
              }
            });
          }
        });
      }
    };
  });
