'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:Script
 * @description
 * The Script service.
 */
angular.module('diceApp').factory('ScheduledScript', function(DiceResource) {
    return DiceResource('/schedule-scripts');
});
