'use strict';

import timeeditLogo from '../../../images/timeedit.svg';

angular.module('diceApp')
  .component('diceFooter', {
    templateUrl: 'es6/base/layout/footer.html',
    controllerAs: 'footerController',
    controller: function ($q, $filter, $rootScope, SystemInfo, Customer) {
      const footerController = this;

      footerController.$onInit = function() {
        footerController.APP = $rootScope.APP;

        footerController.coverage = '';
        footerController.REST = { version: 'fetching...', buildTime: 'fetching...' };
        footerController.timeeditLogoUrl = timeeditLogo;

        $q.all([
          Customer.load(),
          getStatus(),
          SystemInfo.clientInfo().$promise,
          SystemInfo.info().$promise
        ]).then(([customer, status, clientInfo, info]) => {
          footerController.title = customer.title;
          footerController.environment = $rootScope.ENV;
          footerController.REST.client = clientInfo.file;
          footerController.REST.version = info.build.version;
          footerController.REST.buildTime = parseBuildTime(info.build.time);
          footerController.REST.status = status;
          footerController.REST.statusLabel = getStatusLabel();
        });
      };

      function getStatus() {
        return SystemInfo.criticalHealth().$promise.then((criticalHealth) => {
          return criticalHealth.status;
        }).catch((response) => {
          if (response.status === 503 && response.data.status === 'DOWN') {
            return 'DOWN';
          } else {
            return 'UNKNOWN';
          }
        });
      }

      function getStatusLabel() {
        const status = _.get(footerController.REST, 'status', 'UNKNOWN');
        return `Footer.About.Api.Status.${status}`;
      }

      function parseBuildTime(text) {
        const date_args = (text || '').match(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})/) || [];
        if (date_args.length !== 6) {
          return text;
        }

        // backend build-time comes in UTC...
        const backendBuildTime = new Date(Date.UTC(date_args[1], date_args[2] - 1, date_args[3], date_args[4], date_args[5]));
        return $filter('diceDateTime')(backendBuildTime);
      }
    }
  });
