'use strict';

// The htmls are not automatically detected by angularjs-template-loader
// and are therefore manually required.
require('./preview.question.modal.html');
require('./preview.block.modal.html');
require('./preview.evaluation.modal.html');

/**
 * @ngdoc directive
 * @name diceApp.directive:TemplatePreviewButton
 * @description Opens a template preview modal.
 */
angular.module('diceApp').component('templatePreviewButton', {
  templateUrl: 'es6/preview/modal/template.preview.button.html',
  bindings: {
    type: '@', // question, evaluation or block
    template: '<',
    callback: '<?',
    btnSize: '@?',
    btnType: '@?',
    btnContext: '@?'
  },
  controllerAs: 'buttonController',
  controller: function ($uibModal, $q) {
    const buttonController = this;

    /**
     * Opens the modal with our preview.
     */
    buttonController.open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      return loadData().then(open);
    };

    function open(template) {
      return $uibModal.open({
        templateUrl: getTemplateUrl(),
        controllerAs: 'previewController',
        controller: function ($uibModalInstance) {
          const previewController = this;
          previewController.template = template;

          previewController.confirm = function () {
            $uibModalInstance.close();
          };

          previewController.close = function () {
            $uibModalInstance.dismiss('closed');
          };

          previewController.refresh = function () {
            loadData().then((newTemplate) => {
              previewController.close();
              open(newTemplate);
            });
          };
        },
        size: getSize(template)
      });
    }

    function getSize(template) {
      let size = 'lg';

      const sidebarNavigation = _.get(template, 'type.sidebarNavigation', false);
      if (sidebarNavigation) {
        size = 'xl';
      }

      return size;
    }

    function getTemplateUrl() {
      /**
       * The AngularJS-template-loader relies on templateUrl declarations to determine which files to include in the Webpack production build.
       * Therefore, dynamic templateUrl construction (e.g., 'es6/preview/modal/' + buttonController.type + '.html') is not supported.
       * @see https://www.npmjs.com/package/angularjs-template-loader
       */
      switch (buttonController.type) {
        case 'question':
          return 'preview.question.modal.html';
        case 'block':
          return 'preview.block.modal.html';
        case 'evaluation':
          return 'preview.evaluation.modal.html';
        default:
          // eslint-disable-next-line no-console, angular/log
          return console.error('Invalid template preview type specified: ', buttonController.type);
      }
    }

    function loadData() {
      if (angular.isDefined(buttonController.callback)) {
        const result = buttonController.callback(buttonController.template);
        if (result.then) {
          return result;
        } else if (result.$promise) {
          return result.$promise;
        } else {
          return $q.resolve(result);
        }
      } else {
        return $q.resolve(buttonController.template);
      }
    }
  }
});
