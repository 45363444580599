'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceCsvUpload
 * @description
 * diceCsvUpload Upload CSV files
 */
angular.module('diceApp').component('diceCsvUpload', {
  templateUrl: 'es6/csv/csv.upload.html',
  controllerAs: 'uploadController',
  controller: function (Csv, Upload, message, fileSizeFilter) {
    const uploadController = this;

    uploadController.$onInit = function () {
      uploadController.loading = true;
      uploadController.maxSize = 2000000;

      Csv.get().$promise.then((parameters) => {
        uploadController.types = parameters.types;
        uploadController.parameters = _.pick(parameters, [
          'separator',
          'quote'
        ]);
      }).finally(() => {
        uploadController.loading = false;
      });
    };

    uploadController.onType = function() {
      Csv.document({
        type: uploadController.parameters.type
      }).$promise.then((document) => {
        uploadController.document = document;
      });
    };

    uploadController.clear = function () {
      delete uploadController.result;
    };

    uploadController.upload = function () {
      uploadController.clear();

      if (angular.isUndefined(uploadController.file)) {
        return;
      }
      if (uploadController.file.size > uploadController.maxSize) {
        message.addError(
          'Maximum file size is: ' + fileSizeFilter(uploadController.maxSize)
        );
        return;
      }

      uploadController.loading = true;

      const upload = Upload.upload({
        url: 'api/csv',
        data: {
          file: uploadController.file,
          type: uploadController.parameters.type,
          separator: uploadController.parameters.separator,
          quote: uploadController.parameters.quote
        }
      });

      upload.then((response) => {
        uploadController.result = response.data;
        uploadController.error = undefined;
        uploadController.file = undefined;
        uploadController.loading = false;
      });

      upload.error(() => {
        uploadController.file = undefined;
        uploadController.loading = false;
      });
    };
  }
});
