'use strict';

/**
 * @ngdoc component
 * @name diceApp.component:evaluationCopy
 */
angular.module('diceApp')
  .component('evaluationCopy', {
    bindings: {
      base: '<'
    },
    templateUrl: 'es6/evaluations/create/evaluation.copy.html',
    controllerAs: 'evaluationCopyController',
    controller: function ($state, message, Evaluation, evaluationCopier) {
      const evaluationCopyController = this;

      evaluationCopyController.$onInit = function() {
        evaluationCopyController.evaluation = {
          type: evaluationCopyController.base.type,
          template: evaluationCopyController.base.template,
          tags: _.map(evaluationCopyController.base.tags, (tag) => _.omitBy(tag, 'id'))
        };
      };

      evaluationCopyController.save = function(evaluation) {
        const body = angular.copy(evaluation);
        body.type = body.type.id;
        body.evaluation = evaluationCopier.copy(evaluationCopyController.base);

        Evaluation.save(body).$promise.then(function (result) {
          message.addSuccessLabel('Message.Save.Success');
          $state.go('evaluation.general', { id: result.id });
        });
      };
    }
  });
