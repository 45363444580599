'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:SyncronizationLog
 * @description
 * The SyncronizationLog resource.
 */
angular.module('diceApp')
    .factory('SyncronizationLog', function(DiceResource) {
        return DiceResource('/syncronization-logs');
    });
