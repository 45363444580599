'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:personLabel
 * @personLabel Displays a person
 */
angular.module('diceApp')
  .component('personLabel', {
    bindings: {
      person: '<',
      active: '<?',
      labelContext: '<?'
    },
    templateUrl: 'es6/members/person.label.html',
    controllerAs: 'personLabelController'
});