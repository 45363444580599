'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:evaluationTemplateQuestionsModal
 * @description Allows users to pick which blocks to use.
 */
angular.module('diceApp')
  .component('evaluationTemplateQuestionsModal', {
    bindings: {
      block: '<',
      onCancel: '&'
    },
    templateUrl: 'es6/evaluations/evaluation.template.questions.modal.html',
    controllerAs: 'questionsController',
    controller: function () {
      const questionsController = this;

      questionsController.cancel = function() {
        questionsController.onCancel();
      };
    }
  });
