'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceEvaluationFilter
 */
angular.module('diceApp')
  .component('diceEvaluationFilter', {
    bindings: {
      periods: '<',
      filter: '=',
      statuses: '<',
      types: '<',
      onChange: '&',
      reset: '&',
      count: '&'
    },
    templateUrl: 'es6/evaluations/evaluation.filter.html',
    controllerAs: 'evaluationFilterController',
    controller: function ($scope, MemberType) {
      const evaluationFilterController = this;

      evaluationFilterController.$onInit = function () {
        evaluationFilterController.filterCount = evaluationFilterController.count();

        filterStatuses();
        loadMemberTypes();
      };

      function loadMemberTypes() {
        if (evaluationFilterController.filter.type) {
          MemberType.query({
            evaluationType: evaluationFilterController.filter.type
          }).$promise.then((memberTypes) => {
            evaluationFilterController.memberTypes = _.filter(memberTypes, {
              ignore: false,
              participant: false
            });
          });
        }
      }

      $scope.$watch('evaluationFilterController.filter', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          evaluationFilterController.filterCount = evaluationFilterController.count();
          evaluationFilterController.onChange();
        }
      }, true);

      function filterStatuses() {
        evaluationFilterController.filteredStatuses = _.filter(evaluationFilterController.statuses, {
          evaluationType: evaluationFilterController.filter.type
        });
      }

      evaluationFilterController.onTypeChange = function () {
        filterStatuses();
        loadMemberTypes();
      };
    }
  });
