'use strict';

angular.module('diceApp').component('substituteList', {
  bindings: {
    person: '<'
  },
  templateUrl: 'es6/members/substitute/substitute.list.html',
  controllerAs: 'substituteListController',
  controller: function ($uibModal, authentication, message, PersonSubstitute, Pageable) {
    const substituteListController = this;

    substituteListController.$onInit = function() {
      substituteListController.hasRole = authentication.hasRole;
      substituteListController.pageable = Pageable.of({
        order: 'externalId'
      });

      loadData();
    };

    function loadData() {
      substituteListController.loading = true;
      PersonSubstitute.query({
        personId: substituteListController.person.id
      }).$promise.then((substitutes) => {
        substituteListController.substitutes = substitutes;
      }).finally(() => {
        substituteListController.loading = false;
      });
    }

    substituteListController.assign = function() {
      $uibModal.open({
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.person = angular.copy(substituteListController.person);

          modalController.close = function() {
            $uibModalInstance.dismiss();
          };

          modalController.onSave = function() {
            loadData();
            modalController.close();
            message.addSuccessLabel('Message.Save.Success');
          };
        },
        template: `
          <assign-substitutes
            person="modalController.person"
            on-cancel="modalController.close()"
            on-save="modalController.onSave()">
          </assign-substitutes>
        `
      });
    };

    substituteListController.remove = function (substitute) {
      PersonSubstitute.remove(substitute.id).$promise.then(() => {
        message.addSuccessLabel('Message.Delete.Success');
        loadData();
      });
    };
  }

});