'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:evaluationTemplatePicker
 * @description Allows users to pick which blocks to use.
 */
angular.module('diceApp')
  .component('evaluationTemplatePicker', {
    bindings: {
      evaluation: '<',
      onSave: '&',
      modal: '<'
    },
    templateUrl: 'es6/evaluations/evaluation.template.picker.modal.html',
    controllerAs: 'evaluationTemplatePickerController',
    controller: function (Evaluation, EvaluationTemplate) {
      const evaluationTemplatePickerController = this;

      evaluationTemplatePickerController.$onInit = function () {
        evaluationTemplatePickerController.template = evaluationTemplatePickerController.evaluation.template;
        EvaluationTemplate.query().$promise.then((templates) => {
          evaluationTemplatePickerController.templates = _.filter(templates, function(template) {
            return template.type.id === evaluationTemplatePickerController.evaluation.type.id;
          });
        });
      };

      evaluationTemplatePickerController.save = function () {
        Evaluation.pickTemplate({
          evaluationId: evaluationTemplatePickerController.evaluation.id
        }, {
          template: evaluationTemplatePickerController.template
        }).$promise.then((evaluation) => {
          evaluationTemplatePickerController.onSave({ evaluation });
        });
      };

      evaluationTemplatePickerController.close = function () {
        evaluationTemplatePickerController.modal.close();
      };

    }
  });
