'use strict';

angular.module('diceApp').factory('requestQueue', function($q) {

  const requestQueueFactory = this;
  requestQueueFactory.requestQueue = [];

  /**
   * Adds a new request to the request queue
   * @param request Request to an Angular resource
   * @param params Parameters to the request.
   * @param body Body to include with the request.
   * @returns Object of the request sent to the queue. Use the result item of this object (Promise) to perform post-request actions.
   */
  requestQueueFactory.push = function(request, params, body) {

    const resultPromise = $q.defer();
    const queueItem = { request: request, params: params, body: body, result: resultPromise };

    requestQueueFactory.requestQueue.push(queueItem);

    if (requestQueueFactory.requestQueue.length === 1) {
      execute();
    }

    return queueItem;
  };

  /**
   * Clears the request queue.
   */
  requestQueueFactory.clear = function() {
    requestQueueFactory.requestQueue = [];
  };

  function execute() {
    const item = _.head(requestQueueFactory.requestQueue);

    if (!_.isEmpty(item)) {
      const promise = item.request(item.params, item.body);

      if (angular.isDefined(promise.$promise)) {
        promise.$promise
          .then(function(result) {
            item.result.resolve(result);
            next();
          })
          .catch(function(error) {
            item.result.reject(error);
            next();
          });
      } else {
        promise
          .then(function(result) {
            item.result.resolve(result);
            next();
          })
          .catch(function(error) {
            item.result.reject(error);
            next();
          });
      }
    }
  }

  /**
   * Removes the first request from the queue and executes the next request .
   */
  function next() {
      requestQueueFactory.requestQueue.shift();

      if (!_.isEmpty(requestQueueFactory.requestQueue)) {
        execute();
      }
  }

  return requestQueueFactory;

});
