'use strict';

angular.module('diceApp').component('queryTemplateButton', {
    bindings: {
        type: '<',
        template: '<',
        onChange: '&',
        getColumns: '&'
    },
    templateUrl: 'es6/query/template/query.template.button.html',
    controllerAs: 'queryTemplateButtonController',
    controller: function (QueryTemplate, $uibModal) {
        const queryTemplateButtonController = this;

        queryTemplateButtonController.select = function() {
            $uibModal.open({
                controllerAs: 'queryTemplateModalController',
                controller: function($uibModalInstance) {
                    const queryTemplateModalController = this;
                    queryTemplateModalController.type = queryTemplateButtonController.type;
                    queryTemplateModalController.selected = queryTemplateButtonController.template;

                    queryTemplateModalController.onSelect = function(template) {
                        queryTemplateButtonController.onChange({ template });
                        queryTemplateModalController.close();
                    };

                    queryTemplateModalController.close = function() {
                        $uibModalInstance.close();
                    };
                },
                size: 'lg',
                template: `
                    <query-template-select-modal
                        type="queryTemplateModalController.type"
                        selected="queryTemplateModalController.selected"
                        on-select="queryTemplateModalController.onSelect(template)"
                        on-cancel="queryTemplateModalController.close()">
                    </query-template-select-modal>
                `
            });
        };

        queryTemplateButtonController.open = function() {
            $uibModal.open({
                controllerAs: 'queryTemplateModalController',
                controller: function($uibModalInstance) {
                    const queryTemplateModalController = this;
                    queryTemplateModalController.template = build();

                    queryTemplateModalController.onChange = function(template) {
                        queryTemplateButtonController.onChange({ template });
                        queryTemplateModalController.close();
                    };

                    queryTemplateModalController.close = function() {
                        $uibModalInstance.close();
                    };
                },
                template: `
                    <query-template-edit-modal
                        template="queryTemplateModalController.template"
                        on-change="queryTemplateModalController.onChange(template)"
                        on-cancel="queryTemplateModalController.close()">
                    </query-template-edit-modal>
                `
            });
        };

        function build() {
            const template = queryTemplateButtonController.template || {};
            const columns = queryTemplateButtonController.getColumns();

            return _.extend(template, {
                type: queryTemplateButtonController.type,
                headers: columns
            });
        }
    }
});
