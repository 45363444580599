'use strict';

import contentCopy from '../../../images/ic_content_copy_black_24dp_2x.png';

/**
 * @ngdoc function
 * @name diceApp.component:diceEvaluationTemplate
 * @description
 * diceEvaluationTemplate Shows the evaluation templates in detail.
 */
angular.module('diceApp')
  .component('diceEvaluationTemplate', {
    bindings: {
      template: '<'
    },
    bindToController: true,
    templateUrl: 'es6/templates/evaluation/evaluation.template.html',
    controllerAs: 'templateController',
    controller: function(EvaluationTemplate, BlockTemplate, EvaluationType, $scope, message, $state, evaluationTemplatePreparator) {
      const templateController = this;

      templateController.$onInit = function () {
        templateController.showAllBlocks = false;

        if (angular.isDefined(templateController.template.type)) {
          EvaluationType.get({
            id: templateController.template.type
          }).$promise.then((evaluationType) => {
            templateController.evaluationType = evaluationType;
          });
        }

        BlockTemplate.query().$promise.then(function(blocks) {
          templateController.blocks = blocks;
          filterBlocks();
          loadData();
        });
      };

      function filterBlocks() {
        templateController.filteredBlocks = _.filter(templateController.blocks, function(block) {
          return templateController.showAllBlocks || templateController.template.type === block.evaluationType;
        });
      }

      $scope.$watch('templateController.showAllBlocks', function () {
        filterBlocks();
        loadData();
      });

      templateController.save = function (template, form) {
        templateController.prepare(template);

        var body = angular.copy(template);
        body.blocks = _(templateController.template.blocks).map((block, index) => {
          const result = _.pick(block, ['blockTemplateId', 'defaultSelected', 'required']);
          result.sequence = index + 1;
          return result;
        }).value();

        return EvaluationTemplate.store(body).$promise.then(function(result) {
          form.$setPristine();
          if (angular.isUndefined(templateController.template.id)) {
            $state.go('evaluation-template', { id: result.id });
          }
          message.addSuccessLabel('Message.Save.Success');
        });
      };

      templateController.prepare = function(template) {
        const preview = angular.copy(template);

        return EvaluationTemplate.blocks({
          id: template.id
        }).$promise.then((blocks) => {
          preview.blocks = blocks;

          _.each(preview.blocks, function (block, index) {
              block.sequence = index + 1;
          });
          
          return evaluationTemplatePreparator.prepare(preview);
        });
      };

      templateController.prepareBlock = function(block) {
        const preview = angular.copy(block);

        return BlockTemplate.get({
          id: block.id
        }).$promise.then((result) => {
          preview.questions = result.questions;
          return preview;
        });
      };

      templateController.remove = function () {
        EvaluationTemplate.remove(templateController.template.id).$promise.then(function() {
          message.addSuccessLabel('Message.Delete.Success');
          $state.go('evaluation-template-list');
        });
      };

      function loadData() {
        templateController.template.maximumAdditionalQuestions = (!templateController.template.maximumAdditionalQuestions) ? 0 : templateController.template.maximumAdditionalQuestions;

        templateController.template.blocks = _.map(templateController.template.blocks, function(b) {
          b.blockTemplateId = b.block.id;
          return b;
        });

        templateController.filteredBlocks = templateController.filteredBlocks.filter(function(item) {
          return !templateController.template.blocks.some(function(chosen) {
            return item.id === chosen.blockTemplateId;
          });
        }).map(function(b) {
          return { block: b, blockTemplateId: b.id };
        });

        templateController.template.blocks = _.orderBy(templateController.template.blocks, ['sequence'], ['asc']);
      }

      // --- Drag and drop methods start here --- //
      /**
       * dnd-dragging determines what data gets serialized and send to the receiver
       * of the drop. While we usually just send a single object, we send the array
       * of all selected items here.
       */
      templateController.getSelectedItemsIncluding = function (list, item) {
        item.selected = true;
        return list.filter((it) => {
          return it.selected;
        });
      };

      /**
       * We set the list into dragging state, meaning the items that are being
       * dragged are hidden. We also use the HTML5 API directly to set a custom
       * image, since otherwise only the one item that the user actually dragged
       * would be shown as drag image.
       */
      templateController.onDragstart = function (list, event) {
        list.dragging = true;
        if (event.dataTransfer.setDragImage) {
          var img = new Image();
          img.src = contentCopy;
          event.dataTransfer.setDragImage(img, 0, 0);
        }
      };


      /**
       * In the dnd-drop callback, we now have to handle the data array that we
       * sent above. We handle the insertion into the list ourselves. By returning
       * true, the dnd-list directive won't do the insertion itself.
       */
      templateController.onDrop = function (type, items, index) {
        angular.forEach(items, function (item) {
          item.selected = false;
        });

        if (type === 'ALL') {
          templateController.filteredBlocks = templateController.filteredBlocks.slice(0, index)
            .concat(items)
            .concat(templateController.filteredBlocks.slice(index));
        } else {
          templateController.template.blocks = templateController.template.blocks.slice(0, index)
            .concat(items)
            .concat(templateController.template.blocks.slice(index));
        }
        return true;
      };

      /**
       * Last but not least, we have to remove the previously dragged items in the
       * dnd-moved callback.
       */
      templateController.onMoved = function (type, list) {
        if (type === 'ALL') {
          templateController.filteredBlocks = list.filter(function (item) {
            return !item.selected;
          });
        } else {
          templateController.template.blocks = list.filter(function (item) {
            return !item.selected;
          });
        }
      };
    }
  });
