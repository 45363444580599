'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:SaveButton
 * @description
 * The SaveButton service.
 */
angular.module('diceApp').component('saveButton', {
  bindings: {
    form: '<?',
    btnType: '@?',
    btnLabel: '@?',
    btnSize: '@?',
    btnIconOnly: '=?',
    btnContxt: '@?',
    btnDisabled: '<?'
  },
  templateUrl: 'es6/base/forms/buttons/save.button.html',
  controllerAs: 'saveButtonController',
  controller: function () {
    const saveButtonController = this;

    saveButtonController.$onInit = function () {
      saveButtonController.btnContext = saveButtonController.btnContext || 'success';
    };
  }
});
