'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:Enums
 * @description
 * The Enums service.
 */
angular.module('diceApp')
    .factory('Enums', function(DiceResource) {
        return DiceResource('/enum');
    });
