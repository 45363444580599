'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceEvaluationTimer
 * @description
 * diceEvaluationTimer Shows the blocks of an evaluation.
 */
angular.module('diceApp')
  .component('diceEvaluationTimer', {
    bindings: {
      evaluation: '<',
      onClose: '&'
    },
    templateUrl: 'es6/evaluations/evaluation.timer.modal.html',
    controllerAs: 'timerController',
    controller: function ($timeout, $location, Evaluation, EvaluationSession) {
      const timerController = this;

      timerController.$onInit = function () {
        const path = `api/participate/${timerController.evaluation.id}`;
        timerController.url = `${$location.protocol()}://${location.host}/${path}`;

        reset();
        countdown();

        setSession();
        setProgress();
      };

      function setSession() {
        EvaluationSession.current({
          evaluationId: timerController.evaluation.id
        }).$promise.then(({ session, allowed }) => {
          timerController.session = session;
          timerController.allowed = allowed;
          timerController.anonymous = allowed && angular.isDefined(session);
        });
      }

      timerController.setAnonymous = function (anonymous) {
        timerController.anonymous = timerController.allowed && anonymous;
      };

      timerController.close = function () {
        timerController.timer = false;

        $timeout.cancel(timerController.timeout);
        timerController.onClose();
      };

      timerController.start = function () {
        EvaluationSession.save({}, {
          evaluation: timerController.evaluation.id
        }).$promise.then((session) => {
          timerController.session = session;
        });
      };

      timerController.stop = function () {
        EvaluationSession.remove(
          timerController.session.id
        ).$promise.then(() => {
          delete timerController.session;
        });
      };

      function setProgress() {
        Evaluation.progress({
          id: timerController.evaluation.id
        }).$promise.then((progress) => {
          timerController.progress = progress;
        });
      }

      function countdown() {
        timerController.value += 1;
        timerController.time = formatSeconds(timerController.value);
        if (timerController.value % 5 === 0) {
          setProgress(); // Update progress every 5 seconds
        }

        // Repeat
        timerController.timeout = $timeout(countdown, 1000);
      }

      function formatSeconds(seconds) {
        var minutes = Math.floor(seconds / 60);
        var remainder = seconds % 60;
        return ensureTwoDigits(minutes) + ':' + ensureTwoDigits(remainder);
      }

      function ensureTwoDigits(fragment) {
        var current = '' + fragment;
        if (current.length === 1) {
          current = '0' + current;
        }
        return current;
      }

      function reset() {
        timerController.value = 0;
        timerController.time = formatSeconds(timerController.value);
      }
    }
  });
