'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:SystemInfo
 * @description
 * # SystemInfo
 * SystemInfo resource of the diceApp
 */
angular.module('diceApp')
  .factory('SystemInfo', function ($resource) {
    return $resource('/api/actuator', {}, {
      environment: {
        method: 'GET',
        url: '/api/actuator/environment',
        isArray: false
      },
      properties: {
        method: 'GET',
        url: '/api/actuator/properties',
        isArray: false
      },
      info: {
        method: 'GET',
        url: '/api/actuator/info',
        isArray: false
      },
      criticalHealth: {
        method: 'GET',
        url: '/api/actuator/health/critical',
        isArray: false
      },
      clientInfo: {
        method: 'GET',
        url: '/api/actuator/clientInfo',
        isArray: false
      }
    });
  });
