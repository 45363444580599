'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:ReportStates
 * @description
 * # ReportStates
 * Configures all states in relation to report.
 */
angular.module('diceApp').config(function ($stateProvider) {

  $stateProvider.state('report-template-list', {
    url: '/report-templates',
    data: {
      root: true
    },
    template: '<dice-report-template-list></dice-report-template-list>'
  });

  $stateProvider.state('report-template-edit', {
    url: '/report-template/{id:[0-9]{1,8}}',
    data: {
      root: true
    },
    resolve: {
      template: function (ReportTemplate, $stateParams) {
        return ReportTemplate.get({
          id: $stateParams.id
        }).$promise;
      }
    },
    controllerAs: 'reportTemplateStateController',
    controller: function (template) {
      const reportTemplateStateController = this;
      reportTemplateStateController.template = template;
    },
    template: '<dice-report-template report-template="reportTemplateStateController.template"></dice-report-template>'
  });

  $stateProvider.state('report-template-create', {
    url: '/report-template/create',
    controllerAs: 'reportTemplateStateController',
    controller: function (ReportTemplate) {
      const reportTemplateStateController = this;
      reportTemplateStateController.template = ReportTemplate.build();
    },
    template: '<dice-report-template report-template="reportTemplateStateController.template"></dice-report-template>'
  });

  $stateProvider.state('report-template-copy', {
    url: '/report-template/{id:[0-9]{1,8}}/copy',
    resolve: {
     original: function($stateParams, ReportTemplate) {
      return ReportTemplate.get({
         id: $stateParams.id
       }).$promise;
     }
    },
    controllerAs: 'reportTemplateStateController',
    controller: function(ReportTemplate, original) {
      this.template = ReportTemplate.copy(original);
    },
    template: '<dice-report-template report-template="reportTemplateStateController.template"></dice-report-template>'
  });

});
