'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:Pageable
 * @description
 * The Pageable service.
 */
angular.module('diceApp')
  .factory('Pageable', function (Sort) {

    const defaults = {
        currentPage: 1,
        pageSize: 10,
        maxSize: 3,
        order: '',
        reverse: false
    };

    function parse(parameters) {
        const sort = Sort.parse(parameters.sort);

        const values = {
            currentPage: parseInt(parameters.page || '1'),
            pageSize: parseInt(parameters.size || '10'),
            order: sort.order,
            reverse: sort.reverse
        };

        return of(values);
    }

    function of(initial) {
        let pageable = _.extend(angular.copy(defaults), initial);

        return _.extend(pageable, {
            get: function() {
                return {
                    page: this.currentPage,
                    size: this.pageSize,
                    sort: Sort.get(this)
                };
            },
            build: function(args) {
                const params = this.get();
                return _.extend(params, angular.copy(args));
            }
        });
    }

    return { of, parse };
  });