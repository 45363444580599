'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:CreateButton
 * @description
 * The CreateButton service.
 */
angular.module('diceApp')
  .directive('createButton', function () {
    return {
      restrict: 'E',
      scope: {
        btnType: '@?',
        btnSize: '@?',
        btnIconOnly: '=?',
        btnContext: '@?',
        type: '@?'
      },
      link: function (scope) {
        scope.btnContext = scope.btnContext || 'default';
      },
      templateUrl: 'es6/base/forms/buttons/create.button.html'
    };
  });
