'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:notificationTemplateSelect
 * @description
 * The notificationTemplateSelect directive.
 */
angular.module('diceApp')
  .component('notificationTemplateSelect', {
    templateUrl: 'es6/notifications/template/notifications.template.select.html',
    require: {
      ngModel: 'ngModel'
    },
    controllerAs: 'notificationTemplateSelectController',
    controller: function (NotificationTemplate, $scope) {
      const notificationTemplateSelectController = this;

      notificationTemplateSelectController.$onInit = function () {
        loadData();
        $scope.$watch('notificationTemplateSelectController.evaluationType', loadData);
        $scope.$watch('notificationTemplateSelectController.ngModel.$modelValue', function () {
          notificationTemplateSelectController.value = notificationTemplateSelectController.ngModel.$modelValue;
        });
      };

      notificationTemplateSelectController.onChange = function () {
        notificationTemplateSelectController.ngModel.$setViewValue(notificationTemplateSelectController.value);
      };

      function loadData() {
        NotificationTemplate.query({}).$promise.then(function(templates) {
          notificationTemplateSelectController.templates = _.filter(templates, { 
            evaluationType: notificationTemplateSelectController.evaluationType 
          });
        });
      }
    }
  });
