'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Evaluation
 * @description
 * # Evaluation
 * Evaluation resource of the diceApp
 */
angular.module('diceApp')
  .factory('Evaluation', function ($q, DiceResource, Block) {
    const resource = DiceResource('/evaluations', {
      mine: {
        method: 'GET',
        url: '/evaluations/mine',
        isArray: true
      },
      search: {
        method: 'GET',
        url: '/evaluations/search'
      },
      report: {
        method: 'GET',
        url: '/evaluations/query'
      },
      progress: {
        method: 'GET',
        url: '/evaluations/:id/progress'
      },
      type: {
        method: 'GET',
        url: '/evaluations/:id/type'
      },
      changeStatus: {
        method: 'POST',
        url: '/evaluations/:evaluationId/status/:status'
      },
      validateStatus: {
        method: 'POST',
        url: '/evaluations/:evaluationId/validate'
      },
      saveGeneral: {
        method: 'PUT',
        url: '/evaluations/:id/general'
      },
      saveExtraQuestions: {
        method: 'PUT',
        url: '/evaluations/:evaluationId/extra-questions'
      },
      deleteExtraQuestions: {
        method: 'DELETE',
        url: '/evaluations/:evaluationId/extra-questions/:blockId'
      },
      saveExtraBlocks: {
        method: 'PUT',
        url: '/evaluations/:evaluationId/extra-blocks'
      },
      pickTemplate: {
        method: 'PUT',
        url: '/evaluations/:evaluationId/change-template'
      },
      changeBlockActiveStatus: {
        method: 'PUT',
        url: '/evaluations/:evaluationId/select-blocks'
      },
      archive: {
        method: 'POST',
        url: '/archives/:evaluationId'
      },
      calculate: {
        method: 'POST',
        url: '/evaluations/:evaluationId/calculate'
      },
      tags: {
        method: 'GET',
        url: '/evaluations/tags',
        isArray: true
      },
      bulkEdit: {
        method: 'POST',
        url: '/evaluations/bulk-edit'
      },
      bulkMember: {
        method: 'POST',
        url: '/evaluations/bulk-member'
      },
      bulkQuestion: {
        method: 'POST',
        url: '/evaluations/bulk-question'
      },
      bulkChangeStatus: {
        method: 'POST',
        url: '/evaluations/bulk-status'
      },
      bulkChangeNotification: {
        method: 'POST',
        url: '/evaluations/bulk-notification'
      },
      bulkCalculate: {
        method: 'POST',
        url: '/evaluations/bulk-calculate'
      },
      bulkArchive: {
        method: 'POST',
        url: '/evaluations/bulk-archive'
      },
      logs: {
        method: 'GET',
        isArray: true,
        url: '/evaluations/:evaluationId/logs'
      }
    });

    resource.getFull = function (id) {
      return $q.all([
        resource.get({ id }).$promise,
        Block.query({
          evaluationId: id
        }).$promise
      ]).then(([evaluation, blocks]) => {
        evaluation.blocks = blocks;
        return evaluation;
      });
    };

    return resource;
  });
