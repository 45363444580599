'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:scheduleWorkflowCalculateModal
 * @description Shows a modal to manage a schedule workflow calculation.
 */
angular.module('diceApp')
  .component('scheduleWorkflowCalculateModal', {
    bindings: {
      evaluation: '<',
      onSubmit: '&',
      onCancel: '&'
    },
    templateUrl: 'es6/workflow/schedule/schedule.workflow.calculate.modal.html',
    controllerAs: 'scheduleWorkflowCalculateController',
    controller: function (ScheduleWorkflow) {
      const scheduleWorkflowCalculateController = this;

      scheduleWorkflowCalculateController.$onInit = function() {
        loadData();
      };

      function loadData() {
        scheduleWorkflowCalculateController.loading = true;
        ScheduleWorkflow.calculate({
          evaluationId: scheduleWorkflowCalculateController.evaluation.id,
          startDate: scheduleWorkflowCalculateController.evaluation.startDate,
          reminderDate: scheduleWorkflowCalculateController.evaluation.reminderDate,
          endDate: scheduleWorkflowCalculateController.evaluation.endDate
        }).$promise.then((schedules) => {
          scheduleWorkflowCalculateController.schedules = schedules;
          scheduleWorkflowCalculateController.next = _.find(schedules, { next: true });
        }).finally(() => {
          scheduleWorkflowCalculateController.loading = false;
        });
      }

      scheduleWorkflowCalculateController.submit = function() {
        scheduleWorkflowCalculateController.onSubmit();
      };

      scheduleWorkflowCalculateController.cancel = function() {
        scheduleWorkflowCalculateController.onCancel();
      };
    }
  }
);
