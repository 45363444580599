'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Organisation
 * @description
 * # Organisation
 * Organisation resource of the diceApp
 */
angular.module('diceApp')
  .factory('Organisation', function (DiceResource) {
    return DiceResource('/organisations');
  });
