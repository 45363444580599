'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:MemberTypeSelect
 * @description
 * The MemberTypeSelect directive.
 */
angular.module('diceApp')
  .component('memberTypeSelect', {
    bindings: {
      evaluationType: '='
    },
    templateUrl: 'es6/members/type/member.type.select.html',
    require: {
      ngModel: 'ngModel'
    },
    controllerAs: 'memberTypeSelectController',
    controller: function (MemberType, $scope) {
      const memberTypeSelectController = this;
      memberTypeSelectController.$onInit = function () {
        $scope.$watch('memberTypeSelectController.evaluationType', loadData);
        $scope.$watch('memberTypeSelectController.ngModel.$modelValue', function () {
          memberTypeSelectController.value = memberTypeSelectController.ngModel.$modelValue;
        });
      };

      loadData();

      memberTypeSelectController.onChange = function () {
        memberTypeSelectController.ngModel.$setViewValue(memberTypeSelectController.value);
      };

      function loadData() {
        MemberType.query({}).$promise.then(function(memberTypes) {
          memberTypeSelectController.memberTypes = _.filter(memberTypes, {
            evaluationType: memberTypeSelectController.evaluationType
          });
        });
      }

    }
  });
