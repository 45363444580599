'use strict';

angular.module('diceApp').component('expander', {
    bindings: {
        label: '@',
        open: '<',
        onChange: '&'
    },
    templateUrl: 'es6/base/expander.html',
    controllerAs: 'expanderController',
    controller: function() {
        const expanderController = this;

        expanderController.toggle = function() {
            expanderController.open = !expanderController.open;
            expanderController.onChange({
                open: expanderController.open
            });
        };
    }
});
