'use strict';

angular.module('diceApp').component('queryTemplateEditModal', {
    bindings: {
        template: '<',
        onChange: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/query/template/query.template.edit.modal.html',
    controllerAs: 'queryTemplateEditController',
    controller: function (QueryTemplate, authentication, message) {
        const queryTemplateEditController = this;

        queryTemplateEditController.$onInit = function() {
            queryTemplateEditController.isAdmin = authentication.isAdmin();
        };

        queryTemplateEditController.create = function() {
            const form = _.omit(queryTemplateEditController.template, 'id');
            save(form);
        };

        queryTemplateEditController.save = function() {
            const form = angular.copy(queryTemplateEditController.template);
            save(form);
        };

        function save(form) {
            QueryTemplate.store(form).$promise.then((template) => {
                queryTemplateEditController.onChange({ template });
                message.onSaved();
            });
        }

        queryTemplateEditController.cancel = function() {
            queryTemplateEditController.onCancel();
        };
    }
});
