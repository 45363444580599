'use strict';

/**
 * @ngdoc factory
 * @name diceApp.factory:SectionService
 * @description Retrieves sections from a page, and generates and keeps track of their unique id and label
 */
angular.module('diceApp')
  .factory('SectionService', function($document, $timeout) {
    let ids = [];

    return {
      getUniqueValidHtmlId,
      getSections
    };

    function getUniqueValidHtmlId(object, path) {
      const label = _.get(object, path);

      if (!_.isEmpty(label)) {
        const kebabCasedLabel = _.kebabCase(label);
        if (!_.includes(ids, (kebabCasedLabel))) {
          ids.push(kebabCasedLabel);
          return kebabCasedLabel;
        }

        const uniqueKebabCasedLabel = _.uniqueId(kebabCasedLabel + '-');
        if (!_.includes(ids, uniqueKebabCasedLabel)) {
          ids.push(uniqueKebabCasedLabel);
          return uniqueKebabCasedLabel;
        }
      }

      return _.uniqueId('gid-');
    }

    function getSections() {
      return $timeout(() => {
        const sections = $document[0].querySelectorAll('[data-section-id][aria-label]');

        const sectionObjects = _.map(sections, (section) => {
          return {
            sectionId: section.getAttribute('data-section-id'),
            sectionLabel: section.getAttribute('aria-label')
          };
        });

        return _.filter(sectionObjects, (section) => {
          return !_.isEmpty(section.sectionId) && !_.isEmpty(section.sectionLabel);
        });
      });
    }
  });
