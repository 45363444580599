'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceIconPicker
 * @description
 * diceIconPicker select an icon
 */
angular.module('diceApp')
  .component('diceIconPicker', {
    templateUrl: 'es6/form-elements/icon.picker.html',
    controllerAs: 'iconPickerController',
    require: {
      ngModel: 'ngModel'
    },
    controller: function ($scope) {
      const iconPickerController = this;

      iconPickerController.$onInit = function() {
        iconPickerController.value = '';
        $scope.$watch('elementSelectController.ngModel.$modelValue', function() {
          iconPickerController.value = (_.isUndefined(iconPickerController.ngModel.$modelValue) ? '' : iconPickerController.ngModel.$modelValue);
        });
      };

      iconPickerController.onChange = function() {
        iconPickerController.ngModel.$setViewValue(iconPickerController.value);
      };

    }
  });
