'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:LocalStorage
 * @description
 * # LocalStorage
 */
angular.module('diceApp').factory('LocalStorage', function(Storage) {

    return Storage.of(localStorage);

});