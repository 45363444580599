'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceParticipationProgress
 * @description
 * diceParticipationProgress Shows the participant progress.
 */
angular.module('diceApp')
  .component('diceParticipationProgress', {
    templateUrl: 'es6/participate/participation.progress.html',
    controllerAs: 'participationProgressController',
    controller: function (Participate, Pageable, diceDateFilter, ThemedChart) {
      const participationProgressController = this;

      participationProgressController.$onInit = function() {
        setFilter();
        participationProgressController.pageable = Pageable.of({
          order: 'date'
        });
        participationProgressController.calculateProgress();
      };

      function setFilter() {
        const date = new Date();
        participationProgressController.endDate = date.getTime();
        date.setMonth(date.getMonth() - 1);
        participationProgressController.startDate = date.getTime();
      }

      participationProgressController.calculateProgress = function() {
        participationProgressController.loading = true;
        Participate.progress({
          startDate: participationProgressController.startDate,
          endDate: participationProgressController.endDate
        }).$promise.then((data) => {
          const rows = _(data).map((row) => {
            return {
              label: diceDateFilter(row.startedDate),
              date: row.startedDate,
              value: row.amount
            };
          }).sortBy('date').reverse().reverse().value();
          participationProgressController.labels = _.map(rows, 'label');
          participationProgressController.data = [_.map(rows, 'value')];
          participationProgressController.colors = ThemedChart.getLineColors();
          participationProgressController.rows = rows;
        }).finally(() => {
          participationProgressController.loading = false;
        });
      };
    }
  });
