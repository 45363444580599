'use strict';

import XLSX from 'xlsx';

/**
 * @ngdoc service
 * @name diceApp.factory
 * @description
 * # Sheet
 * Sheet in the diceApp.
 */
angular.module('diceApp').factory('Sheet', function () {

    return { create };

    function create() {
        let rows = [];

        function push(row) {
            rows.push(row);
        }

        function get() {
            return rows;
        }

        function download(name) {
            const data = rows;
            write(name, data);
        }

        return { push, get, download };
    }

    function write(name, data) {
        const sheet = XLSX.utils.json_to_sheet(data, { cellDates: true });
        const book = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(book, sheet);
        XLSX.writeFile(book, name);
    }

});
