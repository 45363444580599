'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:tooltipLabel
 * @description
 * The tooltipLabel directive.
 */
angular.module('diceApp').component('tooltipLabel', {
  bindings: {
    message: '@'
  },
  templateUrl: 'es6/base/forms/tooltip.label.html',
  controllerAs: 'tooltipController'
});
