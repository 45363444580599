'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:DescriptionInput
 * @description
 * The DescriptionInput directive.
 */

angular.module('diceApp')
  .factory('systemPropertyStore', function (SystemInfo) {

    let instance  = {};
    let properties = {};

    instance.init = function() {
      SystemInfo.properties().$promise.then((result) => {
        properties = result;
      });
    };

    instance.findValue = function(name, defaultValue) {
      var result = properties[name];
      if (result) {
        return result;
      } else {
        return defaultValue || '';
      }
    };

    return instance;
  });
