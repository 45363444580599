'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceBlockList
 */
angular.module('diceApp')
  .component('diceBlockList', {
    bindings: {
      blocks: '<'
    },
    templateUrl: 'es6/evaluations/create/block.list.html',
    controllerAs: 'blockListController'
  });
