'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceMemberList
 * @description
 * diceMemberList Shows the members.
 */
angular.module('diceApp')
  .component('diceMemberList', {
    bindings: {
      evaluation: '<',
      editable: '@',
      operations: '<'
    },
    templateUrl: 'es6/members/member.list.html',
    controllerAs: 'membersController',
    controller: function ($q, Block, MemberType, Member, $uibModal, $filter) {
      const membersController = this;

      membersController.$onInit = function() {
        loadData();
        buildIncorrectUrl();
      };

      function buildIncorrectUrl() {
        var body = $filter('translate')('Page.Evaluation.Members.Incorrect.Mail');
        body = body.replace(/&amp;/g, '&');
        body = body.replace(/%code%/g, membersController.evaluation.code);
        body = body.replace(/%period%/g, _.result(membersController.evaluation.period, 'name'));
        membersController.incorrectUrl = body;
      }

      function loadData() {
        const promises = [];

        promises.push(MemberType.query({
          evaluationType: membersController.evaluation.type.id
        }).$promise);

        if (angular.isDefined(membersController.evaluation.id)) {
          promises.push(Member.query({
            evaluationId: membersController.evaluation.id
          }).$promise);
        }

        membersController.loading = true;
        $q.all(promises).then((results) => {
          const members = results[1] || [];
          membersController.members = _(results[0]).filter(function(type) {
            return type.participant !== true && type.ignore !== true;
          }).sortBy('sequence').map(function(type) {
            return {
              type: type,
              editable: type.editable && membersController.operations.EDIT_MEMBERS,
              data: _.filter(members, function(member) {
                return member.type.id === type.id;
              })
            };
          }).value();
        }).finally(() => {
          membersController.loading = false;
        });
      }

      function reloadEvaluation () {
        Block.query({
          evaluationId: membersController.evaluation.id
        }).$promise.then((blocks) => {
          membersController.evaluation.blocks = blocks;
        });
      }

      membersController.open = function (member) {
        if (member.editable && membersController.operations.EDIT_MEMBERS === true) {
          open(member);
        }
      };

      function open(member) {
        $uibModal.open({
          controllerAs: 'modalCtrl',
          controller: function($uibModalInstance) {
            const modalCtrl = this;
            modalCtrl.modal = $uibModalInstance;
            modalCtrl.container = member;
            modalCtrl.evaluation = membersController.evaluation;

            modalCtrl.onSave = function() {
              reloadEvaluation();
            };
          },
          template: `
            <member-edit-modal
              container="modalCtrl.container"
              evaluation-id="{{ modalCtrl.evaluation.id }}"
              on-save="modalCtrl.onSave"
              modal="modalCtrl.modal">
            </member-edit-modal>`
        });
      }
    }
  });
