'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceQuestionTemplateList
 * @description Manages a list of question templates.
 */
angular.module('diceApp').component('diceQuestionTemplateList', {
  bindings: {
    questions: '<'
  },
  templateUrl: 'es6/templates/question/question.template.list.html',
  controllerAs: 'listController',
  controller: function(
    $scope,
    $filter,
    EvaluationType,
    QuestionTemplate,
    $timeout,
    language,
    Pageable
  ) {
    const listController = this;

    listController.$onInit = function() {
      listController.syncing = true;
      listController.searchString = '';

      listController.pageable = Pageable.of({
        order: 'name'
      });

      EvaluationType.query().$promise.then(function(types) {
        listController.evaluationTypes = types;
        listController.evaluationType = null;
        listController.search();
      });
    };

    listController.search = () => {
      listController.syncing = true;

      const params = listController.pageable.build({
        text: listController.searchString,
        evaluationType: listController.evaluationType,
        language: language.get()
      });

      QuestionTemplate.search(params).$promise.then((result) => {
        listController.filteredQuestions = result;
      }).finally(() => {
        listController.syncing = false;
      });
    };

    listController.getDetails = function(template) {
      return QuestionTemplate.get({ id: template.id });
    };

    listController.filterTypes = function(questions) {
      if (angular.isUndefined(questions)) {
        questions = listController.questions;
      }

      listController.filteredQuestions = _.filter(questions, function(question) {
        return question.evaluationType === listController.evaluationType;
      });
    };
  }
});
