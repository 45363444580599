'use strict';

angular.module('diceApp').factory('Bulk', function ($state, $uibModal) {

  function showChangeModal(evaluations, type) {
    if (evaluations.length > 0) {
      $uibModal.open({
        size: 'lg',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.type = type;
          modalController.evaluations = evaluations;

          modalController.showResult = function (result) {
            modalController.close();
            $state.reload();
            showResult(result, evaluations, type);
          };

          modalController.close = function () {
            $uibModalInstance.dismiss();
          };
        },
        template: `
          <bulk-change-modal
            type="modalController.type"
            evaluations="modalController.evaluations"
            on-cancel="modalController.close()"
            on-change="modalController.showResult(result)">
          </bulk-change-modal>
        `
      });
    }
  }

  function showResult(result, evaluations, type) {
    $uibModal.open({
      size: 'lg',
      controllerAs: 'modalController',
      controller: function ($uibModalInstance) {
        const modalController = this;
        modalController.result = result;

        _.forEach(result.entities, (entity) =>
          entity.evaluation = _.find(evaluations, { id: entity.entity.id })
        );

        modalController.close = function () {
          $uibModalInstance.dismiss();
        };

        modalController.retry = function () {
          modalController.close();

          const failed = _(result.entities)
            .filter((entity) => entity.status === 'FAILED')
            .map('evaluation')
            .value();
          showChangeModal(failed, type);
        };
      },
      template: `
        <bulk-result-modal
          result="modalController.result"
          on-close="modalController.close()"
          on-retry="modalController.retry()">
        </bulk-result-modal>
      `
    });
  }

  return {
    showChangeModal, showResult
  };
});
