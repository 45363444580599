'use strict';

/**
 * @ngdoc service
 * @name diceApp.forgotPasswordDatasource
 * @description
 * # forgotPasswordDatasource
 * Datasource for endpoints related to password change requests
 * (forgot password, supply new password / activate).
 */
angular.module('diceApp')
  .factory('forgotPasswordDatasource', function ($http) {
    const baseUrl = 'api/password-change-request';

    return {
      forgotPassword,
      changePassword
    };

    /**
     * Trigger a password changed request based on solely the username, if it exists.
     * @param username username
     * @return promise
     */
    function forgotPassword(username) {
      return $http.post(`${baseUrl}/forgot-password`, { username }, {});
    }

    function changePassword(id, code, password) {
      return $http.post(`${baseUrl}/${id}/change-password`, {
        code,
        password
      }, {});
    }
  });