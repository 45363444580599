'use strict';

angular.module('diceApp')
  .component('elementSelect', {
    bindings: {
      inputId: '@?',
      type: '<?',
      ngDisabled: '<?'
    },
    templateUrl: 'es6/elements/element.select.html',
    require: {
      ngModelCtrl: 'ngModel'
    },
    controllerAs: 'elementSelectController',
    controller: function($scope, Element, descriptionFilter, translateFilter) {
      const elementSelectController = this;

      elementSelectController.$onInit = function() {
        elementSelectController.ngModelCtrl.$render = () => {
          elementSelectController.externalId = elementSelectController.ngModelCtrl.$viewValue;
        };

        loadData();
      };

      function loadData() {
        Element.query({
          type: elementSelectController.type
        }).$promise.then((elements) => {

          elementSelectController.elements = _(elements).filter({ visible: true }).map((element) => {
            const label = getLabel(element);
            return _.extend(element, { label });
          }).sortBy(['type', 'label']).value();
        });
      }

      function getLabel(element) {
        const name = descriptionFilter(element.names) || element.externalId;
        if (elementSelectController.type) {
          return name;
        }

        const type = translateFilter(`Enum.${element.type}`);
        return `${name} (${type})`;
      }

      elementSelectController.onChange = function() {
        elementSelectController.ngModelCtrl.$setViewValue(elementSelectController.externalId);
      };
    }
  });