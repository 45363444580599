'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:FixToTop Component
 * @description
 * Fixes a given element to the top of the page.
 */
angular.module('diceApp')
  .directive('fixToTop', function ($window) {
    return {
      restrict: 'A',
      link: function (scope, elm, attrs) {
        var $win = angular.element($window),
          elmHeight = elm[0].offsetHeight,
          siteWrapper = angular.element('body'),
          topClass = attrs.fixToTop || 'fixed-header-active', // get CSS class from directive's attribute value
          offsetTop = elm.offset().top;
        
         $win.bind('scroll', function () {
          if ($win.scrollTop() >= offsetTop) {
            siteWrapper.css({ paddingTop: elmHeight });
            elm.addClass(topClass);
          } else {
            siteWrapper.css({ paddingTop: '' });
            elm.removeClass(topClass);
          }
        });

         scope.$on('$destroy', function() {
          $win.unbind('scroll');
         });
      }
    };
  });
