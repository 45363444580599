'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceEvaluationExtra
 * @description
 * diceEvaluationExtra Shows the extra questions tab for evaluations.
 */
angular.module('diceApp')
  .component('diceEvaluationExtra', {
    bindings: {
      evaluation: '<',
      operations: '<',
      onChange: '&'
    },
    templateUrl: 'es6/evaluations/evaluation.extra.html',
    controllerAs: 'evaluationExtraController',
    controller: function (Evaluation, message, EvaluationTemplate) {
      const evaluationExtraController = this;

      evaluationExtraController.$onInit = function() {
        loadData();
      };

      function loadData() {
        Evaluation.getFull(evaluationExtraController.evaluation.id).then((evaluation) => {
          evaluationExtraController.evaluation = evaluation;

          evaluationExtraController.blocks = _(evaluation.blocks)
            .filter((block) => angular.isUndefined(block.template))
            .orderBy('sequence')
            .value();

          evaluationExtraController.extra = fetchExtra();
          evaluationExtraController.extraId = _.result(evaluationExtraController.extra, 'id');

          evaluationExtraController.opened = angular.isDefined(evaluationExtraController.extra.id);
        });

        if (angular.isDefined(evaluationExtraController.evaluation.template)) {
          evaluationExtraController.template = EvaluationTemplate.get({
            id: evaluationExtraController.evaluation.template
          });
        }
      }

      evaluationExtraController.onExtra = function() {
        evaluationExtraController.extra = fetchExtra();
      };

      function fetchExtra() {
        let block = _.find(evaluationExtraController.blocks, (it) => {
          return it.id === evaluationExtraController.extraId;
        });

        if (angular.isUndefined(block)) {
          block = _.head(evaluationExtraController.blocks);
        }

        if (angular.isDefined(block)) {
          block.questions = _.sortBy(block.questions, 'sequence');
        } else {
          block = {
            active: true,
            questions: [],
            filters: [],
            titles: []
          };
        }

        return block;
      }

      evaluationExtraController.start = function () {
        evaluationExtraController.opened = true;
        if (angular.isDefined(evaluationExtraController.template)) {
          evaluationExtraController.extra.titles = evaluationExtraController.template.defaultExtraQuestionsBlockNames;
        }
        evaluationExtraController.evaluation.blocks.push(evaluationExtraController.extra);
      };

      evaluationExtraController.save = function(block, form) {
        var body = angular.copy(block);
        _.each(body.questions, updateSequence);
        body.filters = _.filter(block.filters, isUsableFilter);

        return Evaluation.saveExtraQuestions({
          evaluationId: evaluationExtraController.evaluation.id
        }, body).$promise.then((evaluation) => onChange(evaluation, form));
      };

      evaluationExtraController.delete = function(block, form) {
        return Evaluation.deleteExtraQuestions({
          evaluationId: evaluationExtraController.evaluation.id,
          blockId: block.id
        }, {}).$promise.then((evaluation) => onChange(evaluation, form));
      };

      function onChange(evaluation, form) {
        evaluationExtraController.evaluation = evaluation;
        evaluationExtraController.onChange({ evaluation: evaluation });

        loadData();
        form.$setPristine(false);
        message.addSuccessLabel('Message.Save.Success');
      }

      function updateSequence(question, $index) {
        question.sequence = $index;
      }

      function isUsableFilter(filter) {
        return !_.isEmpty(filter.question) && 
               !_.isEmpty(filter.operation) && 
               !_.isEmpty(filter.expected);
      }
    }
  });
