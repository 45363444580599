'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Customer
 * @description
 * # Customer
 * Customer resource of the diceApp
 */
angular.module('diceApp')
	.factory('Customer', function(DiceResource) {
		const resource = DiceResource('/customer');

		let promise;

		function load() {
			if (angular.isUndefined(promise)) {
				promise = resource.get().$promise;
			}
			
			return promise;
		}

		return { load };
	});
