'use strict';

angular.module('diceApp')
  .factory('breadcrumb', function ($state) {
    var crumbs = [];

    return { getCrumbs, addCrumb, backTo, remove, removeAll };

    function getCrumbs() {
      return crumbs;
    }

    function addCrumb(state, params) {
      var base = _.split(state.name, '.')[0];

      var crumb = {
        label: _.result(state, 'data.label', 'Page.' + _.replace(_.startCase(base), new RegExp(' ', 'g'), '') + '.Page.Breadcrumb'),
        state: state.name,
        base: base,
        params: params
      };

      var visible = _.result(state, 'data.visible.breadcrumb', true);
      var index = _.findIndex(crumbs, { base, params });
      if (index >= 0) {
        backTo(index);
      } else if (visible === true) {
        crumbs.push(crumb);
      }
    }

    function backTo(index) {
      if (crumbs.length !== index + 1) {
        var current = crumbs[index];
        crumbs = _.slice(crumbs, 0, index);
        $state.go(current.state, current.params);
      }
    }

    function remove(filter) {
      _.remove(crumbs, filter);
    }

    function removeAll() {
      crumbs = [];
    }
  });
