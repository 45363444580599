'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceEditBlock
 * @description
 * diceEditBlock Makes a block editable.
 */
angular.module('diceApp')
  .component('diceEditBlock', {
    bindings: {
      block: '<',
      evaluationType: '<',
      tags: '<?',
      limit: '<',
      form: '<',
      editable: '<',
      advanced: '<?',
      onChange: '&'
    },
    transclude: true,
    templateUrl: 'es6/evaluations/blocks/block.edit.html',
    controllerAs: 'blockController',
    controller: function (language, $uibModal) {
      const blockController = this;

      blockController.$onInit = function () {
        blockController.language = language;
      };

      blockController.prepareForPreview = function (block) {
        _.each(block.questions, updateSequence);
        return angular.copy(block);
      };

      function updateSequence(container, $index) {
        container.sequence = $index;
      }

      blockController.add = function () {
        let question = {
          options: []
        };

        const questions = blockController.block.questions || [];
        questions.push(question);

        blockController.block.questions = questions;
        blockController.open(question, true);
      };

      blockController.open = function (question, isNewQuestion) {
        if (angular.isDefined(question.template)) {
          return; // Should not edit template questions
        }

        setDirty();

        const originalQuestionValue = angular.copy(question);
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;
            modalController.question = question;
            modalController.evaluationType = blockController.evaluationType;
            modalController.advanced = blockController.advanced !== false;
          },
          windowClass: 'lg-modal-window',
          template: `
            <edit-question-modal
              question="modalController.question"
              modal="modalController.modal"
              evaluation-type="modalController.evaluationType"
              advanced="modalController.advanced">
            </edit-question-modal>
          `
        }).result.then(() => {
          blockController.onChange({ block: blockController.block });
        }, () => {
          const questions = blockController.block.questions;
          if (isNewQuestion) {
            questions.splice(questions.indexOf(question), 1);
          } else {
            questions[questions.indexOf(question)] = originalQuestionValue;
          }
        });
      };

      blockController.addQuestion = function () {
        $uibModal.open({
          size: 'lg',
          templateUrl: 'es6/evaluations/blocks/question.add.modal.html',
          controllerAs: 'modalController',
          controller: function ($q, $uibModalInstance, Question) {
            const modalController = this;

            modalController.evaluationType = blockController.evaluationType;
            modalController.limit = blockController.limit;
            modalController.selected = _(blockController.block.questions)
              .filter((question) => angular.isDefined(question.template))
              .map('template')
              .value();
            modalController.initSelected = _.size(blockController.block.questions) - modalController.selected.length;

            modalController.save = function () {
              blockController.block.questions = _.filter(blockController.block.questions, (question) => !question.template);

              const promises = _.map(modalController.selected, (id) =>
                Question.get({
                  templateId: id
                }).$promise.then((question) => {
                  blockController.block.questions.push(question);
                })
              );
              $q.all(promises).then(() => {
                blockController.onChange({ block: blockController.block });
                modalController.close();
              });
            };

            modalController.add = function (templateId) {
              modalController.selected.push(templateId);
            };

            modalController.remove = function (templateId) {
              modalController.selected = modalController.selected.filter((id) => id !== templateId);
            };

            modalController.close = function () {
              $uibModalInstance.dismiss();
            };
          }
        });
      };

      blockController.removeQuestion = function (index) {
        setDirty();

        blockController.block.questions.splice(index, 1);
        blockController.onChange({ block: blockController.block });
      };

      function setDirty() {
        if (angular.isDefined(blockController.form)) {
          blockController.form.$setDirty(true);
        }
      }

      blockController.moveQuestion = function (index) {
        _.remove(blockController.block.questions, blockController.selected);
        blockController.block.questions.splice(index, 0, blockController.selected);
      };
    }
  });
