'use strict';

/**
 * @ngdoc factory
 * @name diceApp.factory:ThemedChart
 * @description
 * Uses CSS variables (with fallbacks) from injected customer-style to create a color palette for bar and line charts.
 */
angular.module('diceApp')
  .factory('ThemedChart', function() {
    const customChartColors = {};

    const computedStyle = getComputedStyle(document.documentElement);

    const chartColorPrimary = _getColorByCSSVariableName('--chartColorPrimary');
    const chartColorPrimaryTransparent = _getColorByCSSVariableName('--chartColorPrimaryTransparent');

    customChartColors.getBarColors = function() {
      return [{
        backgroundColor: chartColorPrimary,
        borderColor: chartColorPrimary
      }];
    };

    customChartColors.getLineColors = function() {
      return [{
        backgroundColor: chartColorPrimaryTransparent,
        borderColor: chartColorPrimary,
        pointBackgroundColor: chartColorPrimary,
        pointBorderColor: '#ffffff'
      }];
    };

    function _getColorByCSSVariableName(variableName) {
      const propertyValue = computedStyle.getPropertyValue(variableName);
      if (propertyValue && propertyValue !== '') {
        return propertyValue;
      }

      return null;
    }

    return customChartColors;
  });
