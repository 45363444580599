'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:thresholdEdit
 * @description
 * thresholdEdit Shows the threshold.
 */
angular.module('diceApp').component('thresholdEdit', {
    bindings: {
        threshold: '<'
    },
    templateUrl: 'es6/report/threshold/threshold.edit.html',
    controllerAs: 'thresholdEditController',
    controller: function(Threshold, message, $state) {
        const thresholdEditController = this;

        thresholdEditController.save = function(form) {
            const body = thresholdEditController.threshold;

            return Threshold.store(body).$promise.then((result) => {
                message.addSuccessLabel('Message.Save.Success');
                form.$setPristine(false);

                if (angular.isUndefined(body.id)) {
                    $state.go('threshold', { id: result.id });
                } else {
                    thresholdEditController.threshold = result;
                }
            });
        };

        thresholdEditController.remove = function() {
            return Threshold.delete({
                id: thresholdEditController.threshold.id
            }).$promise.then(() => {
                message.addSuccessLabel('Message.Delete.Success');
                $state.go('threshold-list');
            });
        };

        thresholdEditController.onColor = function(color) {
            thresholdEditController.color = color;
        };

        thresholdEditController.addColor = function() {
            const colors = thresholdEditController.threshold.colors;

            const color = {
                color: '#af4646',
                sequence: (_(colors).map('sequence').max() || 0) + 1,
                conditions: []
            };

            colors.push(color);
            thresholdEditController.onColor(color);
        };

        thresholdEditController.removeColor = function() {
            _.remove(thresholdEditController.threshold.colors, thresholdEditController.color);
            delete thresholdEditController.color;
        };

        thresholdEditController.addCondition = function() {
            thresholdEditController.color.conditions.push({});
        };

        thresholdEditController.removeCondition = function(index) {
            thresholdEditController.color.conditions.splice(index, 1);
        };
    }
});