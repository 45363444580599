'use strict';

/**
 * @ngdoc function
 * @name <%= scriptAppName %>.factory:exceptionReporter
 * @description
 * # exceptionReporter
 *
 * The exceptionReporter factory is used to report exceptions that occurred
 * in Angular to the back-end. This reporter enriches the error that
 * is reported with the current url of the browser, and the $stateParams
 * of ui-router.
 */
angular.module('diceApp')
  .constant('BACK_END_LOG_URL', 'log/error')
  .factory('exceptionReporter', function($injector, BACK_END_LOG_URL) {
    /*
      Cannot inject $http and $location directly because of
      circular dependency injection errors.
    */
    let $http = null;
    let $location = null;

    // Tracks what the last error was that was sent to the back-end.
    let latest = null;

    /*
      We want to make sure that this request does not trigger the loading bar
      see: https://github.com/chieffancypants/angular-loading-bar.
    */
    const config = { ignoreLoadingBar: true };

    return { report };

    /**
     * Reports an error to the back-end by sending a HTTP Post request
     * with the relevant error info that was available
     * @param  {Object} error The error object which needs to be sent to the back-end.
     * @return {Void}
     */
    function report(error) {
      // If $http is null get it once via the injector manually.
      if (_.isNull($http)) {
        $http = $injector.get('$http');
      }

      // If $location is null get it once via the injector manually.
      if (_.isNull($location)) {
        $location = $injector.get('$location');
      }

      const body = {
        url: $location.absUrl(),
        message: error.message,
        userAgent: navigator.userAgent,
        stack: _.truncate(error.stack, {
          length: 2000,
          separator: ' '
        })
      };

      /*
        If the reported error is the same as the last sent error we do
        not send it again twice in a row. This also fixes a bug in
        Internet Explorer that caused it to go into an infinite loop.
      */
      if (_.isEqual(latest, body) === false) {
        $http.post('api/' + BACK_END_LOG_URL, body, config);
      }

      latest = body;
    }
  });