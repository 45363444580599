'use strict';

/**
 * @ngdoc service
 * @name diceApp.filter:pagination
 * @description
 * The paginationFilter filter.
 */
angular.module('diceApp').filter('pagination', function () {
  return function (input, currentPage, pageSize) {
    if (angular.isArray(input)) {
      var start = (currentPage - 1) * pageSize;
      var end = currentPage * pageSize;
      return input.slice(start, end);
    }
  };
});
