'use strict';

/**
 * @ngdoc directive
 * @name diceApp.component:questionAnswer
 * @description Show a question answer
 */
angular.module('diceApp').component('questionAnswer', {
  templateUrl: 'es6/preview/question.answer.html',
  bindings: {
    question: '<',
    answer: '<'
  },
  controllerAs: 'questionAnswerController',
  controller: function (descriptionFilter) {
    const questionAnswerController = this;

    questionAnswerController.$onInit = function () {
      questionAnswerController.text = getAnswers(questionAnswerController.answer);
    };

    function getAnswers(value) {
      if (_.isEmpty(value)) {
        return null;
      }
      if (_.isArray(value)) {
        return _(value).map(getAnswer).sort().join(', ');
      }

      return getAnswer(value);
    }

    function getAnswer(value) {
      const options = questionAnswerController.question.options;
      if (_.isEmpty(options)) {
        return value;
      }

      const labels = _(options).filter({ value }).map((option) => {
          return {
              language: option.language,
              value: option.label
          };
      }).value();

      return descriptionFilter(labels, value);
    }
  }
});
