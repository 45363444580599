'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:BlockTemplateStates
 * @description
 * # BlockTemplateStates
 * Configures all states for block templates.
 */
angular.module('diceApp').config(function ($stateProvider) {
  $stateProvider.state('option-template-list', {
    url: '/option-templates',
    data: {
      root: true
    },
    template: '<dice-option-template-list options="options"></dice-option-template-list>'
  }).state('option-template-copy', {
    url: '/option-template/{id:[0-9]{1,8}}/copy',
    resolve: {
      optionTemplate: function question($stateParams, OptionTemplate) {
        return OptionTemplate.get({
          id: $stateParams.id
        }).$promise;
      }
    },
    controllerAs: 'optionTemplateCopyController',
    controller: function controller($filter, optionTemplate) {
      const optionTemplateCopyController = this;
      var clone = angular.copy(optionTemplate);
      clone.name = $filter('translate')('Generic.CopyOf') + ' ' + clone.name;
      clone.labels = _.map(clone.labels, function (label) {
        delete label.id;
        label.value = $filter('translate')('Generic.CopyOf') + ' ' + label.value;
        return label;
      });
      optionTemplateCopyController.option = _.omit(clone, ['id']);
    },
    template: '<dice-option-template option="optionTemplateCopyController.option"></dice-option-template>'
  }).state('option-template-create', {
    url: '/option-templates/create',
    template: '<dice-option-template option="{}"></dice-option-template>'
  }).state('option-template', {
    url: '/option-templates/{id:[0-9]{1,8}}',
    resolve: {
      option: function ($stateParams, OptionTemplate) {
        return OptionTemplate.get({
          id: $stateParams.id
        }).$promise;
      }
    },
    controllerAs: 'optionTemplateController',
    controller: function (option) {
      const optionTemplateController = this;
      optionTemplateController.option = option;
    },
    template: '<dice-option-template option="optionTemplateController.option"></dice-option-template>'
  });
});
