'use strict';

angular.module('diceApp').component('bulkResultModal', {
  bindings: {
    result: '<',
    onClose: '&',
    onRetry: '&'
  },
  templateUrl: 'es6/evaluations/bulk/evaluation.bulk.result.modal.html',
  controllerAs: 'bulkResultController',
  controller: function () {
    const bulkResultController = this;

    bulkResultController.$onInit = function () {
      bulkResultController.success = _.filter(bulkResultController.result.entities, (entity) => entity.status === 'SUCCESS');
      bulkResultController.processingInBackground = _.filter(bulkResultController.result.entities, (entity) => entity.status === 'PROCESSING_IN_BACKGROUND');
      bulkResultController.failed = _.filter(bulkResultController.result.entities, (entity) => entity.status === 'FAILED');
    };

    bulkResultController.close = function () {
      bulkResultController.onClose();
    };
  }
});
