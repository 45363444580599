'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceFilterEdit
 * @description Manages a filter.
 */
angular.module('diceApp')
  .component('diceFilterEdit', {
    bindings: {
      filters: '<'
    },
    templateUrl: 'es6/templates/filter/filter.edit.html',
    controllerAs: 'filterEditController',
    controller: function () {
      const filterEditController = this;

      filterEditController.add = function() {
        filterEditController.filters.push({});
      };

      filterEditController.remove = function(filter) {
        _.remove(filterEditController.filters, filter);
      };
    }
  });
