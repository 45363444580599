'use strict';

angular.module('diceApp').component('questionTemplateSelect', {
  bindings: {
    evaluationId: '<?',
    evaluationTypeId: '<',
    evaluationTemplateId: '<?',
    tags: '<?',
    questionTypes: '<?',
    hideOpenQuestions: '<?',
    selectedIds: '<?',
    limit: '<?',
    onSelect: '&',
    onDeselect: '&'
  },
  transclude: true,
  templateUrl: 'es6/templates/question/question.template.select.html',
  controllerAs: 'questionTemplateSelectController',
  controller: function (descriptionFilter, language, QuestionTemplate, Pageable) {
    const questionTemplateSelectController = this;

    questionTemplateSelectController.$onInit = function () {
      questionTemplateSelectController.selectedQuestions = [];

      if (!_.isEmpty(questionTemplateSelectController.selectedIds)) {
        QuestionTemplate.bulk({
          id: questionTemplateSelectController.selectedIds
        }).$promise.then((questions) => {
          _.forEach(questions, (question) =>
            question.label = descriptionFilter(question.labels)
          );
          questionTemplateSelectController.selectedQuestions = questions;
        });
      }

      QuestionTemplate.tags().$promise.then((tags) => {
        questionTemplateSelectController.tags = tags;
      });
    };

    questionTemplateSelectController.$onChanges = function (changes) {
      if (!questionTemplateSelectController.pageable) {
        questionTemplateSelectController.pageable = Pageable.of({ order: 'name' });
      }
      if (shouldSearch(changes)) {
        questionTemplateSelectController.search();
      }
    };

    function shouldSearch(changes) {
      return changes.evaluationId || changes.evaluationTypeId || changes.evaluationTemplateId ||
        changes.questionTypes || changes.hideOpenQuestions;
    }

    questionTemplateSelectController.search = function () {
      questionTemplateSelectController.loading = true;
      QuestionTemplate.search(searchParams()).$promise.then((questions) => {
        _.forEach(questions.content, (question) => {
          question.selected = _.includes(questionTemplateSelectController.selectedIds, question.id);
        });
        questionTemplateSelectController.questions = questions;
      }).finally(() => {
        questionTemplateSelectController.loading = false;
      });
    };

    function searchParams() {
      const params = {
        evaluationTemplateId: questionTemplateSelectController.evaluationTemplateId,
        evaluationId: questionTemplateSelectController.evaluationId,
        closed: questionTemplateSelectController.hideOpenQuestions,
        text: questionTemplateSelectController.text,
        tags: questionTemplateSelectController.selectedTags,
        questionTypes: questionTemplateSelectController.questionTypes,
        language: language.get()
      };

      if (!params.evaluationTemplateId && !params.evaluationId) {
        params.evaluationType = questionTemplateSelectController.evaluationTypeId;
      }

      return questionTemplateSelectController.pageable.build(params);
    }

    questionTemplateSelectController.toggleSelect = function (question) {
      question.selected = !question.selected;
      if (question.selected) {
        questionTemplateSelectController.onSelect({ templateId: question.id });
        questionTemplateSelectController.selectedQuestions.push(question);
      } else {
        deselect(question);
      }
    };

    function deselect(question) {
      questionTemplateSelectController.onDeselect({ templateId: question.id });
      questionTemplateSelectController.selectedQuestions = questionTemplateSelectController.selectedQuestions
        .filter((q) => q.id !== question.id);
    }

    questionTemplateSelectController.deselect = function (question) {
      deselect(question);
      questionTemplateSelectController.search();
    };

    questionTemplateSelectController.onPage = function () {
      questionTemplateSelectController.search();
    };

    questionTemplateSelectController.getDetails = function (question) {
      return QuestionTemplate.get({ id: question.id });
    };
  }
});