'use strict';

angular.module('diceApp').component('bulkEntities', {
  bindings: {
    entities: '<',
    badgeClass: '@',
    label: '@'
  },
  templateUrl: 'es6/evaluations/bulk/bulk.entities.html',
  controllerAs: 'bulkEntitiesController'
});
