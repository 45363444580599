'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceEvaluationTemplateBlocks
 * @description
 * diceEvaluationTemplateBlocks Allows users to pick which blocks to use.
 */
angular.module('diceApp')
  .component('diceEvaluationTemplateBlocks', {
    bindings: {
      evaluation: '<',
      operations: '<',
      onChange: '&'
    },
    templateUrl: 'es6/evaluations/evaluation.template.blocks.html',
    controllerAs: 'blocksController',
    controller: function ($uibModal, $state, $q, EvaluationTemplate, Evaluation, Block) {
      const blocksController = this;

      blocksController.$onInit = function() {
        onTemplateChange();
      };

      function onTemplateChange() {
        delete blocksController.template;
        blocksController.blocks = [];

        if (angular.isDefined(blocksController.evaluation.template)) {
          blocksController.loading = true;

          $q.all([
            EvaluationTemplate.get({
              id: blocksController.evaluation.template
            }).$promise,
            Block.query({
              evaluationId: blocksController.evaluation.id
            }).$promise
          ]).then(([template, blocks]) => {
            blocksController.template = template;

            blocksController.blocks = _(blocks)
              .filter((block) => angular.isDefined(block.template))
              .filter((block) => block.visible !== false)
              .map((block) => {
                var result = angular.copy(block);
                result.original = block;
                result.item = _.find(template.blocks, (item) => item.block.id === block.template);
                result.editable = isEditable(block);
                return result;
              })
              .sortBy('sequence')
              .value();
          }).finally(() => {
            blocksController.loading = false;
          });
        }
      }

      function isEditable(block) {
        var roles = [];
        if (block.required === true) {
          roles.push('SELECT_BLOCKS_REQUIRED');
        }
        if (angular.isDefined(block.member)) {
          roles.push('SELECT_BLOCKS_MEMBER');
        }
        if (_.isEmpty(roles)) {
          roles.push('SELECT_BLOCKS');
        }
        var editable = _.every(roles, (role) => blocksController.operations[role] === true);
        return editable;
      }

      blocksController.prepareForPreview = function(block) {
        if (_.isEmpty(block.questions)) {
          return angular.copy(block.item.block);
        } else {
          return angular.copy(block);
        }
      };

      blocksController.toggle = function (block) {
        if (block.editable) {
          Evaluation.changeBlockActiveStatus({
            evaluationId: blocksController.evaluation.id
          }, {
            blockId: block.id,
            active: block.active
          }).$promise.then((evaluation) => {
            block.original.active = block.active;
            blocksController.onChange({ evaluation });
          });
        }
      };

      blocksController.switchTemplate = function () {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;
            modalController.evaluation = angular.copy(blocksController.evaluation);

            modalController.refresh = function () {
              modalController.modal.close();
              $state.reload();
            };
          },
          template: `
            <evaluation-template-picker
              evaluation="modalController.evaluation"
              on-save="modalController.refresh(evaluation)"
              modal="modalController.modal">
            </evaluation-template-picker>
          `
        });
      };

      blocksController.showQuestions = function (block) {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.block = angular.copy(block);

            modalController.close = function () {
              $uibModalInstance.dismiss();
            };
          },
          template: `
            <evaluation-template-questions-modal
              block="modalController.block"
              on-cancel="modalController.close()">
            </evaluation-template-questions-modal>
          `
        });
      };
    }
  });
