'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Scheduler
 * @description
 * # Scheduler
 * Scheduler resource of the diceApp
 */
angular.module('diceApp')
  .factory('Workflow', function (DiceResource) {
    return DiceResource('/workflow', {
      execute: {
        method: 'GET',
        url: '/workflow/execute/:date'
      },
      reset: {
        method: 'DELETE',
        url: '/workflow/reset'
      },
      dateTypes: {
        method: 'GET',
        url: '/workflow/date-types',
        isArray: true
      },
      logs: {
        method: 'GET',
        url: '/workflow/logs/:evaluationId',
        isArray: true
      }
    });
  });
