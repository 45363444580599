'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:EditButton
 * @description
 * The EditButton service.
 */
angular.module('diceApp')
  .directive('editButton', function () {
    return {
      restrict: 'E',
      scope: {
        btnType: '@?',
        btnSize: '@?',
        btnIconOnly: '=?',
        btnClick: '&?',
        btnContext: '@?'
      },
      templateUrl: 'es6/base/forms/buttons/edit.button.html',
      link: function (scope) {
        scope.btnContext = scope.btnContext || 'default';
        scope.executeBtnClick = function (event) {
          if (scope.btnClick) {
            scope.btnClick({ event: event });
          }
        };
      },
      controller: function ($scope) {
        $scope.size = $scope.size || 'md';
      }
    };
  });
