'use strict';

/**
 * @ngdoc service
 * @name diceApp.directive:deleteButton
 * @description Shows a confirmation modal before deleting an item.
 */
angular.module('diceApp')
  .directive('deleteConfirm', function ($uibModal) {
    return {
      restrict: 'A',
      scope: {
        deleteConfirm: '&?'
      },
      link: function (scope, element) {
        element.bind('click', function(ev) {
          if (scope.deleteConfirm) {
            scope.openConfirm(ev);
          }
        });
      },
      controller: function ($scope) {
        $scope.openConfirm = function($event) {
          $event.preventDefault();
          $event.stopPropagation();

          $uibModal.open({
            templateUrl: 'es6/base/forms/buttons/delete.confirm.modal.html',
            resolve: {
              onDelete: function () {
                return $scope.deleteConfirm;
              }
            },
            controllerAs: 'modalController',
            controller: function ($uibModalInstance, onDelete) {
              const modalController = this;

              modalController.perform = function () {
                $uibModalInstance.close();
                onDelete();
              };
            }
          });
        };
      }
    };
  });
