'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Benchmark
 * @description
 * # Benchmark
 * Benchmark resource of the diceApp
 */
angular.module('diceApp')
  .factory('Benchmark', function (DiceResource) {
    return DiceResource('/benchmarks', {
        generate: {
            method: 'POST'
        }
    });
  });
