'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceBenchmarkLabel
 * @description
 * diceBenchmarkLabel Shows the benchmark.
 */
angular.module('diceApp')
  .component('diceBenchmarkLabel', {
    bindings: {
      question: '<'
    },
    templateUrl: 'es6/report/benchmark/benchmark.label.html',
    controllerAs: 'benchmarkLabelController',
    controller: function() {
        const benchmarkLabelController = this;
        benchmarkLabelController.$onChanges = function() {
            const average = _.result(benchmarkLabelController.question, 'averageValue', 0);
            const benchmark = Math.round(_.result(benchmarkLabelController.question, 'benchmark.average', 0) * 10) / 10;
            benchmarkLabelController.diff = _.round(average - benchmark, 2).toFixed(2);
            benchmarkLabelController.positive = benchmarkLabelController.diff >= 0;
        };
    }
  });
