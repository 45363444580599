'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:evaluationWidget
 * @description
 * evaluationWidget Evaluations widget.
 */
angular
  .module('diceApp')
  .component('evaluationWidget', {
    bindings: {
      widget: '<',
      evaluations: '<'
    },
    templateUrl: 'es6/dashboard/evaluation.widget.html',
    controllerAs: 'evaluationsController',
    controller: function () {
      const evaluationsController = this;

      evaluationsController.order = ['evaluation.code', 'evaluation.startDate'];
      if (evaluationsController.widget.alert === true) {
        evaluationsController.order = _.reverse(evaluationsController.order);
      }
    }
  });
