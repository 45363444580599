'use strict';

angular.module('diceApp').factory('Promises', function($q) {

    function chain(promises) {
        let progress = {
            loading: true,
            current: 0,
            total: promises.length
        };

        progress.$promise = _.reduce(promises, (current, request) => {
            return current.then(() => {
                return request().finally(() => {
                    progress.current += 1;
                });
            });
        }, $q.resolve()).finally(() => {
            progress.loading = false;
        });

        return progress;
    }

    return {
        chain
    };

});
