'use strict';


angular.module('diceApp')
  .component('formError', {
    bindings: {
      form: '@'
    },
    templateUrl: 'es6/base/forms/validation/form.error.html',
    controllerAs: 'formErrorController',
    controller: function (errorFieldsStore) {
      const formErrorController = this;
      errorFieldsStore.onError(function (error) {
        formErrorController.errors = _.filter(error.globalErrors, { object: formErrorController.form });
      });
    }
  });
