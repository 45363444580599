'use strict';

angular.module('diceApp').component('dailyLoggedInReport', {
  templateUrl: 'es6/statistics/daily.logged.in.report.html',
  controllerAs: 'dailyLoggedInReportController',
  controller: function ($q, message, Pageable, UserLogin, diceDateFilter, ThemedChart) {
    const dailyLoggedInReportController = this;

    dailyLoggedInReportController.$onInit = function () {
      initializeDateFilter();
      initializePageable();
      dailyLoggedInReportController.loadData();
    };

    function initializeDateFilter() {
      const currentDate = new Date();
      dailyLoggedInReportController.endDate = currentDate.getTime();
      currentDate.setMonth(currentDate.getMonth() - 1);
      dailyLoggedInReportController.startDate = currentDate.getTime();
    }

    function initializePageable() {
      dailyLoggedInReportController.pageable = Pageable.of({
        order: 'loginDate',
        reverse: true
      });
    }

    dailyLoggedInReportController.loadData = function () {
      dailyLoggedInReportController.loading = true;
      const { startDate, endDate } = dailyLoggedInReportController;

      UserLogin.daily({ startDate, endDate }).$promise.then((data) => {
        const metrics = _.map(data, (metric) => {
          return {
            label: diceDateFilter(metric.loginDate),
            loginDate: metric.loginDate,
            userCount: metric.userCount
          };
        });
        dailyLoggedInReportController.chartLabels = _.map(metrics, 'label');
        dailyLoggedInReportController.chartData = [_.map(metrics, 'userCount')];
        dailyLoggedInReportController.metrics = metrics;
        dailyLoggedInReportController.chartColors = ThemedChart.getBarColors();
      }).finally(() => {
        dailyLoggedInReportController.loading = false;
      });
    };
  }
});
