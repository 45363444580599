'use strict';

/**
 * @ngdoc service
 * @name diceApp.questionType
 * @description
 * # questionType
 * questionType in the diceApp.
 */
angular.module('diceApp').factory('questionType', function() {

   const options = {
    INFORMATIVE: {
      readOnly: true
    },
    SINGLE_LINE_TEXT: {
      store: false,
      visible: ['characterLimit']
    },
    SINGLE_LINE_NUMBER: {
      store: false,
      visible: ['characterLimit'],
      prepare: function(answer) {
        return parseInt(answer);
      }
    },
    MULTI_LINE_TEXT: {
      store: false,
      visible: ['characterLimit']
    },
    MATRIX_ONE_ANSWER: {
      visible: ['options']
    },
    MATRIX_MULTIPLE_ANSWERS: {
      multiple: true,
      visible: ['maximumSelectableOptions', 'options']
    },
    MULTIPLE_CHOICE_VERTICAL_ONE_ANSWER: {
      visible: ['options']
    },
    MULTIPLE_CHOICE_VERTICAL_MULTIPLE_ANSWERS: {
      multiple: true,
      visible: ['maximumSelectableOptions', 'options']
    },
    MULTIPLE_CHOICE_HORIZONTAL_ONE_ANSWER: {
      visible: ['options']
    },
    MULTIPLE_CHOICE_HORIZONTAL_MULTIPLE_ANSWERS: {
      multiple: true,
      visible: ['maximumSelectableOptions', 'options']
    },
    DROPDOWN_LIST: {
      store: false,
      visible: ['options']
    },
    SELECT_BOX: {
      multiple: true,
      store: false,
      visible: ['maximumSelectableOptions', 'options']
    },
    IMAGE: {
      readOnly: true,
      visible: ['image']
    }
  };

  return { isVisible, getProperty, getProperties };

  function getProperty(name, property) {
    return getProperties(name)[property];
  }

  function isVisible(name, component) {
    var properties = getProperties(name);
    var visible = properties.visible || [];
    return _.indexOf(visible, component) >= 0;
  }

  function getProperties(name) {
    var result;
    if (angular.isDefined(name)) {
      result = options[name];
    }
    return result || {};
  }

});
