'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:ParticipantSession
 * @description
 * # ParticipantSession
 * ParticipantSession resource of the diceApp
 */
angular.module('diceApp')
  .factory('ParticipantSession', function (DiceResource) {
    return DiceResource('/participant-sessions', {
      daily: {
        url: '/daily-participation',
        method: 'GET',
        isArray: false
      }
    });
  });
