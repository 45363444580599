'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceQueryColumns
 */
angular.module('diceApp')
  .component('diceQueryColumns', {
    bindings: {
      type: '<',
      template: '<',
      groups: '<',
      columns: '<',
      onChange: '&',
      onTemplate: '&'
    },
    templateUrl: 'es6/query/query.columns.html',
    controllerAs: 'queryColumnController',
    controller: function() {
      const queryColumnController = this;

      queryColumnController.toggle = function(column, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        
        column.visible = column.visible !== true;
        queryColumnController.onChange(column);
      };

      queryColumnController.getColumns = function() {
        return _(queryColumnController.columns).filter((column) => column.visible !== false).map((column, sequence) => {
          return {
            code: column.code,
            sequence
          };
        }).value();
      };
    }
  });
