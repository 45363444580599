'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceScript
 * @description
 * diceScript Shows script page
 */
angular.module('diceApp')
  .component('diceScript', {
    templateUrl: 'es6/admin/script/script.html',
    controllerAs: 'scriptController',
    controller: function($interval, Script, ScheduledScript, message) {
      const scriptController = this;

      scriptController.$onInit = function () {
        scriptController.queue = [];
        
        loadData();
        loadQueue();
        scriptController.interval = $interval(loadQueue, 5000);
      };

      scriptController.$onDestroy = function () {
        if (_.isFunction(scriptController.interval)) {
          scriptController.interval();
        }
      };

      function loadData() {
        ScheduledScript.query().$promise.then((schedules) => {
          scriptController.schedules = schedules;
        });

        Script.query().$promise.then((scripts) => {
          scriptController.scripts = _.sortBy(scripts, 'name');
        });
      }

      function loadQueue() {
        Script.queue().$promise.then((queue) => {
          scriptController.queue = queue;
        });
      }

      scriptController.onSelect = function() {
        setVariables();
      };

      function setVariables() {
        const script = _.find(scriptController.scripts, { name: scriptController.name });
        scriptController.arguments = _.result(script, 'variables') || [];
        _.each(scriptController.arguments, function(argument) {
            if (_.isEmpty(argument.value)) {
                argument.value = argument.defaultValue;
            }
        });
      }

      scriptController.schedule = function() {
        const body = buildBody();
        body.cron = scriptController.cron;
        ScheduledScript.save(body).$promise.then(function(result) {
            scriptController.schedules.push(result);
        });
      };

      scriptController.remove = function(schedule) {
        ScheduledScript.delete({
            id: schedule.id
        }, {}).$promise.then(() => {
            _.remove(scriptController.schedules, schedule);
        });
      };

      scriptController.execute = function() {
        const body = buildBody();

        scriptController.executing = true;
        Script.save(body).$promise.then(function(result) {
          message.addSuccess('Executed script');
          scriptController.queue = scriptController.queue.concat(result);
        }).finally(() => {
          scriptController.executing = false;
        });
      };

      function buildBody() {
        const body = {
          name: scriptController.name,
          arguments: {}
        };

        _.forEach(scriptController.arguments, function(argument) {
          if (!_.isEmpty(argument.value)) {
              body.arguments[argument.name] = argument.value;
          }
        });

        return body;
      }

      scriptController.clearQueue = function() {
        Script.clearQueue().$promise.then(() => {
          return loadQueue();
        });
      };
    }
});
