'use strict';

/**
 * @ngdoc service
 * @name diceApp.filter:Description
 * @description
 * The Description filter.
 */
angular.module('diceApp').filter('description', function(language) {
    return function(input, args) {
        if (_.isString(input)) {
            return input;
        }

        if (_.isEmpty(args)) {
            args = {};
        } else if (_.isString(args)) {
            args = { fallback: args };
        }

        const values = input || [];
        const filter = args.language || language.get();

        const value = _(values).filter({ 
            language: filter
        }).map('value').head();

        if (value) {
            return value;
        } else if (args.fallback) {
            return args.fallback;
        } else {
            return _(values).map('value').filter((it) => !_.isEmpty(it)).head();
        }
    };
});
