'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceEvaluationBlocks
 * @description
 * diceEvaluationBlocks Shows the blocks of an evaluation.
 */
angular.module('diceApp')
  .component('diceEvaluationBlocks', {
    bindings: {
      evaluation: '<',
      operations: '<',
      onChange: '&'
    },
    templateUrl: 'es6/evaluations/evaluation.blocks.html',
    controllerAs: 'blocksController',
    controller: function (Evaluation, EvaluationTemplate, Block, message, $uibModal, $state) {
      const blocksController = this;

      blocksController.$onInit = function() {
        loadData();
      };

      function loadData() {
        loadBlocks();

        if (angular.isDefined(blocksController.evaluation.template)) {
          EvaluationTemplate.get({
            id: blocksController.evaluation.template
          }).$promise.then((template) => {
            blocksController.template = template;
          });
        }
      }

      function loadBlocks() {
        const opened = _(blocksController.blocks).map((block, index) => {
          return block.open === true ? index : undefined;
        }).filter(angular.isDefined).value();

        Block.query({
          evaluationId: blocksController.evaluation.id
        }).$promise.then((blocks) => {
          blocksController.blocks = _(blocks).filter((block) => angular.isUndefined(block.template)).sortBy('sequence').map((block, index) => {
            const result = angular.copy(block);
            result.questions = _.sortBy(result.questions, 'sequence');
            result.open = _.includes(opened, index);
            return result;
          }).value();
        });
      }

      blocksController.add = function() {
        _.forEach(blocksController.blocks, (block) => {
          block.open = false;
        });

        blocksController.blocks.push({
          active: true,
          open: true,
          type: 'STANDARD_BLOCK',
          filters: []
        });
      };

      blocksController.moveUp = function(block, index) {
        var newIndex = Math.max(index - 1, 0);
        move(block, index, newIndex);
      };

      blocksController.moveDown = function(block, index) {
        var newIndex = Math.min(index + 1, blocksController.blocks.length - 1);
        move(block, index, newIndex);
      };

      function move(block, oldIndex, newIndex) {
        blocksController.blocks.splice(oldIndex, 1);
        blocksController.blocks.splice(newIndex, 0, block);
      }

      blocksController.save = function(form) {
        var blocks = angular.copy(blocksController.blocks);
        _.each(blocks, updateSequence);
        _.each(blocks, (block) => {
          _.each(block.questions, updateSequence);
          block.filters = _.filter(block.filters, isUsableFilter);
        });

        Evaluation.saveExtraBlocks({
          evaluationId: blocksController.evaluation.id
        }, blocks).$promise.then((evaluation) => {
          blocksController.evaluation = evaluation;
          blocksController.onChange({ evaluation: evaluation });

          loadData();

          form.$setPristine(false);
          message.addSuccessLabel('Message.Save.Success');
        });
      };

      function updateSequence(container, $index) {
        container.sequence = $index;
      }

      function isUsableFilter(filter) {
        return !_.isEmpty(filter.property) &&
               !_.isEmpty(filter.name) &&
               !_.isEmpty(filter.operation) &&
               !_.isEmpty(filter.expected);
      }

      blocksController.removeBlock = function (index) {
        blocksController.blocks.splice(index, 1);
      };

      // IE 'draggable' issues
      blocksController.setMode = function (event, mode, elementId) {
        event.stopPropagation();
        let elm = angular.element('#' + elementId);
        if (mode === 'drag') {
          elm.attr('draggable', true);
        } else {
          elm.removeAttr('draggable');
        }
      };

      blocksController.switchTemplate = function () {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;
            modalController.evaluation = angular.copy(blocksController.evaluation);

            modalController.refresh = function () {
              modalController.modal.close();
              $state.reload();
            };
          },
          template: `
            <evaluation-template-picker
              evaluation="modalController.evaluation"
              on-save="modalController.refresh(evaluation)"
              modal="modalController.modal">
            </dice-evaluation-template-picker>
          `
        });
      };
    }
  });
