'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Period
 * @description
 * # Period
 * Period resource of the diceApp
 */
angular.module('diceApp')
  .factory('Period', function (DiceResource) {
    return DiceResource('/periods', {
      progress: {
        url: '/periods/progress',
        method: 'GET',
        isArray: true
      },
      years: {
        url: '/periods/years',
        method: 'GET',
        isArray: true
      }
    });
  });
