'use strict';

/**
 * We only want to render the download-widget after login, but we cannot inject this logic in the index.tpl.html,
 * hence this wrapper component.
 */
angular.module('diceApp').component('downloadWrapper', {
  templateUrl: 'es6/export/download/download.wrapper.html',
  controllerAs: 'downloadWrapperController',
  controller: function(authentication) {
    const downloadWrapperController = this;
    downloadWrapperController.isAuthenticated = () => authentication.isAuthenticated();
  }
});
