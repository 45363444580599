'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:emptyWidget
 * @description
 * emptyWidget Empty widget
 */
angular.module('diceApp')
  .component('emptyWidget', {
    templateUrl: 'es6/dashboard/empty.widget.html'
  });