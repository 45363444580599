'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:searchInput
 * @description
 * The searchInput directive.
 */
angular.module('diceApp')
  .component('searchInput', {
    bindings: {
      inputId: '@?'
    },
    templateUrl: 'es6/base/forms/inputs/search.input.html',
    require: {
      ngModel: 'ngModel'
    },
    controllerAs: 'searchInputController',
    controller: function ($scope) {
      const searchInputController = this;

      searchInputController.$onInit = function() {
        $scope.$watch('searchInputController.ngModel.$modelValue', function () {
          searchInputController.value = searchInputController.ngModel.$modelValue;
        });
      };

      searchInputController.update = _.debounce(update, 500);

      function update() {
        searchInputController.ngModel.$setViewValue(searchInputController.value);
      }

      searchInputController.clearInput = function() {
        searchInputController.value = '';
        update();
      };
    }
  });
