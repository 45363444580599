'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:Button
 * @description
 * The base button with several options.
 */
angular.module('diceApp')
  .directive('baseButton', function () {
    return {
      restrict: 'E',
      scope: {
        btnType: '@?',
        btnSize: '@?',
        btnContext: '@?',
        btnClick: '&?',
        type: '@?',
        btnDisabled: '<?'
      },
      transclude: true,
      templateUrl: 'es6/base/forms/buttons/base.button.html',
      link: function (scope, element) {
        scope.btnContext = scope.btnContext || 'default';

        if (scope.type) {
          element.find('button').attr('type', scope.type);
        }

        scope.executeBtnClick = function (event) {
          if (scope.btnClick && !scope.btnDisabled) {
            scope.btnClick({ event: event });
          }
        };

        scope.setType = function (type) {
          return type ? 'btn-' + type : '';
        };
      }
    };
  });
