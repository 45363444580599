'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Organization
 * @description
 * # Organization
 * Organization resource of the diceApp
 */
angular.module('diceApp')
	.factory('Organization', function(DiceResource) {
		return DiceResource('/organization');
	});
