'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceQueryCell
 */
angular.module('diceApp')
  .component('diceQueryCell', {
    bindings: {
      column: '<',
      data: '<'
    },
    templateUrl: 'es6/query/query.cell.html',
    controllerAs: 'queryCellController',
    controller: function() {
        const queryCellController = this;

        queryCellController.$onInit = function() {
            queryCellController.valueType = _.result(queryCellController.column, 'valueType', 'STRING');
        };
    }
  });
