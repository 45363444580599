'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:languageDropdown
 * @description
 * The languageDropdown directive.
 */
angular.module('diceApp').component('languageDropdown', {
    require: {
        ngModel: 'ngModel'
    },
    bindings: {
        disabled: '@?'
    },
    templateUrl: 'es6/i18n/language.dropdown.html',
    controllerAs: 'languageDropdownController',
    controller: function(language, $scope, translateFilter) {
      const languageDropdownController = this;

      languageDropdownController.$onInit = function() {
        languageDropdownController.editable = languageDropdownController.disabled !== 'disabled';
        languageDropdownController.supported = _.map(language.supported, (supported) => {
            return {
                key: supported,
                name: translateFilter('Generic.Language.' + supported) + ' (' + supported.toUpperCase() + ')'
            };
        });

        $scope.$watch('languageDropdownController.ngModel.$modelValue', function () {
            languageDropdownController.value = languageDropdownController.ngModel.$modelValue;
        });
      };

      languageDropdownController.update = function () {
          languageDropdownController.ngModel.$setViewValue(languageDropdownController.value);
      };

    }
  });
