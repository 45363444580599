/**
 * @ngdoc component
 * @name diceApp.component:participantSelect
 * @description
 */
angular.module('diceApp').component('participantSelect', {
    bindings: {
      evaluationId: '<',
      autoSelect: '<?',
      label: '@?'
    },
    require: {
      ngModelCtrl: 'ngModel'
    },
    templateUrl: 'es6/participate/participant.select.html',
    controllerAs: 'participantSelectController',
    controller: function(Participant) {
      const participantSelectController = this;

      participantSelectController.$onInit = function() {
        participantSelectController.ngModelCtrl.$render = () => {
          let viewValue = participantSelectController.ngModelCtrl.$viewValue;
          if (_.isString(viewValue)) {
            viewValue = parseInt(viewValue);
          }
          participantSelectController.participantId = viewValue;
        };
      };

      participantSelectController.$onChanges = function() {
        loadData();
      };

      function loadData() {
        if (angular.isDefined(participantSelectController.evaluationId)) {
          Participant.query({
            evaluationId: participantSelectController.evaluationId
          }).$promise.then((participants) => {
            participantSelectController.participants = _(participants)
              .map((participant) => {
                if (!participant.fullName) {
                  participant.fullName = `${participant.id}`;
                }
                return participant;
              })
              .sortBy('fullName')
              .value();

            if (participantSelectController.autoSelect && angular.isUndefined(participantSelectController.participantId)) {
              const participant = _.head(participantSelectController.participants);
              participantSelectController.onSelect(participant);
            } else {
              setSelected();
            }
          });
        }
      }

      function setSelected() {
        _.forEach(participantSelectController.participants, (participant) => {
          participant.selected = participant.id === participantSelectController.participantId;
        });
      }

      participantSelectController.onChange = function() {
        participantSelectController.ngModelCtrl.$setViewValue(participantSelectController.participantId);
        setSelected();
      };

      participantSelectController.onSelect = function(participant) {
        participantSelectController.participantId = _.get(participant, 'id');
        participantSelectController.onChange();
      };
    }
  });
