'use strict';

/**
 * Pretty prints how long ago the date was.
 * Usage goes as follows:
 * {{ milliseconds | timeago }}
 */
angular.module('diceApp').filter('timeago', function($filter) {
    return function(millis, pattern, defaultText) {
        if (angular.isUndefined(millis)) {
            return '';
        }

        var current = new Date().getTime();
        var ago = current - millis;
        if (ago > 0) {
            return $filter('duration')(ago, pattern, 1, defaultText) + ' ' + $filter('translate')('Duration.Ago');
        } else {
            return $filter('translate')('Duration.In') + ' ' + $filter('duration')(Math.abs(ago), pattern, 1, defaultText, true);
        }
    };
});
