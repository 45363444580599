'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:MemberTypeStates
 * @description
 * # MemberTypeStates
 * Configures all states in relation to member types.
 */
angular.module('diceApp').config(function ($stateProvider) {
  $stateProvider.state('member-type-list', {
    url: '/member-types',
    data: {
      root: true
    },
    resolve: {
      memberTypes: function (MemberType) {
        return MemberType.query().$promise;
      }
    },
    controllerAs: 'memberTypeListController',
    controller: function (memberTypes) {
      const memberTypeListController = this;
      memberTypeListController.memberTypes = memberTypes;
    },
    template: '<dice-member-type-list member-types="memberTypeListController.memberTypes"></dice-member-type-list>'
  });
});