'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:AccessRule
 * @description
 * # AccessRule
 * AccessRule resource of the diceApp
 */
angular.module('diceApp').factory('AccessRule', function (DiceResource) {
  return DiceResource('/access-rules', {
    granted: {
      url: '/access-rules/granted/:evaluationId',
      method: 'GET',
      isArray: false
    }
  });
});
