'use strict';

angular.module('diceApp')
  .directive('includeWidget', function ($compile) {
    return {
      restrict: 'A',
      scope: true,
      attrs: ['includeWidget'],
      link: function (scope, element, attrs) {
        var widget = angular.element(attrs.includeWidget);
        $compile(widget)(scope);
        element.addClass('widget');
        element.append(widget);
      }
    };
  });