'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Member
 * @description
 * # Member
 * Member resource of the diceApp
 */
angular.module('diceApp')
  .factory('Member', function (DiceResource) {
    return DiceResource('/members', {
      setDisabled: {
        url: '/members/:id/disabled',
        method: 'PUT'
      }
    });
  });
