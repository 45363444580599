'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceLabel
 * @description
 * diceEvaluationTemplate Shows the evaluation templates in detail.
 */
angular.module('diceApp')
  .component('diceLabel', {
    bindings: {
      labels: '<'
    },
    bindToController: true,
    templateUrl: 'es6/i18n/label.html',
    controllerAs: 'labelController',
    controller: function(Label, Pageable, SessionStorage, $translate, $filter) {
      const labelController = this;

      labelController.$onInit = function () {
        labelController.pageable = Pageable.of();
        labelController.filteredLabels = labelController.labels;
        labelController.searchString = '';
      };

      labelController.search = function() {
        labelController.filteredLabels = $filter('filter')(labelController.labels, _.toLower(labelController.searchString));
        labelController.filteredLabels = _.orderBy(labelController.filteredLabels, function(label) {
          return _.result(_.last(label), 'key');
        });
      };

      labelController.save = function () {
        _.each(labelController.labels, function (labels) {
          _.each(labels, function (label) {
            if (label.value !== label.original.value) {
              labelController.updateLabel(label);
            }
          });
        });
      };

      labelController.updateLabel = function (label) {
        delete label.original;
        Label.store(label).$promise.then(function () {
          label.original = angular.copy(label);

          // Remove languages
          SessionStorage.remove('EN');
          SessionStorage.remove('NL');

          // Refresh languages
          $translate.refresh();
        });
      };
    }
  });
