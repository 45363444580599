'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:OrganisationStates
 * @description
 * # OrganisationStates
 * Configures all states for organisations.
 */
angular.module('diceApp').config(function ($stateProvider) {

  $stateProvider.state('organisation-list', {
    url: '/organisations',
    template: '<dice-organisation-list></dice-organisation-list>'
  });

});
