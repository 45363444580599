'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:booleanCheck
 * @description
 * The booleanCheck directive.
 */
angular.module('diceApp')
  .component('booleanCheck', {
    bindings: {
      ngModel: '<'
    },
    templateUrl: 'es6/base/tables/boolean.check.html',
    controllerAs: 'booleanCheckController'
  });
