'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:Storage
 * @description
 * # Storage
 * Wrapper around browser specific storage, includes fallback
 * for browsers that do not support a certain storage type.
 */
angular.module('diceApp').factory('Storage', function() {

    function of(storage) {
        if (angular.isUndefined(storage)) {
            return build();
        }

        return {
            get: function(name) {
                return storage.getItem(name);
            },
            set: function(name, value) {
                return storage.setItem(name, value);
            },
            remove: function(name) {
                return storage.removeItem(name);
            }
        };
    }

    function build() {
        let values = {};

        return {
            get: function(name) {
                return values[name];
            },
            set: function(name, value) {
                values[name] = value;
            },
            remove: function(name) {
                delete values[name];
            }
        };
    }

    return { of };

});