'use strict';

angular.module('diceApp').config(function ($stateProvider) {
  $stateProvider.state('parameter-list', {
    url: '/parameters',
    data: {
      root: true
    },
    controllerAs: 'parameterController',
    template: '<parameter-list></parameter-list>'
  });
});
