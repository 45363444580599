'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceEvaluationTypeList
 * @description
 * diceEvaluationTypeList Shows the evaluation types.
 */
angular.module('diceApp')
  .component('diceEvaluationTypeList', {
    bindings: {
      evaluationTypes: '<'
    },
    templateUrl: 'es6/templates/type/evaluation.type.list.html',
    controllerAs: 'evaluationTypeListController',
    controller: function ($uibModal, EvaluationType, Pageable, message) {
      const evaluationTypeListController = this;

      evaluationTypeListController.$onInit = function() {
        evaluationTypeListController.pageable = Pageable.of({
          order: 'sequence'
        });
      };

      evaluationTypeListController.open = function (evaluationType) {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;
            modalController.evaluationType = {};

            if (angular.isDefined(evaluationType.id)) {
              EvaluationType.get({ id: evaluationType.id }).$promise.then((result) => {
                modalController.evaluationType = result;
                modalController.evaluationType.organisationId = _.get(modalController.evaluationType, 'organisation.id');
                delete modalController.evaluationType.organisation;
              });
            }

            modalController.onSave = function (result) {
              var found = _.find(evaluationTypeListController.evaluationTypes, { id: result.id });
              if (found) {
                _.extend(found, result);
              } else {
                evaluationTypeListController.evaluationTypes.push(result);
              }
              message.addSuccessLabel('Message.Save.Success');
            };
          },
          windowClass: 'medium-modal-window',
          template: `
            <evaluation-type-modal
              evaluation-type="modalController.evaluationType"
              on-save="modalController.onSave"
              modal="modalController.modal">
            </evaluation-type-modal>
          `
        });
      };

      evaluationTypeListController.remove = function(evaluationType) {
        EvaluationType.remove(evaluationType.id).$promise.then(function() {
          message.addSuccessLabel('Message.Delete.Success');
          evaluationTypeListController.evaluationTypes.splice(evaluationTypeListController.evaluationTypes.indexOf(evaluationType), 1);
        });
      };


    }
  });
