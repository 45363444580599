'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:UserLogin
 * @description
 * The UserLogin service.
 */
angular.module('diceApp').factory('UserLogin', function (DiceResource) {
  return DiceResource('/user-logins', {
    daily: {
      method: 'GET',
      url: '/user-logins/daily',
      isArray: true
    }
  });
});
