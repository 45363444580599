'use strict';

/**
 * Sortable table headers with icons, font for icons:FontAwesome.
 */
angular.module('diceApp')
    .directive('sortableTable', function($timeout) {
        return {
            restrict: 'A',
            transclude: true,
            replace: true,
            templateUrl: 'es6/base/pagination/sortable.table.html',
            scope: {
                order: '<',
                pageable: '<',
                onChange: '&?'
            },
            link: function(scope) {
                const pageable = scope.pageable || {};

                if (angular.isUndefined(pageable.reverse)) {
                    pageable.reverse = false;
                }

                scope.onClick = function() {
                    if (scope.order) {
                        setOrder();
                    }
                };

                function setOrder() {
                    if (scope.order === pageable.order) {
                        pageable.reverse = pageable.reverse !== true;
                    } else {
                        pageable.order = scope.order;
                        pageable.reverse = false;
                    }

                    if (scope.onChange) {
                        $timeout(() => {
                            scope.onChange(pageable);
                        });
                    }
                }
            }
        };
    });
