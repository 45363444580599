'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:evaluationTemplatePreparator
 * @description
 * Prepare an evaluation template for preview.
 */
angular.module('diceApp')
  .factory('evaluationTemplatePreparator', function() {

    const MEMBER = {
        id: 99,
        fullName: 'John Doe'
    };

    return { prepare };

    function prepare(evaluation) {
        const result = angular.copy(evaluation);
        prepareBlocks(result.blocks);
        return result;
    }

    function prepareBlocks(blocks) {
        _.each(blocks, function(block) {
            if (block.block.type === 'MEMBER_BLOCK') {
                block.member = MEMBER;
            }
        });
    }

  });