'use strict';

/**
 * @ngdoc directive
 * @name diceApp.component:dicePreviewBlock
 * @description Previews a block.
 */
angular.module('diceApp').component('dicePreviewBlock', {
  templateUrl: 'es6/preview/block.html',
  bindings: {
    block: '<',
    blocks: '<?',
    onAnswer: '&?'
  },
  controllerAs: 'previewBlockController',
  controller: function () {
    const previewBlockController = this;

    previewBlockController.$onChanges = function () {
      loadQuestions();
    };

    previewBlockController.answer = function (question, value) {
      if (previewBlockController.onAnswer) {
        previewBlockController.onAnswer({ question, value });
      }
    };

    function loadQuestions() {
      previewBlockController.questions =
        _(previewBlockController.block.questions)
          .map((question) => _.extend(question, question.question))
          .sortBy('sequence')
          .value();
    }
  }
});
