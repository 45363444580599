'use strict';

angular.module('diceApp').component('exportList', {
  templateUrl: 'es6/export/export.list.html',
  controllerAs: 'exportListController',
  controller: function(Export, Pageable) {
    const exportListController = this;

    exportListController.$onInit = function() {
      exportListController.params = {};
      exportListController.pageable = Pageable.of({
        order: 'created',
        reverse: true
      });

      loadData();
    };

    function loadData() {
      const params = exportListController.pageable.build(exportListController.params);
      Export.findAll(params).then((exports) => {
        exportListController.exports = exports.data;
      });
    }

    exportListController.search = function() {
      loadData();
    };
  }
});
