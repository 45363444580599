'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:dicePagination
 * @description
 * dicePagination Shows the pagination bar.
 */
angular.module('diceApp')
  .component('dicePagination', {
    bindings: {
        page: '<',
        pageable: '<',
        showSizeBar: '<?',
        onChange: '&?'
    },
    templateUrl: 'es6/base/pagination/pagination.html',
    controllerAs: 'paginationController',
    controller: function() {
        const paginationController = this;

        paginationController.$onInit = function() {            
            paginationController.displaySizeOptions = _.get(paginationController, 'showSizeBar', true);
            paginationController.sizeBarOptions = [5, 10, 25, 50, 250];

            if (angular.isUndefined(paginationController.pageable)) {
                paginationController.pageable = {};
            }

            paginationController.pageable.currentPage = _.get(paginationController, 'pageable.currentPage', 1);
            paginationController.pageable.pageSize = _.get(paginationController, 'pageable.pageSize', 10);
            paginationController.pageable.maxSize = _.get(paginationController, 'pageable.maxSize', 3);
        };

        paginationController.$onChanges = function() {
            if (_.isArray(paginationController.page)) {
                paginationController.totalElements = paginationController.page.length;
                paginationController.totalPages = Math.ceil(paginationController.page.length / paginationController.pageSize);
            } else {
                paginationController.totalElements = _.get(paginationController.page, 'totalElements', 0);
                paginationController.totalPages = _.get(paginationController.page, 'totalPages', 0);
            }
        };

        paginationController.updateSize = function(pageSize) {
            paginationController.pageable.pageSize = pageSize;
            paginationController.update();
        };

        paginationController.update = function() {
            if (paginationController.onChange) {
                paginationController.onChange({
                    pageable: paginationController.pageable
                });
            }
        };
    }
});
