'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:DeleteButton
 * @description
 * The DeleteButton service.
 */
angular.module('diceApp')
  .directive('deleteButton', function () {
    return {
      restrict: 'E',
      templateUrl: 'es6/base/forms/buttons/delete.button.html',
      scope: {
        onDelete: '&?',
        btnType: '@?',
        btnLabel: '@?',
        btnSize: '@?',
        btnIconOnly: '=?',
        btnContext: '@?'
      },
      link: function (scope) {
        scope.btnContext = scope.btnContext || 'danger';
        scope.btnLabel = scope.btnLabel || 'Generic.Delete';
      }
    };
  });
