'use strict';

/**
 * @ngdoc filter
 * @name diceApp.filter:htmlToPlaintext
 * @description
 * The html to plain text filter.
 */
angular.module('diceApp')
  .filter('htmlToPlaintext', function () {
    return function(text) {
      return text ? String(text)
        .replace(/\<(?!\/?(b|i|u|style|title)[ >])[^>]*\>/gi, '')
        .trim() : '';
    };
  });
