'use strict';

/**
 * @ngdoc service
 * @name diceApp.filter
 * @description
 * # answerOptions
 * filterStore in the diceApp.
 */
angular.module('diceApp').factory('ReportExcelExporter', function (Sheet, descriptionFilter, numberFilter) {

    return { buildReport, buildComposed };

    function buildComposed(args) {
        const { reports } = args;

        const sheet = Sheet.create();
        _.forEach(reports, (report) => {
            addQuestions(report, sheet);
        });
        return sheet;
    }

    function buildReport(args) {
        const { report } = args;

        const sheet = Sheet.create();
        addQuestions(report, sheet);
        return sheet;
    }

    function addQuestions(report, sheet) {
        _(report.reportBlocks).orderBy('sequence').forEach((block) => {
            _(block.questionReports).orderBy('sequence').forEach((question) => {
                let row = {
                    code: report.evaluation.code, 
                    evaluation: descriptionFilter(report.evaluation.names),
                    period: _.result(report.evaluation, 'period.period', '*'), 
                    year: _.result(report.evaluation, 'period.displayYear', '*'),
                    report: descriptionFilter(report.template.names),
                    block: stripHtml(block.blockLabel),
                    member: _.result(block.member, 'name'),
                    question: stripHtml(question.questionLabel)
                };

                _(question.columns).sortBy('sequence').forEach((column) => addOption(row, `column-${column.value}`, column));
                _(question.options).sortBy('sequence').forEach((option) => addOption(row, `value-${option.value}`, option));

                row = _.extend(row, {
                    average: numberFilter(question.averageValue, 1),
                    deviation: numberFilter(question.standardDeviationValue, 1),
                    respondents: numberFilter(question.respondentCount, 0),
                    answers: getAnswers(question)
                });

                sheet.push(row);
            });
        });
    }

    function addOption(row, key, option) {
        row[`${key}`] = option.count;
        if (angular.isDefined(option.percentage)) {
            row[`${key}-percentage`] = option.percentage;
        }
        if (angular.isDefined(option.averageValue)) {
            row[`${key}-average`] = option.averageValue;
        }
    }

    function stripHtml(text) {
        if (!text) {
            return '';
        }

        return text.replace(/<[^>]*>?/gm, '');
    }

    function getAnswers(question) {
        if (_.includes(['BULLET', 'TEXT'], question.visualization)) {
            return _(question.options).map('value').uniq().sort().join(', ');
        }

        return '';
    }

});
