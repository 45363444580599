'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:ThresholdStates
 * @description
 * # ThresholdStates
 * Configures all states in thresholds.
 */
angular.module('diceApp').config(function ($stateProvider) {
  
  $stateProvider.state('threshold-list', {
    url: '/thresholds',
    template: '<threshold-list></threshold-list>',
    data: {
      root: true
    }
  });

  $stateProvider.state('threshold-create', {
    url: '/thresholds/create',
    controllerAs: 'thresholdStateController',
    controller: function() {
        this.threshold = {
          colors: []
        };
    },
    template: '<threshold-edit threshold="thresholdStateController.threshold"></threshold-edit>'
  });

  $stateProvider.state('threshold', {
    url: '/thresholds/{id:[0-9]{1,8}}',
    resolve: {
        threshold: function($stateParams, Threshold) {
            return Threshold.get({
                id: $stateParams.id
            }).$promise;
        }
    },
    controllerAs: 'thresholdStateController',
    controller: function(threshold) {
        this.threshold = threshold;
    },
    template: '<threshold-edit threshold="thresholdStateController.threshold"></threshold-edit>'
  });

});
