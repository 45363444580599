'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:EvaluationList
 * @description
 * EvaluationList Shows the evaluations in list.
 */
angular.module('diceApp')
  .component('diceParticipateMessage', {
    bindings: {
      evaluationId: '<',
      type: '<',
      session: '<?',
      message: '@',
      messageLabel: '@',
      breadcrumbLabel: '@'
    },
    templateUrl: 'es6/participate/participate.message.html',
    controllerAs: 'participateMessageController',
    controller: function (Participate, Customer, $window, authentication) {
      const participateMessageController = this;

      participateMessageController.$onInit = function () {
        participateMessageController.isAuthenticated = authentication.isAuthenticated();

        loadData();
      };

      function loadData() {
        Customer.load().then((customer) => {
          participateMessageController.customer = customer;
        });
      }

      participateMessageController.logout = function () {
        Participate.logout({
          evaluationId: participateMessageController.evaluationId
        }, {}).$promise.then(function (result) {
          $window.location = result.targetUrl;
        });
      };
    }
  });
