'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Element
 * @description
 * # Element
 * Element resource of the diceApp
 */
angular.module('diceApp')
	.factory('Element', function(DiceResource) {
		return DiceResource('/elements');
	});
