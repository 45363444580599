'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:PersonController
 * @description
 * # PersonController
 * Controller of the diceApp
 */
angular.module('diceApp').component('dicePersonList', {
  bindings: {
    parameters: '<'
  },
  templateUrl: 'es6/members/person.list.html',
  controllerAs: 'listController',
  controller: function(
    authentication,
    Person,
    Synchronize,
    $state,
    $uibModal,
    message,
    Pageable
  ) {
    const listController = this;
    listController.hasRole = authentication.hasRole;

    listController.$onInit = function() {
      listController.pageable = Pageable.parse(listController.parameters);
      listController.searchString = listController.parameters.text;
      listController.active = listController.parameters.active;
      listController.syncing = false;

      Synchronize.get().$promise.then((properties) => {
        listController.isSyncEnabled = properties.persons === true;
      });

      listController.update();
    };

    listController.onEmployee = function() {
      listController.employee = listController.employee !== true;
      listController.update();
    };

    listController.onStudent = function() {
      listController.student = listController.student !== true;
      listController.update();
    };

    listController.update = function() {
      listController.searching = true;

      const params = listController.pageable.build({
        text: _.lowerCase(listController.searchString),
        active: listController.active,
        employee: listController.employee ? true : undefined,
        student: listController.student ? true : undefined
      });

      Person.search(params).$promise.then((persons) => {
        listController.persons = persons;
      }).finally(() => {
        listController.searching = false;
      });

      updateState(params);
    };

    function updateState(params) {
      $state.go('person-list', params, {
        notify: false
      });
    }

    listController.become = function(person, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      authentication.become(person).finally(() => {
        $state.go('dashboard');
      });
    };

    listController.sync = function(person, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      listController.syncing = true;
      Synchronize.persons({
        id: person.id
      }, {}).$promise.then(function(newPerson) {
        _.extend(person, newPerson);
        listController.syncing = false;
      });
    };

    listController.syncAll = function() {
      listController.syncing = true;
      Synchronize.persons().$promise.then(function() {
        listController.syncing = false;
        listController.update();
      });
    };

    listController.open = function(person) {
      $uibModal.open({
        controllerAs: 'modalController',
        controller: function($uibModalInstance) {
          const modalController = this;
          modalController.modal = $uibModalInstance;
          modalController.hasRole = authentication.hasRole;

          modalController.person = angular.copy(person);
          modalController.person.organisationId = _.get(modalController.person, 'organisation.id');
          delete modalController.person.organisation;

          modalController.onSave = function() {
            message.addSuccessLabel('Message.Save.Success');
            listController.update();
          };

          modalController.onDelete = function() {
            message.addSuccessLabel('Message.Delete.Success');
            listController.update();
          };
        },
        template: `
          <person-modal
            modal="modalController.modal"
            person="modalController.person"
            is-editable="modalController.hasRole(['SYSTEM_ADMIN', 'ADMIN'])"
            on-save="modalController.onSave(person)"
            on-delete="modalController.onDelete(person)">
          </person-modal>`
      });
    };

    listController.substitutes = function(person) {
      $state.go('substitute-list', { personId: person.id });
    };
  }
});
