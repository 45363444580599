'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceStatusTypeList
 * @description
 * # diceStatusTypeList
 * Controller of the diceApp
 */
angular.module('diceApp')
  .component('diceStatusTypeList', {
    bindings: {
      statusTypes: '<'
    },
    templateUrl: 'es6/status/type/status.type.list.html',
    controllerAs: 'statusTypeListController',
    controller: function ($uibModal, StatusType, Pageable, EvaluationType, message) {
      const statusTypeListController = this;

      statusTypeListController.$onInit = function () {
        statusTypeListController.pageable = Pageable.of({
          order: 'fullName'
        });

        EvaluationType.query().$promise.then(function (types) {
          statusTypeListController.evaluationTypes = types;
          statusTypeListController.evaluationType = _.find(types, { defaultSelected: true });
          statusTypeListController.filterTypes();
        });
      };

      statusTypeListController.filterTypes = function () {
        statusTypeListController.filteredTypes = _.filter(statusTypeListController.statusTypes, function(statusType) {
          return statusType.evaluationType === statusTypeListController.evaluationType.id;
        });
      };

      statusTypeListController.openSequenceOrder = function () {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;
            modalController.statusTypes = statusTypeListController.statusTypes;
            modalController.evaluationType = statusTypeListController.evaluationType;

            modalController.onSave = function (statusTypes) {
              // replace saved member types in list and sort list by sequence
              statusTypeListController.statusTypes = _(statusTypeListController.statusTypes).map((st) => {
                let foundStatusType = _.find(statusTypes, { 'id': st.id });
                return angular.isDefined(foundStatusType) ? foundStatusType : st;
              }).orderBy('sequence').value();
              statusTypeListController.filterTypes();
              message.addSuccessLabel('Message.Save.Success');
            };
          },
          template: `
            <status-type-sequence-modal
              evaluation-type="modalController.evaluationType"
              status-types="modalController.statusTypes"
              on-save="modalController.onSave"
              modal="modalController.modal">
            </status-type-sequence-modal>
           `
        });
      };

      statusTypeListController.remove = function (statusType) {
        StatusType.remove(statusType.id).$promise.then(function() {
          message.addSuccessLabel('Message.Delete.Success');
         _.remove(statusTypeListController.statusTypes, (s) => s.id === statusType.id);
         statusTypeListController.filterTypes();
        });
      };

      statusTypeListController.create = function () {
        statusTypeListController.open({
          evaluationType: statusTypeListController.evaluationType.id
        });
      };

      statusTypeListController.open = function (statusType) {
          $uibModal.open({
            controllerAs: 'modalController',
            controller: function ($uibModalInstance) {
              const modalController = this;
              modalController.modal = $uibModalInstance;
              modalController.statusType = angular.copy(statusType);

              modalController.onSave = function (savedType) {
                var found = _.find(statusTypeListController.statusTypes, { id: savedType.id });
                if (found) {
                  _.extend(found, savedType);
                } else {
                  statusTypeListController.statusTypes.push(savedType);
                }
                statusTypeListController.filterTypes();
                message.addSuccessLabel('Message.Save.Success');
              };
            },
            template: `
              <status-type-modal
                status-type="modalController.statusType"
                on-save="modalController.onSave"
                modal="modalController.modal">
              </status-type-modal>
            `
          });
        };

      statusTypeListController.openRules = function (statusType, $event) {
        // prevent clicking on the row itself.
        $event.preventDefault();
        $event.stopPropagation();

        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.modal = $uibModalInstance;
            modalController.statusType = statusType;
          },
          template: `
            <access-rule-list-for-status-type-modal
              status-type="modalController.statusType"
              modal="modalController.modal">
            </access-rule-list-for-status-type-modal>
          `
        });
      };
    }
  });
