'use strict';

angular.module('diceApp').component('diceCredits', {
  templateUrl: 'es6/base/credits.html',
  controllerAs: 'creditsController',
  controller: function () {
    const creditsController = this;
    creditsController.currentDate = new Date();
    creditsController.year = creditsController.currentDate.getFullYear();
    creditsController.timeEditUrl = 'https://www.timeedit.com/';
  }
});
