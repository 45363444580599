'use strict';

/**
 * @ngdoc directive
 * @name diceApp.factory:errorFieldsStore
 * @description Provides callbacks whenever an error occurs.
 */
angular.module('diceApp')
  .factory('errorFieldsStore', function () {
    let _onErrorCallbacks = [];

    return { publish, onError, clearErrors };

    /**
     * @description Performs all registered callbacks.
     */
    function publish(error) {
      _.each(_onErrorCallbacks, (callback) => callback(error));
    }

    /**
     * @description Registers a callback function for when a field error occurs.
     * @param {fn(constraints) -> void} callback The function that handles the error event.
     */
    function onError(callback) {
      _onErrorCallbacks.push(callback);
    }

    function clearErrors() {
      publish({});
    }
  });
