'use strict';

angular.module('diceApp').component('diceTagPopover', {
  bindings: {
    tags: '<',
    viewClasses: '<?'
  },
  templateUrl: 'es6/tags/tag.popover.html',
  controllerAs: 'tagPopoverController',
  controller: function (Tags) {
    const tagPopoverController = this;

    tagPopoverController.$onInit = function () {
      tagPopoverController.tags_ = Tags.build(tagPopoverController.tags);
    };

    tagPopoverController.stop = function ($event) {
      $event.stopPropagation();
      $event.preventDefault();
    };
  }
});