'use strict';

angular.module('diceApp').component('showHideToggle', {
  templateUrl: 'es6/base/show.hide.toggle.html',
  bindings: {
    onToggle: '&'
  },
  controllerAs: 'showHideController',
  controller: function () {
    const showHideController = this;

    showHideController.$onInit = function () {
      showHideController.expanded = false;
    };

    showHideController.toggle = function () {
      showHideController.expanded = !showHideController.expanded;
      showHideController.onToggle({ expanded: showHideController.expanded });
    };
  }
});