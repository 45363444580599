'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:scrollObserver
 * @description Observes for scrolling on elements with a data-section-id and marks the corresponding navigation link as active.
 */
angular.module('diceApp')
  .factory('ScrollService', function ($document, $timeout) {
    let observer;

    function initObserver(sectionIds) {
      if (observer) {
        observer.disconnect();
      }

      const scrollHandler = (entries) => {
        entries.forEach((entry) => {
          const section = entry.target;
          const sectionId = section.getAttribute('data-section-id');
          const sectionLink = $document[0].querySelector(`[data-section-id-nav='${sectionId}']`);

          if (entry.intersectionRatio > 0) {
            angular.element(sectionLink).addClass('active');
          } else {
            angular.element(sectionLink).removeClass('active');
          }
        });
      };

      observer = new IntersectionObserver(scrollHandler, {
        rootMargin: '-60px 0px 0px 0px'
      });

      sectionIds.forEach((sectionId) => {
        const element = $document[0].querySelector(`[data-section-id='${sectionId}']`);
        if (element) {
          observer.observe(element);
        }
      });
    }

    return {
      setupObserver: function(ids) {
        $timeout(() => {
          initObserver(ids);
        });
      },

      disconnectObserver: function () {
        if (observer) {
          observer.disconnect();
        }
      }
    };
  });

