'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:StatusTransitionStates
 * @description
 * # StatusTransitionStates
 * Configures all states for status transitions
 */
angular.module('diceApp').config(function($stateProvider) {
  $stateProvider.state('status-type-list', {
    url: '/status-type',
    data: {
      root: true
    },
    resolve: {
      statusTypes: function(StatusType) {
        return StatusType.query().$promise;
      }
    },
    controllerAs: 'statusTypeListController',
    controller: function(statusTypes) {
      const statusTypeListController = this;
      statusTypeListController.statusTypes = statusTypes;
    },
    template: '<dice-status-type-list status-types="statusTypeListController.statusTypes"></dice-status-type-list>'
  });
});
