'use strict';

/**
 * @ngdoc component
 * @name diceApp.component:memberTypeMultipleSelect
 * @description
 * The memberTypeMultipleSelect component.
 */
angular.module('diceApp')
  .component('memberTypeMultipleSelect', {
      templateUrl: 'es6/members/type/member.type.multiple.select.html',
      bindings: {
        evaluationType: '='
      },
      require: {
        ngModel: 'ngModel'
      },
      controllerAs: 'memberTypeMultipleSelectController',
      controller: function(MemberType, $scope) {
        const memberTypeMultipleSelectController = this;

        memberTypeMultipleSelectController.$onInit = function () {
          $scope.$watch('memberTypeMultipleSelectController.evaluationType', loadData);
          $scope.$watch('memberTypeMultipleSelectController.ngModel.$modelValue', function () {
            memberTypeMultipleSelectController.value = memberTypeMultipleSelectController.ngModel.$modelValue;
          });
        };

        loadData();

        memberTypeMultipleSelectController.onChange = function () {
          memberTypeMultipleSelectController.value = _(memberTypeMultipleSelectController.memberTypes)
            .filter({ checked: true })
            .map(function(memberType) {
              return { memberType: memberType.value.id };
            }).value();
          memberTypeMultipleSelectController.ngModel.$setViewValue(memberTypeMultipleSelectController.value);
        };

        function loadData() {
          MemberType.query({}).$promise.then(function(memberTypes) {
            memberTypeMultipleSelectController.memberTypes = _(memberTypes).filter({
              evaluationType: memberTypeMultipleSelectController.evaluationType,
              ignore: false,
              participant: false
            }).map(function(memberType) {
              return {
                value: memberType,
                checked: !_.isUndefined(_.find(memberTypeMultipleSelectController.value, function (type) {
                  return type.memberType === memberType.id;
                }))
              };
            }).value();
          });
        }
      }
  });
