'use strict';

angular.module('diceApp').component('queryTemplateSelectModal', {
    bindings: {
        type: '<',
        selected: '<',
        onSelect: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/query/template/query.template.select.modal.html',
    controllerAs: 'queryTemplateSelectController',
    controller: function (QueryTemplate) {
        const queryTemplateSelectController = this;

        queryTemplateSelectController.$onInit = function() {
            loadData();
        };

        function loadData() {
            queryTemplateSelectController.loading = true;
            QueryTemplate.mine({
                type: queryTemplateSelectController.type
            }).$promise.then((templates) => {
                queryTemplateSelectController.templates = _.sortBy(templates, ['sequence', 'visible', 'name', 'id']);
            }).finally(() => {
                queryTemplateSelectController.loading = false;
            });
        }

        queryTemplateSelectController.select = function(template) {
            queryTemplateSelectController.onSelect({ template });
        };

        queryTemplateSelectController.remove = function(template) {
            QueryTemplate.remove(template.id).$promise.then(() => {
                loadData();
                queryTemplateSelectController.onChange();
            });
        };

        queryTemplateSelectController.cancel = function() {
            queryTemplateSelectController.onCancel();
        };
    }
});
