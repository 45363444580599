'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Question
 * @description
 * Question resource
 */
angular.module('diceApp').factory('Question', function (DiceResource) {
  return DiceResource('/questions');
});
