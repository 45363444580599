'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:StructureStates
 * @description
 * # StructureStates
 * Configures all states in relation to structures.
 */
angular.module('diceApp').config(function ($stateProvider) {

  // Imports
  $stateProvider.state('synchronize', {
    url: '/synchronize',
    data: {
      root: true
    },
    template: '<dice-synchronize></dice-synchronize>'
  });

  // Scripts
  $stateProvider.state('scripts', {
    url: '/scripts',
    data: {
      root: true
    },
    template: '<dice-script></dice-script>'
  });

});
