'use strict';

/* eslint-disable complexity */

/**
 * Complexity warning has been disabled because code with if-else is easier to maintain.
 */ 

angular.module('diceApp')
  .directive('formInput', function ($compile, $translate, jarbInputStore, jarbInputSelector) {
    return {
      templateUrl: 'es6/base/forms/inputs/form.input.html',
      require: '^diceForm',
      attrs: ['inputId', 'label', 'editable'],
      link: function (scope, element, attrs, formController) {

        if (!formController.labelWidth) {
          formController.labelWidth = 3;
        }

        const modelPath = formController.model + '.' + attrs.inputId;
        const validationPath = formController.validator + '.' + attrs.inputId;

        let label = element.find('label');
        label.attr('for', attrs.inputId);

        if (attrs.label) {
          label.append(`<span translate="${attrs.label}"></span>`);
        }

        const tooltipKey = `${attrs.label}.Tooltip`;
        const tooltip = $translate.instant(tooltipKey);
        if (tooltip !== tooltipKey) {
          label.append(`<tooltip-label message="${tooltip}"></tooltip-label>`);
        }

        $compile(label)(scope);

        let editable = formController.editable;
        if (editable === true) {
          editable = attrs.editable !== 'false';
        }

        const jarbConstraints = jarbInputStore.getConstraints();
        if (jarbConstraints === null) {
          jarbInputStore.onConstraintsChanged(function (constraints) {
            applyConstraints(constraints, modelPath, validationPath, attrs, element, scope, editable);
          });
        } else {
          applyConstraints(jarbConstraints, modelPath, validationPath, attrs, element, scope, editable);
        }
      }
    };

    function applyConstraints(constraints, modelPath, validationPath, attrs, element, scope, editable) {
      const rules = jarbInputSelector.validationRulesFor(validationPath, constraints);
      if (_.isEmpty(rules)) {
        return; // Skip when not found
      }

      // Replace the type text by type from attrs if the client code requested so
      if (angular.isDefined(attrs.type)) {
        rules.types = _.map(rules.types, function(type) {
          return type === 'text' ? attrs.type : type;
        });
      }

      const input = element.find('.form-input-container');
      const container = input.parent();

      let newInput;

      if (_.includes(rules.types, 'enum')) {
        newInput = angular.element('<enum-select></enum-select>');
      } else if (_.includes(rules.types, 'element')) {
        newInput = angular.element('<element-select></element-select>');
      } else if (_.includes(rules.types, 'language')) {
        newInput = angular.element('<language-dropdown></language-dropdown>');
      } else if (_.includes(rules.types, 'member-type')) {
        newInput = angular.element('<member-type-select></member-type-select>');
      } else if (_.includes(rules.types, 'image')) {
        newInput = angular.element('<dice-image-upload></dice-image-upload>');
      } else if (_.includes(rules.types, 'status-type')) {
        newInput = angular.element('<status-type-select></status-type-select>');
      } else if (_.includes(rules.types, 'notification-template')) {
        newInput = angular.element('<notification-template-select></notification-template-select>');
      } else if (_.includes(rules.types, 'evaluation-type')) {
        newInput = angular.element('<evaluation-type-select></evaluation-type-select>');
      } else if (_.includes(rules.types, 'option-template')) {
        newInput = angular.element('<option-template-select></option-template-select>');
      } else if (_.includes(rules.types, 'descriptions')) {
        newInput = angular.element('<description-input editable="formController.editable"></description-input>');
      } else if (_.includes(rules.types, 'person-roles')) {
        if (rules.javaType === 'java.util.Set' || rules.javaType === 'java.util.List' || rules.javaType === 'java.util.Collection') {
          newInput = angular.element('<enum-select-multiple type="person-role"></enum-select-multiple>');
        } else {
          newInput = angular.element('<enum-select type="person-role"></enum-select>');
        }
      } else if (rules.maximumLength > 256) {
        newInput = angular.element('<textarea class="form-control msd-elastic"></textarea>');
      } else if (rules.javaType === 'java.time.LocalDate') {
        newInput = angular.element('<date-input></date-input>');
      } else if (rules.javaType === 'java.lang.Boolean' || rules.javaType === 'boolean') {
        newInput = angular.element('<toggle-input></toggle-input>');
      } else {
        newInput = angular.element('<input class="form-control">');
        if (rules.max) {
          newInput.attr('max', rules.max);
        }
      }

      newInput.attr('id', attrs.inputId);
      newInput.attr('name', attrs.inputId);
      newInput.attr('ng-model', modelPath);
      newInput.attr('jarb-input', validationPath);
      newInput.attr('form', 'formController.form');
      if (editable !== true) {
        newInput.attr('disabled', 'disabled');
      }

      const attributesToCopy = ['type', 'evaluationType', 'placeholder', 'isReadOnly', 'disabled', 'ngDisabled', 'ngRequired', 'ngModel', 'id', 'name', 'min', 'max', 'sort', 'autocomplete', 'viewClasses'];
      _.each(attributesToCopy, function(attributeToCopy) {
        const value = attrs[attributeToCopy];
        if (value) {
          newInput.attr(_.kebabCase(attributeToCopy), value);
        }
      });

      const required = (rules.required || (angular.isDefined(attrs.required) && attrs.required !== 'false')) && editable === true;
      if (required) {
        newInput.attr('required', true);
      } else {
        newInput.attr('required');
      }

      input.replaceWith(newInput);

      if (required && !_.includes(rules.types, 'descriptions')) {
        container.append('<required-label ng-model="' + modelPath + '"></required-label>');
      }

      const errorFieldName = attrs.inputId || attrs.field || rules.propertyName;
      container.append(`
        <error-fields
          entity="${scope.formController.validator}" 
          field="${errorFieldName}">
        </error-fields>
      `);

      $compile(container)(scope);
    }
  });
