'use strict';

angular.module('diceApp').component('userLoginsReport', {
  templateUrl: 'es6/statistics/user.logins.report.html',
  controllerAs: 'userLoginsReportController',
  controller: function(UserLogin, Pageable) {
    const userLoginsReportController = this;

    userLoginsReportController.$onInit = function() {
      userLoginsReportController.pageable = Pageable.of({ order: 'loginDateTime', reverse: true });
      loadData();
    };

    function loadData() {
      const params = userLoginsReportController.pageable.build({
        term: userLoginsReportController.term
      });

      userLoginsReportController.loading = true;
      return UserLogin.get(params).$promise.then((logins) => {
        userLoginsReportController.logins = logins;
      }).finally(() => {
        userLoginsReportController.loading = false;
      });
    }

    userLoginsReportController.search = function() {
      loadData();
    };
  }
});