'use strict';

angular.module('diceApp')
  .factory('authenticationTokenInterceptor', function ($cookies) {
    return {
      request: function (request) {
        const token = $cookies.get('te_evaluation_token') || $cookies.get('te_auth_token');
        if (!_.isEmpty(token)) {
          request.headers['Authorization'] = `Bearer ${token}`;
        }
        return request;
      }
    };
  });
