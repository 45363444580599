'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Participant
 * @description
 * # Participant
 * Participant resource of the diceApp
 */
angular.module('diceApp')
  .factory('Participant', function (DiceResource) {
    return DiceResource('/participants', {
      changeDisabled: {
        method: 'PUT',
        url: '/participants/:participant/change-disabled'
      },
      batchUpdate: {
        method: 'PUT',
        url: '/participants/batch-update',
        isArray: true
      },
      deleteAll: {
        method: 'DELETE',
        url: '/participants/batch-delete'
      },
      syncPersons: {
        method: 'POST',
        url: '/participants/:evaluation/sync-manual-participants',
        isArray: true
      },
      search: {
        method: 'GET',
        url: '/participants/search',
        isArray: false
      }
    });
  });
