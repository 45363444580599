'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:MemberType
 * @description
 * # MemberType
 * MemberType resource of the diceApp
 */
angular.module('diceApp')
  .factory('MemberType', function (DiceResource) {
    return DiceResource('/member-types', {
      storeOrderSequence: {
        method: 'PUT',
        url: '/member-types/sequence',
        isArray: true
      }
    });
  });
