'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:evaluationTemplateLabel
 * @description
 * evaluationTemplateLabel Shows the evaluation template, if any
 */
angular.module('diceApp')
  .component('evaluationTemplateLabel', {
    bindings: {
      template: '<'
    },
    templateUrl: 'es6/evaluations/evaluation.template.label.html',
    controllerAs: 'evaluationTemplateLabelController',
    controller: function (authentication) {
      const evaluationTemplateLabelController = this;
      
      evaluationTemplateLabelController.$onInit = function() {
        evaluationTemplateLabelController.clickable = authentication.hasRole(['SYSTEM_ADMIN', 'ADMIN', 'EVALUATION_COORDINATOR']);
      };

    }
});


