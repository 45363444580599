'use strict';

/**
 * @ngdoc component
 * @name diceApp.component:reportSummary
 * @description Summary of the report, as a native table on desktop and as a list on mobile, using same conditional data.
 */
angular.module('diceApp').component('reportSummary', {
  bindings: {
    summary: '<',
    report: '<'
  },
  templateUrl: 'es6/report/report.summary.html',
  controllerAs: 'summaryController',
  controller: function(descriptionFilter, numberFilter, translateFilter) {
    const summaryController = this;

    summaryController.$onInit = function() {
      if (angular.isUndefined(summaryController.summary) || angular.isUndefined(summaryController.report)) {
        return;
      }

      setColumns();
    };

    function isVisible(key) {
      return _.get(summaryController.summary, [key, 'type'], false);
    }

    function getTooltip(key) {
      const tooltips = _.get(summaryController.summary, [key, 'tooltips']);
      return tooltips ? descriptionFilter(tooltips) : undefined;
    }

    function setColumns() {
      let columns = [
        {
          key: 'PARTICIPANT_COUNT',
          labelKey: 'Page.Evaluation.Report.CourseEntries',
          fallbackTitle: 'Course entries',
          value: summaryController.report.reportCourse.registrationCount
        },
        {
          key: 'REGISTRATIONS_WITHOUT_MARK_PERCENTAGE',
          labelKey: 'Page.Evaluation.Report.OfWhichNoMarks',
          fallbackTitle: '-of which no marks',
          value: summaryController.report.reportCourse.registrationWithoutMark + ' (' + numberFilter(summaryController.report.reportCourse.registrationWithoutMarkPercentage, 0) + '%)'
        },
        {
          key: 'RESPONDENT_PERCENTAGE',
          labelKey: 'Page.Evaluation.Report.Response',
          fallbackTitle: 'Response',
          value: summaryController.report.reportCourse.respondentCount + ' / ' + summaryController.report.reportCourse.participantCount + ' (' + numberFilter(summaryController.report.reportCourse.respondentPercentage, 0) + '%)'
        },
        {
          key: 'BSC1',
          labelKey: 'Page.Evaluation.Report.BSc1Course',
          fallbackTitle: 'BSc-1 course',
          value: angular.isDefined(summaryController.report.reportCourse.bscOneCourse) ? translateFilter('BOOLEAN.YESNO.' + _.upperCase(summaryController.report.reportCourse.bscOneCourse)) : ''
        },
        {
          key: 'SUCCESS_RATE_COURSE',
          labelKey: 'Page.Evaluation.Report.SuccessRateCourse',
          fallbackTitle: 'Success rate course',
          shownIf: summaryController.report.reportCourse.successRateCourse >= 0,
          value: numberFilter(summaryController.report.reportCourse.successRateCourse || 0, 0) + '%'
        },
        {
          key: 'SUCCESS_RATE_EXAM',
          labelKey: 'Page.Evaluation.Report.SuccessRateExam',
          fallbackTitle: 'Success rate exam',
          shownIf: summaryController.report.reportCourse.successRateExam >= 0,
          value: numberFilter(summaryController.report.reportCourse.successRateExam || 0, 0) + '%'
        },
        {
          key: 'ATTENTION_POINTS',
          labelKey: 'Page.Evaluation.Report.AttentionPoints',
          fallbackTitle: 'Attention Points',
          isBold: summaryController.report.attentionPoints > 0,
          value: summaryController.report.attentionPoints
        },
        {
          key: 'AVERAGE_GRADE',
          labelKey: 'Page.Evaluation.Report.AverageGrade',
          fallbackTitle: 'Average Grade',
          value: numberFilter(summaryController.report.averageGrade, 1)
        }
      ];

      _.each(columns, function (column) {
        column.title = translateFilter(column.labelKey) || column.fallbackTitle;
        column.isVisible = column.shownIf !== false && isVisible(column.key);
        column.tooltip = getTooltip(column.key);
      });

      summaryController.columns = columns;
    }
  }
});
