'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:sortIcon
 * @description
 * sortIcon Shows the sorting icon
 */
angular.module('diceApp')
  .component('sortIcon', {
    bindings: {
        reverse: '@?'
    },
    templateUrl: 'es6/base/pagination/sort.icon.html',
    controllerAs: 'sortIconController'
});