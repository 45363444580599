'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:periodYearSelect
 * @description
 * The periodYearSelect directive.
 */
angular.module('diceApp')
  .component('periodYearSelect', {
    templateUrl: 'es6/templates/period/period.year.select.html',
    require: {
      ngModelCtrl: 'ngModel'
    },
    bindings: {
      isRequired: '<?'
    },
    controllerAs: 'periodYearSelectController',
    controller: function (Period) {
      const periodYearSelectController = this;

      periodYearSelectController.$onInit = function () {
        periodYearSelectController.ngModelCtrl.$render = () => {
          periodYearSelectController.value = periodYearSelectController.ngModelCtrl.$modelValue;
        };
      };

      Period.years().$promise.then((years) => {
        periodYearSelectController.years = _(years).map((year) => {
          const number = year.year;
          return _.extend(year, { label: `${number}/${number + 1}` });
        }).sortBy('label').reverse().value();
      });

      periodYearSelectController.onChange = function () {
        periodYearSelectController.ngModelCtrl.$setViewValue(periodYearSelectController.value);
      };
    }
  });
