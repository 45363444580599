'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:DashboardStates
 * @description
 * # DashboardStates
 * Configures all states in relation to dashboard.
 */
angular.module('diceApp').config(function ($stateProvider) {

  // Widgets
  $stateProvider.state('widget-list', {
    url: '/widgets',
    data: {
      root: true
    },
    templateUrl: 'es6/dashboard/widget.list.html',
    controllerAs: 'widgetStateController',
    resolve: {
      widgets: function (EvaluationWidget) {
        return EvaluationWidget.query().$promise;
      }
    },
    controller: function (widgets) {
      const widgetStateController = this;
      widgetStateController.widgets = widgets;
    },
    template: '<dice-widget-list widgets="widgetStateController.widgets"></dice-widget-list>'
  });

});
