'use strict';

/**
 * @ngdoc function
 * @name diceApp.config:BenchmarkStates
 * @description
 * # BenchmarkStates
 * Configures all states for benchmarks.
 */
angular.module('diceApp').config(function ($stateProvider) {
  
  $stateProvider.state('benchmarks', {
    url: '/benchmarks',
    template: '<dice-benchmark></dice-benchmark>'
  });

});
