'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:accessRuleListForMemberTypeModal
 * @description Shows a modal to manage an access rule list of a member type.
 */
angular.module('diceApp')
  .component('accessRuleListForMemberTypeModal', {
    bindings: {
      memberType: '<',
      modal: '<'
    },
    templateUrl: 'es6/security/access.rule.list.member.type.modal.html',
    controllerAs: 'accessRuleListController',
    controller: function (MemberType, AccessRule, $filter) {
      const accessRuleListController = this;

      accessRuleListController.$onInit = loadData;

      function loadData() {
        accessRuleListController.loading = true;
        AccessRule.query().$promise.then(function(rules) {
          accessRuleListController.rules = _(rules)
            .filter((rule) => rule.memberType && rule.memberType.id === accessRuleListController.memberType.id)
            .map(function(rule) {
              const result = angular.copy(rule);
              result.operationName = $filter('enum')(rule.operation);
              return result;
            }).value();
        }).finally(() => accessRuleListController.loading = false);
      }

      accessRuleListController.toggle = function (rule) {
        accessRuleListController.rule = {
          id: rule.id,
          operation: rule.operation,
          statusType: _.result(rule, 'statusType.id'),
          memberType: accessRuleListController.memberType.id
        };
      };

      accessRuleListController.cancel = function () {
        delete accessRuleListController.rule;
      };

      accessRuleListController.close = function () {
        accessRuleListController.modal.close();
      };

      accessRuleListController.remove = function(rule) {
        AccessRule.delete({ id: rule.id }).$promise.then(function() {
          loadData();
        });
      };

      accessRuleListController.save = function (rule, form) {
        AccessRule.store(rule).$promise.then(function() {
          form.$setPristine(false);
          accessRuleListController.cancel();
          loadData();
        });
      };
    }
  });
