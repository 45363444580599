'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceEvaluationForm
 */
angular.module('diceApp')
  .component('diceEvaluationForm', {
    bindings: {
      evaluation: '<',
      onSubmit: '&'
    },
    transclude: true,
    templateUrl: 'es6/evaluations/create/evaluation.form.html',
    controllerAs: 'evaluationFormController',
    controller: function ($timeout, Period) {
      const evaluationFormController = this;

      evaluationFormController.$onInit = function() {
        evaluationFormController.periods = Period.query();

        $timeout(() => {
          // Set form dirty so save button is enabled when switching tabs
          if (evaluationFormController.evaluationForm) {
            evaluationFormController.evaluationForm.$setDirty(true);
          }
        });
      };

      evaluationFormController.onPeriod = function() {
        const period = _.find(evaluationFormController.periods, { 
            id: evaluationFormController.evaluation.period 
        });

        if (angular.isDefined(period)) {
            evaluationFormController.evaluation.startDate = period.startDate;
            evaluationFormController.evaluation.reminderDate = period.reminderDate;
            evaluationFormController.evaluation.endDate = period.endDate;
        }
      };

      evaluationFormController.save = function(evaluation) {
        evaluationFormController.onSubmit({ 
          evaluation: evaluation 
        });
      };
    }
  });
