'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:scheduleWorkflowList
 * @description
 * scheduleWorkflowList Shows the scheduled workflows.
 */
angular.module('diceApp')
  .component('scheduleWorkflowList', {
    templateUrl: 'es6/workflow/schedule/schedule.workflow.list.html',
    controllerAs: 'scheduleWorkflowListController',
    controller: function (ScheduleWorkflow, Pageable) {
      const scheduleWorkflowListController = this;

      scheduleWorkflowListController.$onInit = function() {    
        scheduleWorkflowListController.pageable = Pageable.of({
          order: 'scheduleDate',
          reverse: false
        });

        scheduleWorkflowListController.scheduleDate = new Date().getTime();
        scheduleWorkflowListController.future = false;
        scheduleWorkflowListController.search();
      };

      scheduleWorkflowListController.search = function () {
        const params = scheduleWorkflowListController.pageable.build({
          scheduleDate: scheduleWorkflowListController.scheduleDate,
          future: scheduleWorkflowListController.future,
          participants: true
        });

        scheduleWorkflowListController.loading = true;
        ScheduleWorkflow.get(params).$promise.then((schedules) => {
          scheduleWorkflowListController.schedules = schedules;
        }).finally(() => {
          scheduleWorkflowListController.loading = false;
        });
      };
    }
  }
);
