'use strict';

/**
 * @ngdoc component
 * @name diceApp.component:tagList
 * @description Shows the tags.
 */
angular.module('diceApp')
  .component('tagList', {
    templateUrl: 'es6/tags/tag.list.html',
    controllerAs: 'tagsController',
    bindings: {
        type: '@',
        isReadOnly: '<?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    controller: function(Element) {
        const tagsController = this;

        tagsController.$onInit = function() {
            loadData();

            tagsController.ngModelCtrl.$render = function () {
                tagsController.tags = setValues(tagsController.ngModelCtrl.$modelValue);
            };
        };

        tagsController.$onChanges = function() {
            tagsController.readOnly = tagsController.isReadOnly === true;
        };

        function setValues(tags) {
            let result = {};
            _.forEach(tags, (tag) => {
                result[tag.name] = tag.value;
            });
            return result;
        }

        function loadData() {
            tagsController.loading = true;
            Element.query({ type: tagsController.type }).$promise.then((types) => {
                tagsController.types = _.filter(types, (type) => {
                    return type.externalId && type.visible === true;
                });
            }).finally(() => {
                tagsController.loading = false;
            });
        }

        tagsController.onValue = _.debounce(onValue, 500);

        function onValue(name) {
            const tags = tagsController.ngModelCtrl.$modelValue;
            const value = tagsController.tags[name];

            let existing = _.find(tags, { name: name });
            if (angular.isDefined(existing)) {
                existing.value = value;
            } else {
                tags.push({
                    name: name,
                    value: value
                });
            }

            tagsController.ngModelCtrl.$setViewValue(tags);
        }
    }
});
