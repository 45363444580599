'use strict';

/**
 * @ngdoc service
 * @name diceApp.factory:Label
 * @description
 * The Label service.
 */
angular.module('diceApp')
    .factory('Label', function(DiceResource) {
        return DiceResource('/label');
    });
