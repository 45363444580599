'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:evaluationPreparator
 * @description
 * Prepares the evaluation definition for an actual evaluation.
 */
angular.module('diceApp')
  .factory('evaluationPreparator', function(questionType) {

    return { prepare };

    function prepare(evaluation, answers) {
      // Filter unused blocks before prepare
      evaluation.blocks = _.filter(evaluation.blocks, isUsableBlock);

      // Prepare all underlying blocks, filtering unused questions
      var members = _(evaluation.blocks).filter((b) => angular.isDefined(b.member)).map((b) => b.member).uniq().value();
      _.each(evaluation.blocks, (b) => prepareBlock(b, members));

      // Filter unused blocks after prepare
      evaluation.blocks = _.filter(evaluation.blocks, isUsableBlock);

      // Prefill with any existing answers
      if (angular.isDefined(answers)) {
        _.each(evaluation.blocks, (b) => prefillAnswers(b, answers));
      }

      return evaluation;
    }

    function isUsableBlock(block) {
      return !_.isEmpty(block.questions) && block.active !== false;
    }

    function prepareBlock(block, members) {
      block.questions = _.filter(block.questions, function(question) {
        return question.questionType !== 'MEMBER_LIST' || !_.isEmpty(members);
      });
    }

    function prefillAnswers(block, answers) {
      _.each(block.questions, (question) => prefillAnswer(question, answers));
    }

    function prefillAnswer(question, answers) {
      var answer = _.find(answers, { question: question.id });
      if (answer) {
        var prepared = prepareAnswer(question, answer.values);
        question.answer = prepared;
      }
    }

    function prepareAnswer(question, values) {
      var type = questionType.getProperties(question.questionType);
      var answer = values;

      if (!type.multiple && values.length === 1) {
        var result = values[0];
        if (type.prepare && result) {
          result = type.prepare(result);
        }
        answer = result;
      }

      return answer;
    }

  });