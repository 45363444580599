'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:workflowPattern
 * @description
 * workflowPattern Shows the workflow as textual pattern
 */
angular.module('diceApp')
  .component('workflowPattern', {
    bindings: {
      workflow: '<',
      classes: '<?'
    },
    templateUrl: 'es6/workflow/workflow.pattern.html',
    controllerAs: 'workflowPatternController',
    controller: function () {
      const workflowPatternController = this;
      workflowPatternController.Math = Math;
    }
});
