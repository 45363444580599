'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceEvaluationTemplateList
 * @description
 * diceEvaluationTemplateList Shows the evaluation templates in list.
 */
angular
  .module('diceApp')
  .component('diceEvaluationTemplateList', {
    bindToController: true,
    templateUrl: 'es6/templates/evaluation/evaluation.template.list.html',
    controllerAs: 'listController',
    controller: function (EvaluationTemplate, EvaluationType, language, Pageable, evaluationTemplatePreparator) {
      const listController = this;

      listController.$onInit = function () {
        listController.searchString = '';
        listController.pageable = Pageable.of({
          order: 'name'
        });

        EvaluationType.query().$promise.then(function (types) {
          listController.evaluationTypes = types;

          const defaultSelected = _.find(types, { defaultSelected: true });
          listController.evaluationType = _.result(defaultSelected, 'id');
          listController.search();
        });
      };

      listController.search = () => {
        listController.syncing = true;

        const params = listController.pageable.build({
          language: language.get(),
          text: listController.searchString,
          evaluationType: listController.evaluationType
        });

        EvaluationTemplate.search(params).$promise.then((templates) => {
          listController.filteredTemplates = templates;
        }).finally(() => {
          listController.syncing = false;
        });
      };

      listController.getDetails = function (template) {
        return EvaluationTemplate.blocks({
          id: template.id
        }).$promise.then((blocks) => {
          template.blocks = blocks;
          return evaluationTemplatePreparator.prepare(template);
        });
      };
    }
  });
