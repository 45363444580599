'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceElementList
 * @description
 * # diceElementList
 * Controller of the diceApp
 */
angular.module('diceApp')
  .component('diceElementList', {
    bindings: {
      elements: '<',
      tags: '<'
    },
    templateUrl: 'es6/elements/element.list.html',
    controllerAs: 'listController',
    controller: function ($uibModal, $scope, Element, Pageable, message) {
      const listController = this;

      listController.$onInit = function () {
        listController.pageable = Pageable.of({
          order: 'externalId'
        });

        join();
        groupBy();
        
        listController.type = listController.grouped[0];
      };

      listController.open = function (element) {
        $uibModal.open({
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;

            modalController.modal = $uibModalInstance;
            modalController.element = angular.copy(element);

            modalController.onSave = function (savedElement) {
              let found = _.find(listController.elements, { id: savedElement.id });
              if (found) {
                _.extend(found, savedElement);
              } else {
                found = _.find(listController.elements, { externalId: savedElement.externalId, type: savedElement.type });
                if (found && !found.id) {
                  listController.elements.splice(_.indexOf(listController.elements, found), 1);
                }
                listController.elements.push(savedElement);
              }
            };
          },
          template: `
            <dice-element-modal
              element="modalController.element"
              on-save="modalController.onSave"
              modal="modalController.modal">
            </dice-element-modal>`
        });
      };

      listController.delete = function (element) {
          Element.delete({ id: element.id })
            .$promise.then(function() {
              listController.elements = listController.elements.filter((e) => e.id !== element.id);
              join();
              message.addSuccessLabel('Message.Delete.Success');
            });
      };

      $scope.$watch('listController.elements', function (oldArray, newArray) {
        if (oldArray !== newArray) {
          groupBy();
          let type = _.find(listController.grouped, { type: listController.type.type });
          listController.type = (type) ? type : listController.grouped[0];
        }
      }, true);

      /**
       * Creates entries for tags which are not elements yet. These can then be saved as elements if they have to be customized.
       */
      function join() {
        _.forEach(listController.tags, function(tag) {
          const elementNames = _.map(listController.elements, 'externalId');
          const elementTypes = _.map(listController.elements, 'type');

          const hasElementForTag = _.includes(elementNames, tag.name) && _.includes(elementTypes, tagTypeToElementType(tag.type));
          if (!hasElementForTag) {
            listController.elements.push({ externalId: tag.name, type: tagTypeToElementType(tag.type) });
          }
        });
      }

      function tagTypeToElementType(tagType) {
        if (_.isEmpty(tagType)) {
          return 'TAG';
        }

        return _.toUpper(tagType) + '_TAG';
      }

      function groupBy() {
        listController.grouped = _(listController.elements).groupBy('type').toPairs().map(function (item) {
          return _.zipObject(['type', 'elements'], item);
        }).value();
      }
    }
  });
