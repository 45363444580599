'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:StatusChange
 * @description
 * Allows the status to be changed
 */
angular.module('diceApp')
  .component('statusChange', {
    bindings: {
      transitions: '<',
      changeStatus: '&'
    },
    require: 'ngModel',
    templateUrl: 'es6/status/status.change.html',
    controllerAs: 'statusChangeController'
  });
