'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Scheduler
 * @description
 * # Scheduler
 * Scheduler resource of the diceApp
 */
angular.module('diceApp')
  .factory('MailLog', function (DiceResource) {
    return DiceResource('/mail-logs', {
      getByEvaluation: {
        method: 'GET',
        url: '/mail-logs/evaluation/:evaluationId',
        isArray: true
      },
      reports: {
        method: 'GET',
        url: '/mail-logs/reports',
        isArray: true
      }
    });
  });
