'use strict';

/**
 * @ngdoc directive
 * @name diceApp.directive:optionTemplateSelect
 * @optionTemplateSelect
 * The optionTemplateSelect directive.
 */
angular.module('diceApp')
  .component('optionTemplateSelect', {
    templateUrl: 'es6/templates/option/option.template.select.html',
    require: {
      ngModel: 'ngModel'
    },
    controllerAs: 'optionTemplateSelectController',
    controller: function (OptionTemplate, $scope) {
      const optionTemplateSelectController = this;

      optionTemplateSelectController.$onInit = function () {
        $scope.$watch('optionTemplateSelectController.ngModel.$modelValue', function () {
          optionTemplateSelectController.value = optionTemplateSelectController.ngModel.$modelValue;
        });
      };

      optionTemplateSelectController.optionTemplates = OptionTemplate.query();

      optionTemplateSelectController.onChange = function () {
        optionTemplateSelectController.ngModel.$setViewValue(optionTemplateSelectController.value);
      };
    }
  });
