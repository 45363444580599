'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:periodModal
 * @description Shows a modal to manage a period.
 */
angular.module('diceApp')
  .component('periodModal', {
    bindings: {
      period: '<',
      onSave: '<',
      modal: '<'
    },
    templateUrl: 'es6/structure/period.modal.html',
    controllerAs: 'periodController',
    controller: function (Period) {
      const periodController = this;

      periodController.close = function () {
        periodController.modal.close();
      };

      periodController.save = function () {
        Period.store(periodController.period).$promise.then(function (result) {
          periodController.onSave(result);
          periodController.close();
        });
      };
    }
  });
