'use strict';

angular.module('diceApp').component('personTypeahead', {
  templateUrl: 'es6/members/person.typeahead.html',
  bindings: {
    inputName: '@',
    placeholder: '@?',
    viewClasses: '<?',
    isRequired: '<?',
    params: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'personTypeaheadController',
  controller: function ($element, Pageable, Person) {
    const personTypeaheadController = this;

    personTypeaheadController.$onInit = function () {
      personTypeaheadController.pageable = Pageable.of({
        order: 'fullName',
        pageSize: 5,
        active: true
      });

      personTypeaheadController.ngModelCtrl.$validators.required = (modelValue) => {
        return personTypeaheadController.isRequired !== true || _.isNumber(modelValue);
      };

      personTypeaheadController.ngModelCtrl.$parsers.push((viewValue) => {
        if (_.isObject(viewValue)) {
          return _.get(viewValue, 'id');
        } else if (_.isNumber(viewValue)) {
          return viewValue;
        }
      });

      personTypeaheadController.ngModelCtrl.$render = () => {
        if (personTypeaheadController.ngModelCtrl.$viewValue) {
          Person.get({
            id: personTypeaheadController.ngModelCtrl.$viewValue
          }).$promise.then((person) => {
            personTypeaheadController.person = person;
          });
        } else {
          delete personTypeaheadController.person;
        }
      };
    };

    personTypeaheadController.getPersons = function (text) {
      const params = _.extend(personTypeaheadController.params, {
        text: _.lowerCase(text),
        page: 0,
        size: 10
      });

      personTypeaheadController.loading = true;
      return Person.publicSearch(params).$promise
        .then((result) => result.content)
        .finally(() => {
          personTypeaheadController.loading = false;
        });
    };

    personTypeaheadController.getDisplayName = function (person) {
      if (person) {
        return person.fullName ? person.fullName : person.displayName;
      }
    };

    personTypeaheadController.setFocus = function () {
      $element.find('input')[0].focus();
    };

    personTypeaheadController.onChange = function (value) {
      if (angular.isUndefined(value)) {
        delete personTypeaheadController.person;
      }

      personTypeaheadController.ngModelCtrl.$setViewValue(angular.copy(value));
    };
  }
});
