'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:reportFeedback
 */
angular.module('diceApp')
  .component('reportFeedback', {
    bindings: {
      feedback: '<',
      evaluation: '<',
      participantId: '<',
      isReadOnly: '<',
      onChange: '&'
    },
    templateUrl: 'es6/report/report.feedback.html',
    controllerAs: 'reportFeedbackController',
    controller: function(FeedbackModal) {
      const reportFeedbackController = this;

      reportFeedbackController.open = function() {
        FeedbackModal.open({
          feedback: reportFeedbackController.feedback,
          evaluationId: reportFeedbackController.evaluation.id,
          participantId: reportFeedbackController.participantId,
          onChange: () => reportFeedbackController.onChange()
        });
      };
    }
  });