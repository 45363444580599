'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Notification
 * @description Notifications resource of the diceApp
 */
angular.module('diceApp')
  .factory('Notifications', function(DiceResource) {
    return DiceResource('/notifications', {
      getRecipients: {
        method: 'GET',
        url: '/notifications/available-recipients'
      },
      preview: {
        method: 'POST',
        url: '/notifications/preview'
      },
      send: {
        method: 'POST',
        url: '/notifications/send'
      }
    });
  });
